import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CREATION_SIDEPANEL_DATA_TYPE } from "../constants/constants";
import Inventory_Item from "../models/inventory-item.model";
import { Address } from "../models/address.model";

export interface CreationSlidePanelData {
  openStatus: boolean;
  dataType: CREATION_SIDEPANEL_DATA_TYPE;
  data?: Inventory_Item | Address; // can be expanded to include other types of data as required.
}

@Injectable({
  providedIn: "root",
})
export class CreationSlidepanelService {
  private subject = new BehaviorSubject<CreationSlidePanelData>(
    {} as CreationSlidePanelData
  );

  openSlidePanel(data: CreationSlidePanelData) {
    this.subject.next(data);
  }

  closeSlidePanel() {
    const data = {} as CreationSlidePanelData;
    data.openStatus = false;
    this.subject.next(data);
  }

  getMessasge(): Observable<CreationSlidePanelData> {
    return this.subject.asObservable();
  }
}
