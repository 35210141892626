import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilitiesService } from "../services/utilities.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(private utilities: UtilitiesService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log("canactivate route :: ", route);
    console.log("canactivate state :: ", state);
    if (state.url.startsWith("/login") || state.url.startsWith("/signup")) {
      return !this.utilities.getUserFromService().isLoggedIn;
    }

    return this.utilities.getUserFromService().isLoggedIn;
  }
}
