import { environment } from "./environments/environment";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// import { initializeApp } from "firebase/app";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { reducers, metaReducers } from "./core/state_reducers";
import { HeaderComponent } from "./layout/header/header.component";
import { SideNavComponent } from "./layout/side-nav/side-nav.component";
import { MainContentComponent } from "./layout/main-content/main-content.component";
import { SalesComponent } from "./pages/invoice/sales/sales.component";
import { ButtonComponent } from "./components/button/button.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TextInputComponent } from "./components/text-input/text-input.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { InterceptorService } from "./core/services/interceptor.service";
import { EnvironmentService } from "./core/services/environment.service";
import {
  provideFirebaseApp,
  getApp,
  initializeApp,
  FirebaseApp,
} from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "@angular/fire/app-check";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// PrimeNg Imports
import { MessageService, ConfirmationService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { InputNumberModule } from "primeng/inputnumber";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ChipModule } from "primeng/chip";
import { SidebarModule } from "primeng/sidebar";
import { SelectButtonModule } from "primeng/selectbutton";
import { DropdownModule } from "primeng/dropdown";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { StepsModule } from "primeng/steps";
import { ToastModule } from "primeng/toast";
import { AccordionModule } from "primeng/accordion";
import { CalendarModule } from "primeng/calendar";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MenuModule } from "primeng/menu";
import { ContextMenuModule } from "primeng/contextmenu";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DividerModule } from "primeng/divider";
import { TabMenuModule } from "primeng/tabmenu";
import { MenubarModule } from "primeng/menubar";
import { TagModule } from "primeng/tag";
import { PanelModule } from "primeng/panel";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PanelMenuModule } from "primeng/panelmenu";
import { RadioButtonModule } from "primeng/radiobutton";
import { DialogModule } from "primeng/dialog";

import { LoginComponent } from "./pages/login/login.component";
import { NumbersonlyDirective } from "./core/directives/numbersonly.directive";
import { StoreModule } from "@ngrx/store";
import { SlideOutPanelComponent } from "./layout/slide-out-panel/slide-out-panel.component";
import { ProductsComponent } from "./pages/product/products/products.component";
import { CreateProductComponent } from "./pages/product/create-product/create-product.component";
import { ConfigLoaderService } from "./core/services/config-loader.service";
import { SignupComponent } from "./pages/signup/signup.component";
import { ToastComponent } from "./components/toast/toast.component";
import { PartiesComponent } from "./pages/party/parties/parties.component";
import { CreatePartyComponent } from "./pages/party/create-party/create-party.component";
import { CreateInvoiceComponent } from "./pages/invoice/create-invoice/create-invoice.component";
import { CheckboxModule } from "primeng/checkbox";
import { InventoryComponent } from "./pages/inventory/inventory/inventory.component";
import { ConfirmDeleteModalComponent } from "./shared/confirm-delete-modal/confirm-delete-modal.component";
import { FieldErrorComponent } from "./components/field-error/field-error.component";
import { InvalidControlScrollDirective } from "./core/directives/invalid-control-scroll.directive";
import { CreationSlideOutPanelComponent } from "./layout/creation-slide-out-panel/creation-slide-out-panel.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { UserUpdateComponent } from "./pages/user-update/user-update.component";
import { CompanyUpdateComponent } from "./pages/company/company-update/company-update.component";
import { RecordPaymentComponent } from "./components/record-payment/record-payment.component";
import { SortByEpochPipe } from "./core/pipes/sort-by-epoch.pipe";
import { RoundOffCurrencyPipe } from "./core/pipes/round-off-currency.pipe";
import { DisableControlDirective } from "./core/directives/disable-control.directive";
import { StockflowComponent } from "./pages/product/stockflow/stockflow.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { IconComponent } from "./components/icon/icon.component";
import { ViewPartyComponent } from "./pages/party/view-party/view-party.component";
import { AlphaNumericDirective } from "./core/directives/alpha-numeric.directive";
import { EditorMaxCharsDirective } from "./core/directives/editor-max-chars.directive";
import { CreateUpdateAddressComponent } from "./shared/create-update-address/create-update-address.component";
import { CurrencyFormatDirective } from "./core/directives/currency-format.directive";
import { ChallansComponent } from "./pages/challan/delivery-challan/challans/challans.component";
import { CreateUpdateChallanComponent } from "./pages/challan/delivery-challan/create-update-challan/create-update-challan.component";
import { DateToEpochDirective } from "./core/directives/date-to-epoch.directive";
import { TypewriterEffectDirective } from "./core/services/typewriter-effect.directive";
import { ConfirmExitModalComponent } from "./shared/confirm-exit-modal/confirm-exit-modal.component";
import { ChartComponent } from "./components/chart/chart.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    MainContentComponent,
    SalesComponent,
    ButtonComponent,
    TextInputComponent,
    CheckboxComponent,
    LoginComponent,
    NumbersonlyDirective,
    SlideOutPanelComponent,
    ProductsComponent,
    CreateProductComponent,
    SignupComponent,
    ToastComponent,
    PartiesComponent,
    CreatePartyComponent,
    CreateInvoiceComponent,
    InventoryComponent,
    ConfirmDeleteModalComponent,
    FieldErrorComponent,
    InvalidControlScrollDirective,
    CreationSlideOutPanelComponent,
    SettingsComponent,
    UserUpdateComponent,
    CompanyUpdateComponent,
    RecordPaymentComponent,
    SortByEpochPipe,
    RoundOffCurrencyPipe,
    DisableControlDirective,
    StockflowComponent,
    DashboardComponent,
    IconComponent,
    ViewPartyComponent,
    AlphaNumericDirective,
    EditorMaxCharsDirective,
    CreateUpdateAddressComponent,
    CurrencyFormatDirective,
    ChallansComponent,
    CreateUpdateChallanComponent,
    DateToEpochDirective,
    TypewriterEffectDirective,
    ConfirmExitModalComponent,
    ChartComponent,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    BrowserAnimationsModule,
    ButtonModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    InputNumberModule,
    OverlayPanelModule,
    ChipModule,
    SidebarModule,
    SelectButtonModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    StepsModule,
    ToastModule,
    AccordionModule,
    CalendarModule,
    InputTextareaModule,
    CheckboxModule,
    MenuModule,
    ContextMenuModule,
    ConfirmDialogModule,
    DividerModule,
    TabMenuModule,
    MenubarModule,
    TagModule,
    NgxSkeletonLoaderModule,
    PanelModule,
    AutoCompleteModule,
    PanelMenuModule,
    RadioButtonModule,
    DialogModule,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (configLoaderService: ConfigLoaderService) => () => {
    //     configLoaderService.loadConfiguration()?.toPromise();
    //   },
    //   deps: [ConfigLoaderService],
    //   multi: true,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    MessageService,
    ConfirmationService,
    ConfirmDeleteModalComponent,
    ConfirmExitModalComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    private configLoaderService: ConfigLoaderService,
    private environmentService: EnvironmentService,
    private app: FirebaseApp
  ) {
    // type envKeyType = keyof typeof environment;
    // if (environment.isNotLocal) {
    //   this.configLoaderService.loadConfiguration().subscribe((config) => {
    //     Object.keys(config).forEach((key) => {
    //       if (this.isKey(environment, key)) {
    //         environment[key] = config[key] as never;
    //       }
    //       console.log();
    //     });
    //   });
    // }
    // this.environmentService.markEnvironmentReady();

    const appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaV3Provider(environment.recaptchaId),

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true,
    });

    // const app = initializeApp(environment.firebase);
  }

  isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
    return k in x;
  }
}
