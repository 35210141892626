export enum INVOICE_STATUS {
  "Pending" = 0,
  "Partially Paid" = 1,
  "Paid" = 2,
  "Cancelled" = 3,
}

export class InvoiceItem {
  product_service_id: string = "";

  quantity: number = 0;

  unit_price: number = 0.0;

  unit: string = "";

  discount_amount: number = 0.0;

  discount_mode: "amount" | "percent" | null = "amount";

  discount_percent: number = 0.0;

  price_w_tax: number = 0.0;

  tax_rate: number = 0.0;

  title: string = "";

  constructor(data: any) {
    Object.assign(this, data);
  }
}
