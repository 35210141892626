<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Settings</h5>
  </div>
</div>
<p-tabMenu
  [model]="items"
  [activeItem]="activeItem"
  (activeItemChange)="onActiveItemChange($event)"
  styleClass="settings-tabgroup"
></p-tabMenu>
<div class="mt-3">
  <router-outlet></router-outlet>
</div>
