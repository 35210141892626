<p-confirmDialog
  #cd
  [style]="{ 'min-width': '25vw' }"
  key="confirm-delete"
  styleClass="delete-confirmation-modal"
>
  <!-- <ng-template pTemplate="header">
    <h3>Header Content</h3>
  </ng-template> -->
  <ng-template pTemplate="footer">
    <p-button
      label="Cancel"
      styleClass="p-button-sm p-button-secondary"
      (click)="cd.reject()"
    ></p-button>
    <p-button
      label="Delete"
      icon="pi pi-trash"
      styleClass="p-button-sm p-button-danger"
      (click)="cd.accept()"
    ></p-button>
  </ng-template>
</p-confirmDialog>
