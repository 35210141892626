import { Component, OnInit, ViewChild } from "@angular/core";
import {
  SlidepanelService,
  SidePanelData,
} from "src/app/core/services/slidepanel.service";
import { Router, ActivatedRoute } from "@angular/router";
import { InvoiceService } from "src/app/core/services/invoice.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { get } from "lodash";
import {
  InvoiceItem,
  INVOICE_STATUS,
} from "src/app/core/models/invoice-item.model";
import { Company } from "src/app/core/models/company.model";
import { MenuItem } from "primeng/api";
import { ContextMenu } from "primeng/contextmenu";
import { PdfService } from "src/app/core/services/pdf.service";

interface Column {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
  shouldConcat?: boolean;
  concatField?: string;
  subField?: string;
}

interface Product {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus?: string;
  category?: string;
  image?: string;
  rating?: number;
}

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
})
export class SalesComponent implements OnInit {
  _ = get;

  INVOICE_STATUS = INVOICE_STATUS;

  isInvoiceViewed = false;

  cols!: Column[];

  invoices: Array<any> | undefined = [];

  areInvoicesLoaded = false;

  isTabChanged = false;

  companyId = "";

  company: Company = {} as Company;

  readonly tabToStatusMap = [
    {
      index: 0,
      tabLabel: "All",
      status: null,
      noDataMessage: "No invoices found",
    },
    {
      index: 1,
      tabLabel: "Pending",
      status: INVOICE_STATUS.Pending,
      noDataMessage: "No Pending invoices found",
    },
    {
      index: 2,
      tabLabel: "Partially Paid",
      status: INVOICE_STATUS["Partially Paid"],
      noDataMessage: "No Partially Paid invoices found",
    },
    {
      index: 3,
      tabLabel: "Paid",
      status: INVOICE_STATUS.Paid,
      noDataMessage: "No Paid invoices found",
    },
    {
      index: 4,
      tabLabel: "Cancelled",
      status: INVOICE_STATUS.Cancelled,
      noDataMessage: "No Cancelled invoices found",
    },
  ];

  activeIndex = 0;

  noDataMessageForCurrentTab = "No invoices found";

  sidePanelData: SidePanelData = {
    openStatus: false,
    dataType: "invoice",
  };

  invoiceIdToOpen!: string;

  @ViewChild("contextMenu", { static: false }) contextMenu!: ContextMenu;

  contextMenuItems: MenuItem[] = [];

  constructor(
    private sps: SlidepanelService,
    private router: Router,
    private route: ActivatedRoute,
    private is: InvoiceService,
    public utilities: UtilitiesService,
    private pdf: PdfService
  ) {
    this.companyId = this.utilities.getCurrentCompany().id;
    this.invoiceIdToOpen =
      this.router.getCurrentNavigation()?.extras?.state?.["invoiceId"];
    console.log("invoiceIdToOpen :: ", this.invoiceIdToOpen);
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: "party.first_name",
        header: "Name",
        width: "30%",
        shouldConcat: true,
        concatField: "party.last_name",
        subField: "party.contact_details.mobile_number.value",
      },
      { field: "total", header: "Amount", showAs: "currency" },
      { field: "status", header: "Status", showAs: "chip" },
      {
        field: "invoice_number",
        header: "Invoice #",
      },
      {
        field: "invoice_date",
        header: "Invoice Date",
        showAs: "date",
      },
      { field: "", header: "", width: "5%", showAs: "action" },
    ];

    this.getInvoices();

    this.is.getPaymentRecordedMessasge().subscribe((isRecorded) => {
      if (!isRecorded) return;

      this.getInvoices();
    });
  }

  getInvoices() {
    this.areInvoicesLoaded = false;
    this.is.getInvoicesByCompany(this.companyId).subscribe({
      next: (response: any) => {
        console.log("getInvoicesByUser response :: ", response);
        this.invoices = response.data.invoices;
        this.company =
          response.data.company && response.data.company !== null
            ? response.data.company
            : this.utilities.getCurrentCompany();
        this.areInvoicesLoaded = true;

        // if this component is routed to after invoice is created
        // it will open the created invoice in the side panel
        if (this.invoiceIdToOpen && this.invoiceIdToOpen !== "") {
          const invoiceToOpen = this.invoices?.find(
            (invoice) => invoice.id === this.invoiceIdToOpen
          );
          this.invoiceIdToOpen = "";
          this.viewInvoice(invoiceToOpen);
        }
      },
      error: (error) => {
        console.log("getInvoicesByUser error :: ", error);
        this.invoices = [];
        this.areInvoicesLoaded = true;
      },
    });
  }

  createInvoice() {
    this.router.navigate(["new"], { relativeTo: this.route });
  }

  getInvoicesOnTabChange(selectedTabIndex: number) {
    this.activeIndex = selectedTabIndex;
    this.noDataMessageForCurrentTab = this.tabToStatusMap.find((element) => {
      element.index === selectedTabIndex;
    })
      ? this.tabToStatusMap.find((element) => {
          element.index === selectedTabIndex;
        })!.noDataMessage
      : this.noDataMessageForCurrentTab;

    this.isTabChanged = true;

    this.is.getInvoicesByCompany(this.companyId).subscribe({
      next: (response: any) => {
        console.log("getInvoicesByUser response :: ", response);
        this.invoices = response.data.invoices;
        this.company = response.data.company;

        let statusToFilter = this.tabToStatusMap.find(
          (element) => element.index === selectedTabIndex
        )?.status;

        if (statusToFilter !== null) {
          console.log(
            "typeof | value :: ",
            typeof statusToFilter,
            statusToFilter
          );
          statusToFilter =
            typeof statusToFilter === "string"
              ? parseInt(statusToFilter, 10)
              : statusToFilter;
          this.invoices = this.invoices?.filter(
            (element) => parseInt(element.status, 10) === statusToFilter
          );
        }

        this.isTabChanged = false;
      },
      error: (error) => {
        this.isTabChanged = false;
        this.invoices = [];
        this.areInvoicesLoaded = true;
      },
    });
  }

  viewInvoice(invoice: any) {
    if (!invoice) return;

    this.sidePanelData.invoice = invoice;
    this.sidePanelData.openStatus = true;
    this.sidePanelData.company = this.company;
    this.sps.openSlidePanel(this.sidePanelData);
  }

  openContextMenu(event: MouseEvent, invoice: any): void {
    this.contextMenuItems = [
      {
        label: "Edit Invoice",
        icon: "pi pi-pencil",
        command: (event) => console.log("Edit Action"), // this.editProduct(product),
      },
      {
        label: "Download as PDF",
        icon: "pi pi-download",
        command: (event) => this.pdf.createInvoicePdf(invoice, this.company),
      },
      {
        label: "Cancel Invoice",
        icon: "pi pi-times-circle",
        styleClass: "danger",
        command: (event) => console.log("Edit Action"), // this.deleteProduct(product),
      },
    ];
    this.contextMenu.toggle(event);
    event.stopPropagation();
  }
}
