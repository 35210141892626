import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { endpoints } from "./endpoints.ts.service";
import { Challan } from "../models/challan.model";
import { UtilitiesService } from "./utilities.service";

@Injectable({
  providedIn: "root",
})
export class ChallanService {
  constructor(
    private utilities: UtilitiesService,
    private httpClient: HttpClient
  ) {}

  createUpdateChallan(payload: Omit<Challan, "total_weight">) {
    const companyId = this.utilities.getCurrentCompany().id;
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.CHALLAN}`,
      payload,
      {
        headers,
      }
    );
  }

  getChallansByCompany() {
    const companyId = this.utilities.getCurrentCompany().id;
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.COMPANIES}${endpoints.CHALLAN}`,
      {
        headers,
      }
    );
  }
}
