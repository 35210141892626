import { Component, Input, OnInit } from "@angular/core";
import {
  CreationSlidePanelData,
  CreationSlidepanelService,
} from "src/app/core/services/creation-slidepanel.service";
import {
  CREATION_SIDEPANEL_DATA_TYPE,
  STOCKFLOW_TYPES,
} from "src/app/core/constants/constants";
import { Address } from "src/app/core/models/address.model";

@Component({
  selector: "app-creation-slide-out-panel",
  templateUrl: "./creation-slide-out-panel.component.html",
  styleUrls: ["./creation-slide-out-panel.component.scss"],
})
export class CreationSlideOutPanelComponent implements OnInit {
  parseFloat = parseFloat;

  CREATION_SIDEPANEL_DATA_TYPE = CREATION_SIDEPANEL_DATA_TYPE;

  STOCKFLOW_TYPES = STOCKFLOW_TYPES;

  @Input() isVisible: boolean = false;

  @Input() sidePanelData!: CreationSlidePanelData;

  sidebarVisible: boolean = false;

  data: any;

  constructor(private csps: CreationSlidepanelService) {}

  createCustomer() {}

  close() {
    this.csps.closeSlidePanel();
  }

  ngOnInit(): void {}

  isAddressForEdit() {
    if (
      this.sidePanelData.dataType === this.CREATION_SIDEPANEL_DATA_TYPE.address
    ) {
      this.data = this.sidePanelData.data as Address;
      if (this.data.line_1 && this.data.city && this.data.state) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }
}
