import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { LoadingspinnerService } from "./loadingspinner.service";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import { selectAppState } from "../state_reducers";
import { UtilitiesService } from "./utilities.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  requests: Array<any> = [];

  totalRequests = 0;

  headers!: HttpHeaders;

  readonly omitAuthHeader = ["./assets/environment-config.json"];

  constructor(
    private loaderService: LoadingspinnerService,
    private store: Store,
    private utilitiesService: UtilitiesService,
    private as: AuthService
  ) {}

  addAuthHeader(request: HttpRequest<any>) {
    const userId = this.utilitiesService.getUserFromService().id;
    const token = sessionStorage.getItem("ast-usr-tk")!;

    return request.clone({
      headers: request.headers.append("token", token),
    });
  }

  private v2HTTPOkResponse(res: HttpEvent<any>, req: HttpRequest<any>) {
    if (res instanceof HttpResponse) {
      this.decrementLoader(req.url);
    }

    return res;
  }

  incrementLoader(req: HttpRequest<any>) {
    this.requests.push(req.url);
    this.totalRequests += 1;

    console.log(`TOTALREQUESTS :: , '${this.totalRequests}'
    ADDED API :: '${req.url}'
    METHOD :: '${req.method}'`);

    // this.loaderService.sendMessage(true);
  }

  decrementLoader(req: string) {
    this.requests.splice(this.requests.lastIndexOf(req), 1);
    this.totalRequests -= 1;

    console.log(`TOTALREQUESTS :: , '${this.totalRequests}'
    REMOVED API :: '${req}'`);

    if (this.totalRequests <= 0) {
      this.loaderService.sendMessage(false);
    }
  }

  handleResponseError(
    error: any,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    console.log("errorrrrr :: ", error);

    if (error.status === 400) {
      this.decrementLoader(request.url);
      return throwError(
        () =>
          new HttpErrorResponse({
            status: error.error.statusCode,
            statusText: "Bad request",
            error: error.error.error,
          })
      );
    }

    if (error.status === 401) {
      console.log("Encountered HTTP 401. Attempting to refresh token");
      if (!this.as.auth.currentUser) {
        this.as.performLogout();
        this.decrementLoader(request.url);
        return EMPTY;
      }
      this.as.refreshIdToken().subscribe({
        next: (token: string) => {
          console.log("token refreshed");
          if (token && token.length) {
            const updatedRequest = this.addAuthHeader(request!);
            console.log("Attempting to re-call the API with new token");
            return next
              .handle(updatedRequest)
              .pipe(
                map((res: HttpEvent<any>) => {
                  return this.v2HTTPOkResponse(res, updatedRequest);
                })
              )
              .pipe(
                catchError((innerError: any) => {
                  if (innerError.status == 500) {
                    // this.alertService.showError(error.statusText);
                  } else if (innerError.status == 588) {
                    // this.alertService.showAlert(error.statusText);
                  }

                  return throwError(() => innerError.statusText);
                })
              );
          } else {
            console.log("token could not be refreshed");
            this.as.performLogout();
            return EMPTY;
          }
        },
        error: (error) => {
          console.error("interceptor refreshIdToken :: ", error);
        },
      });
    }

    if (error.status === 404) {
      this.decrementLoader(request.url);
      return throwError(
        () =>
          new HttpResponse({
            status: 404,
            statusText: "Not Found",
            body: error.error,
          })
      );
    }

    return EMPTY;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.omitAuthHeader?.some((url) => request.url.includes(url))) {
      return next.handle(request);
    }

    this.incrementLoader(request);

    const authReq = this.addAuthHeader(request);

    return next
      .handle(authReq)
      .pipe(map((res: HttpEvent<any>) => this.v2HTTPOkResponse(res, request)))
      .pipe(
        catchError((error) => this.handleResponseError(error, request, next))
      );
  }
}
