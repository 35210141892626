import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent implements OnInit {
  @Input() id!: string;

  @Input() label!: string;

  showLabel: boolean = false;

  ngOnInit(): void {
    if (this.label && this.label.length) {
      this.showLabel = true;
    }
  }
}
