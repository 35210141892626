import { T_PRODUCT_UNIT } from "../models/inventory-item.model";
import { T_STATE } from "../models/address.model";
import { T_NUMERIC_DD, T_STRING_DD } from "../models/shared.model";

// CONSTANTS
/**
 * All the supported product units
 * @constant
 */
export const PRODUCT_UNITS: Array<T_PRODUCT_UNIT> = [
  { name: "Piece (PCS)", code: "PCS", acceptDecimal: false },
  { name: "Box (BOX)", code: "BOX", acceptDecimal: false },
  { name: "Kilogram (KG)", code: "KG", acceptDecimal: true },
  { name: "Gram (G)", code: "G", acceptDecimal: true },
  { name: "Liter (L)", code: "L", acceptDecimal: true },
  { name: "Milliliter (ML)", code: "ML", acceptDecimal: true },
  { name: "Pair (PAIR)", code: "PAIR", acceptDecimal: false },
  { name: "Dozen (DOZ)", code: "DOZ", acceptDecimal: true },
  { name: "Meter (M)", code: "M", acceptDecimal: true },
  { name: "Square Meter (SQ. M)", code: "SQM", acceptDecimal: true },
  { name: "Roll (ROLL)", code: "ROLL", acceptDecimal: false },
  { name: "Packet (PKT)", code: "PKT", acceptDecimal: false },
  { name: "Carton (CTN)", code: "CTN", acceptDecimal: false },
  { name: "Bunch (BUNCH)", code: "BUNCH", acceptDecimal: false },
  { name: "Tray (TRAY)", code: "TRAY", acceptDecimal: false },
  { name: "Can (CAN)", code: "CAN", acceptDecimal: false },
  { name: "Bottle (BOTTLE)", code: "BOTTLE", acceptDecimal: false },
  { name: "Set (SET)", code: "SET", acceptDecimal: false },
  { name: "Ream (REAM)", code: "REAM", acceptDecimal: true },
  { name: "Pack (PACK)", code: "PACK", acceptDecimal: false },
];

/**
 * The list of all the states in India along with their state code and GST code
 * @constant
 */
export const STATES: Array<T_STATE> = [
  { name: "Jammu & Kashmir", code: "JK", gstCode: "01" },
  { name: "Himachal Pradesh", code: "HP", gstCode: "02" },
  { name: "Punjab", code: "PB", gstCode: "03" },
  { name: "Chandigarh", code: "CH", gstCode: "04" },
  { name: "Uttarakhand", code: "UT", gstCode: "05" },
  { name: "Haryana", code: "HR", gstCode: "06" },
  { name: "Delhi", code: "DL", gstCode: "07" },
  { name: "Rajasthan", code: "RJ", gstCode: "08" },
  { name: "Uttar Pradesh", code: "UP", gstCode: "09" },
  { name: "Bihar", code: "BH", gstCode: "10" },
  { name: "Sikkim", code: "SK", gstCode: "11" },
  { name: "Arunachal Pradesh", code: "AR", gstCode: "12" },
  { name: "Nagaland", code: "NL", gstCode: "13" },
  { name: "Manipur", code: "MN", gstCode: "14" },
  { name: "Mizoram", code: "MI", gstCode: "15" },
  { name: "Tripura", code: "TR", gstCode: "16" },
  { name: "Meghalaya", code: "ME", gstCode: "17" },
  { name: "Assam", code: "AS", gstCode: "18" },
  { name: "West Bengal", code: "WB", gstCode: "19" },
  { name: "Jharkhand", code: "JH", gstCode: "20" },
  { name: "Odisha", code: "OR", gstCode: "21" },
  { name: "Chattisgarh", code: "CT", gstCode: "22" },
  { name: "Madhya Pradesh", code: "MP", gstCode: "23" },
  { name: "Gujarat", code: "GJ", gstCode: "24" },
  { name: "Daman & Diu", code: "DD", gstCode: "25" },
  { name: "Dadra & Nagar Haveli", code: "DN", gstCode: "26" },
  { name: "Maharashtra", code: "MH", gstCode: "27" },
  { name: "Andhra Pradesh", code: "AP", gstCode: "28" },
  { name: "Karnataka", code: "KA", gstCode: "29" },
  { name: "Goa", code: "GA", gstCode: "30" },
  { name: "Lakshadweep", code: "LD", gstCode: "31" },
  { name: "Kerala", code: "KL", gstCode: "32" },
  { name: "Tamil Nadu", code: "TN", gstCode: "33" },
  { name: "Puducherry", code: "PY", gstCode: "34" },
  { name: "Andaman & Nicobar Islands", code: "AN", gstCode: "35" },
  { name: "Telangana", code: "TL", gstCode: "36" },
  { name: "Hyderabad GST Commissionerate", code: "AD", gstCode: "37" },
  { name: "Kurnool GST Commissionerate", code: "LA", gstCode: "38" },
];

export type T_COUNTRY = {
  code: string;
  dial_code: string;
  flag: string;
  mobile_number_length: number;
  name: string;
};

/**
 * List of all countries with their respective country code, dial code and flag
 * @constant
 */
export const COUNTRIES = [
  {
    code: "AF",
    dial_code: "93",
    flag: "🇦🇫",
    mobile_number_length: 9,
    name: "Afghanistan",
  },
  {
    code: "AX",
    dial_code: "358",
    flag: "🇦🇽",
    name: "Åland Islands",
  },
  {
    code: "AL",
    dial_code: "355",
    flag: "🇦🇱",
    mobile_number_length: 9,
    name: "Albania",
  },
  {
    code: "DZ",
    dial_code: "213",
    flag: "🇩🇿",
    mobile_number_length: 10,
    name: "Algeria",
  },
  {
    code: "AS",
    dial_code: "1684",
    flag: "🇦🇸",
    name: "American Samoa",
  },
  {
    code: "AD",
    dial_code: "376",
    flag: "🇦🇩",
    mobile_number_length: 9,
    name: "Andorra",
  },
  {
    code: "AO",
    dial_code: "244",
    flag: "🇦🇴",
    mobile_number_length: 9,
    name: "Angola",
  },
  {
    code: "AI",
    dial_code: "1264",
    flag: "🇦🇮",
    name: "Anguilla",
  },
  {
    code: "AQ",
    dial_code: "672",
    flag: "🇦🇶",
    name: "Antarctica",
  },
  {
    code: "AG",
    dial_code: "1268",
    flag: "🇦🇬",
    mobile_number_length: 10,
    name: "Antigua and Barbuda",
  },
  {
    code: "AR",
    dial_code: "54",
    flag: "🇦🇷",
    mobile_number_length: 10,
    name: "Argentina",
  },
  {
    code: "AM",
    dial_code: "374",
    flag: "🇦🇲",
    mobile_number_length: 8,
    name: "Armenia",
  },
  {
    code: "AW",
    dial_code: "297",
    flag: "🇦🇼",
    name: "Aruba",
  },
  {
    code: "AU",
    dial_code: "61",
    flag: "🇦🇺",
    mobile_number_length: 10,
    name: "Australia",
  },
  {
    code: "AT",
    dial_code: "43",
    flag: "🇦🇹",
    mobile_number_length: 11,
    name: "Austria",
  },
  {
    code: "AZ",
    dial_code: "994",
    flag: "🇦🇿",
    mobile_number_length: 9,
    name: "Azerbaijan",
  },
  {
    code: "BS",
    dial_code: "1242",
    flag: "🇧🇸",
    mobile_number_length: 10,
    name: "Bahamas",
  },
  {
    code: "BH",
    dial_code: "973",
    flag: "🇧🇭",
    mobile_number_length: 8,
    name: "Bahrain",
  },
  {
    code: "BD",
    dial_code: "880",
    flag: "🇧🇩",
    mobile_number_length: 11,
    name: "Bangladesh",
  },
  {
    code: "BB",
    dial_code: "1246",
    flag: "🇧🇧",
    mobile_number_length: 10,
    name: "Barbados",
  },
  {
    code: "BY",
    dial_code: "375",
    flag: "🇧🇾",
    mobile_number_length: 9,
    name: "Belarus",
  },
  {
    code: "BE",
    dial_code: "32",
    flag: "🇧🇪",
    mobile_number_length: 9,
    name: "Belgium",
  },
  {
    code: "BZ",
    dial_code: "501",
    flag: "🇧🇿",
    mobile_number_length: 7,
    name: "Belize",
  },
  {
    code: "BJ",
    dial_code: "229",
    flag: "🇧🇯",
    mobile_number_length: 8,
    name: "Benin",
  },
  {
    code: "BM",
    dial_code: "1441",
    flag: "🇧🇲",
    name: "Bermuda",
  },
  {
    code: "BT",
    dial_code: "975",
    flag: "🇧🇹",
    mobile_number_length: 8,
    name: "Bhutan",
  },
  {
    code: "BO",
    dial_code: "591",
    flag: "🇧🇴",
    mobile_number_length: 8,
    name: "Bolivia, Plurinational State of bolivia",
  },
  {
    code: "BA",
    dial_code: "387",
    flag: "🇧🇦",
    mobile_number_length: 8,
    name: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    dial_code: "267",
    flag: "🇧🇼",
    mobile_number_length: 9,
    name: "Botswana",
  },
  {
    code: "BV",
    dial_code: "47",
    flag: "🇧🇻",
    name: "Bouvet Island",
  },
  {
    code: "BR",
    dial_code: "55",
    flag: "🇧🇷",
    mobile_number_length: 11,
    name: "Brazil",
  },
  {
    code: "IO",
    dial_code: "246",
    flag: "🇮🇴",
    name: "British Indian Ocean Territory",
  },
  {
    code: "BN",
    dial_code: "673",
    flag: "🇧🇳",
    mobile_number_length: 8,
    name: "Brunei Darussalam",
  },
  {
    code: "BG",
    dial_code: "359",
    flag: "🇧🇬",
    mobile_number_length: 10,
    name: "Bulgaria",
  },
  {
    code: "BF",
    dial_code: "226",
    flag: "🇧🇫",
    mobile_number_length: 8,
    name: "Burkina Faso",
  },
  {
    code: "BI",
    dial_code: "257",
    flag: "🇧🇮",
    mobile_number_length: 8,
    name: "Burundi",
  },
  {
    code: "KH",
    dial_code: "855",
    flag: "🇰🇭",
    mobile_number_length: 9,
    name: "Cambodia",
  },
  {
    code: "CM",
    dial_code: "237",
    flag: "🇨🇲",
    mobile_number_length: 9,
    name: "Cameroon",
  },
  {
    code: "CA",
    dial_code: "1",
    flag: "🇨🇦",
    mobile_number_length: 10,
    name: "Canada",
  },
  {
    code: "CV",
    dial_code: "238",
    flag: "🇨🇻",
    mobile_number_length: 7,
    name: "Cape Verde",
  },
  {
    code: "KY",
    dial_code: "345",
    flag: "🇰🇾",
    name: "Cayman Islands",
  },
  {
    code: "CF",
    dial_code: "236",
    flag: "🇨🇫",
    mobile_number_length: 8,
    name: "Central African Republic",
  },
  {
    code: "TD",
    dial_code: "235",
    flag: "🇹🇩",
    mobile_number_length: 8,
    name: "Chad",
  },
  {
    code: "CL",
    dial_code: "56",
    flag: "🇨🇱",
    mobile_number_length: 9,
    name: "Chile",
  },
  {
    code: "CN",
    dial_code: "86",
    flag: "🇨🇳",
    mobile_number_length: 11,
    name: "China",
  },
  {
    code: "CX",
    dial_code: "61",
    flag: "🇨🇽",
    name: "Christmas Island",
  },
  {
    code: "CC",
    dial_code: "61",
    flag: "🇨🇨",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    dial_code: "57",
    flag: "🇨🇴",
    mobile_number_length: 10,
    name: "Colombia",
  },
  {
    code: "KM",
    dial_code: "269",
    flag: "🇰🇲",
    mobile_number_length: 7,
    name: "Comoros",
  },
  {
    code: "CG",
    dial_code: "242",
    flag: "🇨🇬",
    name: "Congo",
  },
  {
    code: "CD",
    dial_code: "243",
    flag: "🇨🇩",
    mobile_number_length: 9,
    name: "Congo, The Democratic Republic of the Congo",
  },
  {
    code: "CK",
    dial_code: "682",
    flag: "🇨🇰",
    name: "Cook Islands",
  },
  {
    code: "CR",
    dial_code: "506",
    flag: "🇨🇷",
    mobile_number_length: 8,
    name: "Costa Rica",
  },
  {
    code: "CI",
    dial_code: "225",
    flag: "🇨🇮",
    name: "Cote d'Ivoire",
  },
  {
    code: "HR",
    dial_code: "385",
    flag: "🇭🇷",
    mobile_number_length: 9,
    name: "Croatia",
  },
  {
    code: "CU",
    dial_code: "53",
    flag: "🇨🇺",
    mobile_number_length: 8,
    name: "Cuba",
  },
  {
    code: "CY",
    dial_code: "357",
    flag: "🇨🇾",
    mobile_number_length: 9,
    name: "Cyprus",
  },
  {
    code: "CZ",
    dial_code: "420",
    flag: "🇨🇿",
    mobile_number_length: 9,
    name: "Czech Republic",
  },
  {
    code: "DK",
    dial_code: "45",
    flag: "🇩🇰",
    mobile_number_length: 8,
    name: "Denmark",
  },
  {
    code: "DJ",
    dial_code: "253",
    flag: "🇩🇯",
    mobile_number_length: 8,
    name: "Djibouti",
  },
  {
    code: "DM",
    dial_code: "1767",
    flag: "🇩🇲",
    mobile_number_length: 7,
    name: "Dominica",
  },
  {
    code: "DO",
    dial_code: "1849",
    flag: "🇩🇴",
    mobile_number_length: 10,
    name: "Dominican Republic",
  },
  {
    code: "EC",
    dial_code: "593",
    flag: "🇪🇨",
    mobile_number_length: 9,
    name: "Ecuador",
  },
  {
    code: "EG",
    dial_code: "20",
    flag: "🇪🇬",
    mobile_number_length: 10,
    name: "Egypt",
  },
  {
    code: "SV",
    dial_code: "503",
    flag: "🇸🇻",
    mobile_number_length: 8,
    name: "El Salvador",
  },
  {
    code: "GQ",
    dial_code: "240",
    flag: "🇬🇶",
    mobile_number_length: 8,
    name: "Equatorial Guinea",
  },
  {
    code: "ER",
    dial_code: "291",
    flag: "🇪🇷",
    mobile_number_length: 8,
    name: "Eritrea",
  },
  {
    code: "EE",
    dial_code: "372",
    flag: "🇪🇪",
    mobile_number_length: 8,
    name: "Estonia",
  },
  {
    code: "ET",
    dial_code: "251",
    flag: "🇪🇹",
    mobile_number_length: 9,
    name: "Ethiopia",
  },
  {
    code: "FK",
    dial_code: "500",
    flag: "🇫🇰",
    name: "Falkland Islands (Malvinas)",
  },
  {
    code: "FO",
    dial_code: "298",
    flag: "🇫🇴",
    name: "Faroe Islands",
  },
  {
    code: "FJ",
    dial_code: "679",
    flag: "🇫🇯",
    mobile_number_length: 7,
    name: "Fiji",
  },
  {
    code: "FI",
    dial_code: "358",
    flag: "🇫🇮",
    mobile_number_length: 10,
    name: "Finland",
  },
  {
    code: "FR",
    dial_code: "33",
    flag: "🇫🇷",
    mobile_number_length: 9,
    name: "France",
  },
  {
    code: "GF",
    dial_code: "594",
    flag: "🇬🇫",
    name: "French Guiana",
  },
  {
    code: "PF",
    dial_code: "689",
    flag: "🇵🇫",
    name: "French Polynesia",
  },
  {
    code: "TF",
    dial_code: "262",
    flag: "🇹🇫",
    name: "French Southern Territories",
  },
  {
    code: "GA",
    dial_code: "241",
    flag: "🇬🇦",
    mobile_number_length: 8,
    name: "Gabon",
  },
  {
    code: "GM",
    dial_code: "220",
    flag: "🇬🇲",
    mobile_number_length: 8,
    name: "Gambia",
  },
  {
    code: "GE",
    dial_code: "995",
    flag: "🇬🇪",
    mobile_number_length: 9,
    name: "Georgia",
  },
  {
    code: "DE",
    dial_code: "49",
    flag: "🇩🇪",
    mobile_number_length: 11,
    name: "Germany",
  },
  {
    code: "GH",
    dial_code: "233",
    flag: "🇬🇭",
    mobile_number_length: 9,
    name: "Ghana",
  },
  {
    code: "GI",
    dial_code: "350",
    flag: "🇬🇮",
    name: "Gibraltar",
  },
  {
    code: "GR",
    dial_code: "30",
    flag: "🇬🇷",
    mobile_number_length: 10,
    name: "Greece",
  },
  {
    code: "GL",
    dial_code: "299",
    flag: "🇬🇱",
    name: "Greenland",
  },
  {
    code: "GD",
    dial_code: "1473",
    flag: "🇬🇩",
    mobile_number_length: 7,
    name: "Grenada",
  },
  {
    code: "GP",
    dial_code: "590",
    flag: "🇬🇵",
    name: "Guadeloupe",
  },
  {
    code: "GU",
    dial_code: "1671",
    flag: "🇬🇺",
    name: "Guam",
  },
  {
    code: "GT",
    dial_code: "502",
    flag: "🇬🇹",
    mobile_number_length: 8,
    name: "Guatemala",
  },
  {
    code: "GG",
    dial_code: "44",
    flag: "🇬🇬",
    name: "Guernsey",
  },
  {
    code: "GN",
    dial_code: "224",
    flag: "🇬🇳",
    mobile_number_length: 8,
    name: "Guinea",
  },
  {
    code: "GW",
    dial_code: "245",
    flag: "🇬🇼",
    mobile_number_length: 8,
    name: "Guinea-Bissau",
  },
  {
    code: "GY",
    dial_code: "592",
    flag: "🇬🇾",
    mobile_number_length: 8,
    name: "Guyana",
  },
  {
    code: "HT",
    dial_code: "509",
    flag: "🇭🇹",
    mobile_number_length: 8,
    name: "Haiti",
  },
  {
    code: "HM",
    dial_code: "672",
    flag: "🇭🇲",
    name: "Heard Island and Mcdonald Islands",
  },
  {
    code: "VA",
    dial_code: "379",
    flag: "🇻🇦",
    mobile_number_length: 9,
    name: "Holy See (Vatican City State)",
  },
  {
    code: "HN",
    dial_code: "504",
    flag: "🇭🇳",
    mobile_number_length: 8,
    name: "Honduras",
  },
  {
    code: "HK",
    dial_code: "852",
    flag: "🇭🇰",
    name: "Hong Kong",
  },
  {
    code: "HU",
    dial_code: "36",
    flag: "🇭🇺",
    mobile_number_length: 9,
    name: "Hungary",
  },
  {
    code: "IS",
    dial_code: "354",
    flag: "🇮🇸",
    mobile_number_length: 7,
    name: "Iceland",
  },
  {
    code: "IN",
    dial_code: "91",
    flag: "🇮🇳",
    mobile_number_length: 10,
    name: "India",
  },
  {
    code: "ID",
    dial_code: "62",
    flag: "🇮🇩",
    mobile_number_length: 11,
    name: "Indonesia",
  },
  {
    code: "IR",
    dial_code: "98",
    flag: "🇮🇷",
    mobile_number_length: 10,
    name: "Iran, Islamic Republic of Persian Gulf",
  },
  {
    code: "IQ",
    dial_code: "964",
    flag: "🇮🇶",
    mobile_number_length: 10,
    name: "Iraq",
  },
  {
    code: "IE",
    dial_code: "353",
    flag: "🇮🇪",
    mobile_number_length: 10,
    name: "Ireland",
  },
  {
    code: "IM",
    dial_code: "44",
    flag: "🇮🇲",
    name: "Isle of Man",
  },
  {
    code: "IL",
    dial_code: "972",
    flag: "🇮🇱",
    mobile_number_length: 9,
    name: "Israel",
  },
  {
    code: "IT",
    dial_code: "39",
    flag: "🇮🇹",
    mobile_number_length: 10,
    name: "Italy",
  },
  {
    code: "JM",
    dial_code: "1876",
    flag: "🇯🇲",
    mobile_number_length: 10,
    name: "Jamaica",
  },
  {
    code: "JP",
    dial_code: "81",
    flag: "🇯🇵",
    mobile_number_length: 11,
    name: "Japan",
  },
  {
    code: "JE",
    dial_code: "44",
    flag: "🇯🇪",
    name: "Jersey",
  },
  {
    code: "JO",
    dial_code: "962",
    flag: "🇯🇴",
    mobile_number_length: 9,
    name: "Jordan",
  },
  {
    code: "KZ",
    dial_code: "7",
    flag: "🇰🇿",
    mobile_number_length: 10,
    name: "Kazakhstan",
  },
  {
    code: "KE",
    dial_code: "254",
    flag: "🇰🇪",
    mobile_number_length: 9,
    name: "Kenya",
  },
  {
    code: "KI",
    dial_code: "686",
    flag: "🇰🇮",
    mobile_number_length: 5,
    name: "Kiribati",
  },
  {
    code: "KP",
    dial_code: "850",
    flag: "🇰🇵",
    name: "Korea, Democratic People's Republic of Korea",
  },
  {
    code: "KR",
    dial_code: "82",
    flag: "🇰🇷",
    mobile_number_length: 11,
    name: "Korea, Republic of South Korea",
  },
  {
    code: "XK",
    dial_code: "383",
    flag: "🇽🇰",
    mobile_number_length: 9,
    name: "Kosovo",
  },
  {
    code: "KW",
    dial_code: "965",
    flag: "🇰🇼",
    mobile_number_length: 8,
    name: "Kuwait",
  },
  {
    code: "KG",
    dial_code: "996",
    flag: "🇰🇬",
    mobile_number_length: 9,
    name: "Kyrgyzstan",
  },
  {
    code: "LA",
    dial_code: "856",
    flag: "🇱🇦",
    mobile_number_length: 9,
    name: "Laos",
  },
  {
    code: "LV",
    dial_code: "371",
    flag: "🇱🇻",
    mobile_number_length: 8,
    name: "Latvia",
  },
  {
    code: "LB",
    dial_code: "961",
    flag: "🇱🇧",
    mobile_number_length: 8,
    name: "Lebanon",
  },
  {
    code: "LS",
    dial_code: "266",
    flag: "🇱🇸",
    mobile_number_length: 8,
    name: "Lesotho",
  },
  {
    code: "LR",
    dial_code: "231",
    flag: "🇱🇷",
    mobile_number_length: 8,
    name: "Liberia",
  },
  {
    code: "LY",
    dial_code: "218",
    flag: "🇱🇾",
    mobile_number_length: 9,
    name: "Libyan Arab Jamahiriya",
  },
  {
    code: "LI",
    dial_code: "423",
    flag: "🇱🇮",
    mobile_number_length: 9,
    name: "Liechtenstein",
  },
  {
    code: "LT",
    dial_code: "370",
    flag: "🇱🇹",
    mobile_number_length: 8,
    name: "Lithuania",
  },
  {
    code: "LU",
    dial_code: "352",
    flag: "🇱🇺",
    mobile_number_length: 9,
    name: "Luxembourg",
  },
  {
    code: "MO",
    dial_code: "853",
    flag: "🇲🇴",
    name: "Macao",
  },
  {
    code: "MK",
    dial_code: "389",
    flag: "🇲🇰",
    mobile_number_length: 8,
    name: "Macedonia",
  },
  {
    code: "MG",
    dial_code: "261",
    flag: "🇲🇬",
    mobile_number_length: 9,
    name: "Madagascar",
  },
  {
    code: "MW",
    dial_code: "265",
    flag: "🇲🇼",
    mobile_number_length: 9,
    name: "Malawi",
  },
  {
    code: "MY",
    dial_code: "60",
    flag: "🇲🇾",
    mobile_number_length: 10,
    name: "Malaysia",
  },
  {
    code: "MV",
    dial_code: "960",
    flag: "🇲🇻",
    mobile_number_length: 7,
    name: "Maldives",
  },
  {
    code: "ML",
    dial_code: "223",
    flag: "🇲🇱",
    mobile_number_length: 8,
    name: "Mali",
  },
  {
    code: "MT",
    dial_code: "356",
    flag: "🇲🇹",
    mobile_number_length: 9,
    name: "Malta",
  },
  {
    code: "MH",
    dial_code: "692",
    flag: "🇲🇭",
    mobile_number_length: 7,
    name: "Marshall Islands",
  },
  {
    code: "MQ",
    dial_code: "596",
    flag: "🇲🇶",
    name: "Martinique",
  },
  {
    code: "MR",
    dial_code: "222",
    flag: "🇲🇷",
    mobile_number_length: 8,
    name: "Mauritania",
  },
  {
    code: "MU",
    dial_code: "230",
    flag: "🇲🇺",
    mobile_number_length: 8,
    name: "Mauritius",
  },
  {
    code: "YT",
    dial_code: "262",
    flag: "🇾🇹",
    name: "Mayotte",
  },
  {
    code: "MX",
    dial_code: "52",
    flag: "🇲🇽",
    mobile_number_length: 10,
    name: "Mexico",
  },
  {
    code: "FM",
    dial_code: "691",
    flag: "🇫🇲",
    mobile_number_length: 7,
    name: "Micronesia, Federated States of Micronesia",
  },
  {
    code: "MD",
    dial_code: "373",
    flag: "🇲🇩",
    mobile_number_length: 8,
    name: "Moldova",
  },
  {
    code: "MC",
    dial_code: "377",
    flag: "🇲🇨",
    mobile_number_length: 9,
    name: "Monaco",
  },
  {
    code: "MN",
    dial_code: "976",
    flag: "🇲🇳",
    mobile_number_length: 8,
    name: "Mongolia",
  },
  {
    code: "ME",
    dial_code: "382",
    flag: "🇲🇪",
    mobile_number_length: 9,
    name: "Montenegro",
  },
  {
    code: "MS",
    dial_code: "1664",
    flag: "🇲🇸",
    name: "Montserrat",
  },
  {
    code: "MA",
    dial_code: "212",
    flag: "🇲🇦",
    mobile_number_length: 10,
    name: "Morocco",
  },
  {
    code: "MZ",
    dial_code: "258",
    flag: "🇲🇿",
    mobile_number_length: 9,
    name: "Mozambique",
  },
  {
    code: "MM",
    dial_code: "95",
    flag: "🇲🇲",
    mobile_number_length: 9,
    name: "Myanmar",
  },
  {
    code: "NA",
    dial_code: "264",
    flag: "🇳🇦",
    mobile_number_length: 9,
    name: "Namibia",
  },
  {
    code: "NR",
    dial_code: "674",
    flag: "🇳🇷",
    mobile_number_length: 5,
    name: "Nauru",
  },
  {
    code: "NP",
    dial_code: "977",
    flag: "🇳🇵",
    mobile_number_length: 10,
    name: "Nepal",
  },
  {
    code: "NL",
    dial_code: "31",
    flag: "🇳🇱",
    mobile_number_length: 10,
    name: "Netherlands",
  },
  {
    code: "AN",
    dial_code: "599",
    flag: "",
    name: "Netherlands Antilles",
  },
  {
    code: "NC",
    dial_code: "687",
    flag: "🇳🇨",
    name: "New Caledonia",
  },
  {
    code: "NZ",
    dial_code: "64",
    flag: "🇳🇿",
    mobile_number_length: 10,
    name: "New Zealand",
  },
  {
    code: "NI",
    dial_code: "505",
    flag: "🇳🇮",
    mobile_number_length: 8,
    name: "Nicaragua",
  },
  {
    code: "NE",
    dial_code: "227",
    flag: "🇳🇪",
    mobile_number_length: 8,
    name: "Niger",
  },
  {
    code: "NG",
    dial_code: "234",
    flag: "🇳🇬",
    mobile_number_length: 11,
    name: "Nigeria",
  },
  {
    code: "NU",
    dial_code: "683",
    flag: "🇳🇺",
    name: "Niue",
  },
  {
    code: "NF",
    dial_code: "672",
    flag: "🇳🇫",
    name: "Norfolk Island",
  },
  {
    code: "MP",
    dial_code: "1670",
    flag: "🇲🇵",
    name: "Northern Mariana Islands",
  },
  {
    code: "NO",
    dial_code: "47",
    flag: "🇳🇴",
    mobile_number_length: 8,
    name: "Norway",
  },
  {
    code: "OM",
    dial_code: "968",
    flag: "🇴🇲",
    mobile_number_length: 8,
    name: "Oman",
  },
  {
    code: "PK",
    dial_code: "92",
    flag: "🇵🇰",
    mobile_number_length: 10,
    name: "Pakistan",
  },
  {
    code: "PW",
    dial_code: "680",
    flag: "🇵🇼",
    mobile_number_length: 7,
    name: "Palau",
  },
  {
    code: "PS",
    dial_code: "970",
    flag: "🇵🇸",
    mobile_number_length: 9,
    name: "Palestinian Territory, Occupied",
  },
  {
    code: "PA",
    dial_code: "507",
    flag: "🇵🇦",
    mobile_number_length: 8,
    name: "Panama",
  },
  {
    code: "PG",
    dial_code: "675",
    flag: "🇵🇬",
    mobile_number_length: 7,
    name: "Papua New Guinea",
  },
  {
    code: "PY",
    dial_code: "595",
    flag: "🇵🇾",
    mobile_number_length: 9,
    name: "Paraguay",
  },
  {
    code: "PE",
    dial_code: "51",
    flag: "🇵🇪",
    mobile_number_length: 9,
    name: "Peru",
  },
  {
    code: "PH",
    dial_code: "63",
    flag: "🇵🇭",
    mobile_number_length: 10,
    name: "Philippines",
  },
  {
    code: "PN",
    dial_code: "64",
    flag: "🇵🇳",
    name: "Pitcairn",
  },
  {
    code: "PL",
    dial_code: "48",
    flag: "🇵🇱",
    mobile_number_length: 9,
    name: "Poland",
  },
  {
    code: "PT",
    dial_code: "351",
    flag: "🇵🇹",
    mobile_number_length: 9,
    name: "Portugal",
  },
  {
    code: "PR",
    dial_code: "1939",
    flag: "🇵🇷",
    name: "Puerto Rico",
  },
  {
    code: "QA",
    dial_code: "974",
    flag: "🇶🇦",
    mobile_number_length: 8,
    name: "Qatar",
  },
  {
    code: "RO",
    dial_code: "40",
    flag: "🇷🇴",
    mobile_number_length: 10,
    name: "Romania",
  },
  {
    code: "RU",
    dial_code: "7",
    flag: "🇷🇺",
    mobile_number_length: 10,
    name: "Russia",
  },
  {
    code: "RW",
    dial_code: "250",
    flag: "🇷🇼",
    mobile_number_length: 9,
    name: "Rwanda",
  },
  {
    code: "RE",
    dial_code: "262",
    flag: "🇷🇪",
    name: "Reunion",
  },
  {
    code: "BL",
    dial_code: "590",
    flag: "🇧🇱",
    name: "Saint Barthelemy",
  },
  {
    code: "SH",
    dial_code: "290",
    flag: "🇸🇭",
    name: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  {
    code: "KN",
    dial_code: "1869",
    flag: "🇰🇳",
    mobile_number_length: 7,
    name: "Saint Kitts and Nevis",
  },
  {
    code: "LC",
    dial_code: "1758",
    flag: "🇱🇨",
    mobile_number_length: 7,
    name: "Saint Lucia",
  },
  {
    code: "MF",
    dial_code: "590",
    flag: "🇲🇫",
    name: "Saint Martin",
  },
  {
    code: "PM",
    dial_code: "508",
    flag: "🇵🇲",
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "VC",
    dial_code: "1784",
    flag: "🇻🇨",
    mobile_number_length: 7,
    name: "Saint Vincent and the Grenadines",
  },
  {
    code: "WS",
    dial_code: "685",
    flag: "🇼🇸",
    mobile_number_length: 7,
    name: "Samoa",
  },
  {
    code: "SM",
    dial_code: "378",
    flag: "🇸🇲",
    mobile_number_length: 9,
    name: "San Marino",
  },
  {
    code: "ST",
    dial_code: "239",
    flag: "🇸🇹",
    mobile_number_length: 7,
    name: "Sao Tome and Principe",
  },
  {
    code: "SA",
    dial_code: "966",
    flag: "🇸🇦",
    mobile_number_length: 9,
    name: "Saudi Arabia",
  },
  {
    code: "SN",
    dial_code: "221",
    flag: "🇸🇳",
    mobile_number_length: 9,
    name: "Senegal",
  },
  {
    code: "RS",
    dial_code: "381",
    flag: "🇷🇸",
    mobile_number_length: 9,
    name: "Serbia",
  },
  {
    code: "SC",
    dial_code: "248",
    flag: "🇸🇨",
    mobile_number_length: 7,
    name: "Seychelles",
  },
  {
    code: "SL",
    dial_code: "232",
    flag: "🇸🇱",
    mobile_number_length: 8,
    name: "Sierra Leone",
  },
  {
    code: "SG",
    dial_code: "65",
    flag: "🇸🇬",
    mobile_number_length: 8,
    name: "Singapore",
  },
  {
    code: "SK",
    dial_code: "421",
    flag: "🇸🇰",
    mobile_number_length: 9,
    name: "Slovakia",
  },
  {
    code: "SI",
    dial_code: "386",
    flag: "🇸🇮",
    mobile_number_length: 9,
    name: "Slovenia",
  },
  {
    code: "SB",
    dial_code: "677",
    flag: "🇸🇧",
    mobile_number_length: 7,
    name: "Solomon Islands",
  },
  {
    code: "SO",
    dial_code: "252",
    flag: "🇸🇴",
    mobile_number_length: 8,
    name: "Somalia",
  },
  {
    code: "ZA",
    dial_code: "27",
    flag: "🇿🇦",
    mobile_number_length: 9,
    name: "South Africa",
  },
  {
    code: "SS",
    dial_code: "211",
    flag: "🇸🇸",
    mobile_number_length: 9,
    name: "South Sudan",
  },
  {
    code: "GS",
    dial_code: "500",
    flag: "🇬🇸",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "ES",
    dial_code: "34",
    flag: "🇪🇸",
    mobile_number_length: 9,
    name: "Spain",
  },
  {
    code: "LK",
    dial_code: "94",
    flag: "🇱🇰",
    mobile_number_length: 10,
    name: "Sri Lanka",
  },
  {
    code: "SD",
    dial_code: "249",
    flag: "🇸🇩",
    mobile_number_length: 9,
    name: "Sudan",
  },
  {
    code: "SR",
    dial_code: "597",
    flag: "🇸🇷",
    mobile_number_length: 7,
    name: "Suriname",
  },
  {
    code: "SJ",
    dial_code: "47",
    flag: "🇸🇯",
    name: "Svalbard and Jan Mayen",
  },
  {
    code: "SZ",
    dial_code: "268",
    flag: "🇸🇿",
    mobile_number_length: 9,
    name: "Eswatini",
  },
  {
    code: "SE",
    dial_code: "46",
    flag: "🇸🇪",
    mobile_number_length: 9,
    name: "Sweden",
  },
  {
    code: "CH",
    dial_code: "41",
    flag: "🇨🇭",
    mobile_number_length: 9,
    name: "Switzerland",
  },
  {
    code: "SY",
    dial_code: "963",
    flag: "🇸🇾",
    mobile_number_length: 9,
    name: "Syrian Arab Republic",
  },
  {
    code: "TW",
    dial_code: "886",
    flag: "🇹🇼",
    mobile_number_length: 10,
    name: "Taiwan",
  },
  {
    code: "TJ",
    dial_code: "992",
    flag: "🇹🇯",
    mobile_number_length: 9,
    name: "Tajikistan",
  },
  {
    code: "TZ",
    dial_code: "255",
    flag: "🇹🇿",
    mobile_number_length: 9,
    name: "Tanzania, United Republic of Tanzania",
  },
  {
    code: "TH",
    dial_code: "66",
    flag: "🇹🇭",
    mobile_number_length: 10,
    name: "Thailand",
  },
  {
    code: "TL",
    dial_code: "670",
    flag: "🇹🇱",
    mobile_number_length: 8,
    name: "Timor-Leste",
  },
  {
    code: "TG",
    dial_code: "228",
    flag: "🇹🇬",
    mobile_number_length: 8,
    name: "Togo",
  },
  {
    code: "TK",
    dial_code: "690",
    flag: "🇹🇰",
    name: "Tokelau",
  },
  {
    code: "TO",
    dial_code: "676",
    flag: "🇹🇴",
    mobile_number_length: 7,
    name: "Tonga",
  },
  {
    code: "TT",
    dial_code: "1868",
    flag: "🇹🇹",
    mobile_number_length: 10,
    name: "Trinidad and Tobago",
  },
  {
    code: "TN",
    dial_code: "216",
    flag: "🇹🇳",
    mobile_number_length: 8,
    name: "Tunisia",
  },
  {
    code: "TR",
    dial_code: "90",
    flag: "🇹🇷",
    mobile_number_length: 10,
    name: "Turkey",
  },
  {
    code: "TM",
    dial_code: "993",
    flag: "🇹🇲",
    mobile_number_length: 8,
    name: "Turkmenistan",
  },
  {
    code: "TC",
    dial_code: "1649",
    flag: "🇹🇨",
    name: "Turks and Caicos Islands",
  },
  {
    code: "TV",
    dial_code: "688",
    flag: "🇹🇻",
    mobile_number_length: 5,
    name: "Tuvalu",
  },
  {
    code: "UG",
    dial_code: "256",
    flag: "🇺🇬",
    mobile_number_length: 9,
    name: "Uganda",
  },
  {
    code: "UA",
    dial_code: "380",
    flag: "🇺🇦",
    mobile_number_length: 9,
    name: "Ukraine",
  },
  {
    code: "AE",
    dial_code: "971",
    flag: "🇦🇪",
    mobile_number_length: 9,
    name: "United Arab Emirates",
  },
  {
    code: "GB",
    dial_code: "44",
    flag: "🇬🇧",
    mobile_number_length: 10,
    name: "United Kingdom",
  },
  {
    code: "US",
    dial_code: "1",
    flag: "🇺🇸",
    mobile_number_length: 10,
    name: "United States",
  },
  {
    code: "UY",
    dial_code: "598",
    flag: "🇺🇾",
    mobile_number_length: 9,
    name: "Uruguay",
  },
  {
    code: "UZ",
    dial_code: "998",
    flag: "🇺🇿",
    mobile_number_length: 9,
    name: "Uzbekistan",
  },
  {
    code: "VU",
    dial_code: "678",
    flag: "🇻🇺",
    mobile_number_length: 7,
    name: "Vanuatu",
  },
  {
    code: "VE",
    dial_code: "58",
    flag: "🇻🇪",
    mobile_number_length: 10,
    name: "Venezuela, Bolivarian Republic of Venezuela",
  },
  {
    code: "VN",
    dial_code: "84",
    flag: "🇻🇳",
    mobile_number_length: 10,
    name: "Vietnam",
  },
  {
    code: "VG",
    dial_code: "1284",
    flag: "🇻🇬",
    name: "Virgin Islands, British",
  },
  {
    code: "VI",
    dial_code: "1340",
    flag: "🇻🇮",
    name: "Virgin Islands, U.S.",
  },
  {
    code: "WF",
    dial_code: "681",
    flag: "🇼🇫",
    name: "Wallis and Futuna",
  },
  {
    code: "YE",
    dial_code: "967",
    flag: "🇾🇪",
    mobile_number_length: 9,
    name: "Yemen",
  },
  {
    code: "ZM",
    dial_code: "260",
    flag: "🇿🇲",
    mobile_number_length: 9,
    name: "Zambia",
  },
  {
    code: "ZW",
    dial_code: "263",
    flag: "🇿🇼",
    mobile_number_length: 9,
    name: "Zimbabwe",
  },
];

// export const CWM = [
//   { code: "AF", name: "Afghanistan", mobile_number_length: 9 },
//   { code: "AL", name: "Albania", mobile_number_length: 9 },
//   { code: "DZ", name: "Algeria", mobile_number_length: 10 },
//   { code: "AD", name: "Andorra", mobile_number_length: 9 },
//   { code: "AO", name: "Angola", mobile_number_length: 9 },
//   { code: "AG", name: "Antigua and Barbuda", mobile_number_length: 10 },
//   { code: "AR", name: "Argentina", mobile_number_length: 10 },
//   { code: "AM", name: "Armenia", mobile_number_length: 8 },
//   { code: "AU", name: "Australia", mobile_number_length: 10 },
//   { code: "AT", name: "Austria", mobile_number_length: 11 },
//   { code: "AZ", name: "Azerbaijan", mobile_number_length: 9 },
//   { code: "BS", name: "Bahamas", mobile_number_length: 10 },
//   { code: "BH", name: "Bahrain", mobile_number_length: 8 },
//   { code: "BD", name: "Bangladesh", mobile_number_length: 11 },
//   { code: "BB", name: "Barbados", mobile_number_length: 10 },
//   { code: "BY", name: "Belarus", mobile_number_length: 9 },
//   { code: "BE", name: "Belgium", mobile_number_length: 9 },
//   { code: "BZ", name: "Belize", mobile_number_length: 7 },
//   { code: "BJ", name: "Benin", mobile_number_length: 8 },
//   { code: "BT", name: "Bhutan", mobile_number_length: 8 },
//   { code: "BO", name: "Bolivia", mobile_number_length: 8 },
//   { code: "BA", name: "Bosnia and Herzegovina", mobile_number_length: 8 },
//   { code: "BW", name: "Botswana", mobile_number_length: 9 },
//   { code: "BR", name: "Brazil", mobile_number_length: 11 },
//   { code: "BN", name: "Brunei", mobile_number_length: 8 },
//   { code: "BG", name: "Bulgaria", mobile_number_length: 10 },
//   { code: "BF", name: "Burkina Faso", mobile_number_length: 8 },
//   { code: "BI", name: "Burundi", mobile_number_length: 8 },
//   { code: "CV", name: "Cabo Verde", mobile_number_length: 7 },
//   { code: "KH", name: "Cambodia", mobile_number_length: 9 },
//   { code: "CM", name: "Cameroon", mobile_number_length: 9 },
//   { code: "CA", name: "Canada", mobile_number_length: 10 },
//   { code: "CF", name: "Central African Republic", mobile_number_length: 8 },
//   { code: "TD", name: "Chad", mobile_number_length: 8 },
//   { code: "CL", name: "Chile", mobile_number_length: 9 },
//   { code: "CN", name: "China", mobile_number_length: 11 },
//   { code: "CO", name: "Colombia", mobile_number_length: 10 },
//   { code: "KM", name: "Comoros", mobile_number_length: 7 },
//   { code: "CD", name: "Congo", mobile_number_length: 9 },
//   { code: "CR", name: "Costa Rica", mobile_number_length: 8 },
//   { code: "HR", name: "Croatia", mobile_number_length: 9 },
//   { code: "CU", name: "Cuba", mobile_number_length: 8 },
//   { code: "CY", name: "Cyprus", mobile_number_length: 9 },
//   { code: "CZ", name: "Czech Republic", mobile_number_length: 9 },
//   { code: "DK", name: "Denmark", mobile_number_length: 8 },
//   { code: "DJ", name: "Djibouti", mobile_number_length: 8 },
//   { code: "DM", name: "Dominica", mobile_number_length: 7 },
//   { code: "DO", name: "Dominican Republic", mobile_number_length: 10 },
//   { code: "EC", name: "Ecuador", mobile_number_length: 9 },
//   { code: "EG", name: "Egypt", mobile_number_length: 10 },
//   { code: "SV", name: "El Salvador", mobile_number_length: 8 },
//   { code: "GQ", name: "Equatorial Guinea", mobile_number_length: 8 },
//   { code: "ER", name: "Eritrea", mobile_number_length: 8 },
//   { code: "EE", name: "Estonia", mobile_number_length: 8 },
//   { code: "ET", name: "Ethiopia", mobile_number_length: 9 },
//   { code: "FJ", name: "Fiji", mobile_number_length: 7 },
//   { code: "FI", name: "Finland", mobile_number_length: 10 },
//   { code: "FR", name: "France", mobile_number_length: 9 },
//   { code: "GA", name: "Gabon", mobile_number_length: 8 },
//   { code: "GM", name: "Gambia", mobile_number_length: 8 },
//   { code: "GE", name: "Georgia", mobile_number_length: 9 },
//   { code: "DE", name: "Germany", mobile_number_length: 11 },
//   { code: "GH", name: "Ghana", mobile_number_length: 9 },
//   { code: "GR", name: "Greece", mobile_number_length: 10 },
//   { code: "GD", name: "Grenada", mobile_number_length: 7 },
//   { code: "GT", name: "Guatemala", mobile_number_length: 8 },
//   { code: "GN", name: "Guinea", mobile_number_length: 8 },
//   { code: "GW", name: "Guinea-Bissau", mobile_number_length: 8 },
//   { code: "GY", name: "Guyana", mobile_number_length: 8 },
//   { code: "HT", name: "Haiti", mobile_number_length: 8 },
//   { code: "HN", name: "Honduras", mobile_number_length: 8 },
//   { code: "HU", name: "Hungary", mobile_number_length: 9 },
//   { code: "IS", name: "Iceland", mobile_number_length: 7 },
//   { code: "IN", name: "India", mobile_number_length: 10 },
//   { code: "ID", name: "Indonesia", mobile_number_length: 11 },
//   { code: "IR", name: "Iran", mobile_number_length: 10 },
//   { code: "IQ", name: "Iraq", mobile_number_length: 10 },
//   { code: "IE", name: "Ireland", mobile_number_length: 10 },
//   { code: "IL", name: "Israel", mobile_number_length: 9 },
//   { code: "IT", name: "Italy", mobile_number_length: 10 },
//   { code: "JM", name: "Jamaica", mobile_number_length: 10 },
//   { code: "JP", name: "Japan", mobile_number_length: 11 },
//   { code: "JO", name: "Jordan", mobile_number_length: 9 },
//   { code: "KZ", name: "Kazakhstan", mobile_number_length: 10 },
//   { code: "KE", name: "Kenya", mobile_number_length: 9 },
//   { code: "KI", name: "Kiribati", mobile_number_length: 5 },
//   { code: "KR", name: "Korea, North", mobile_number_length: 11 },
//   { code: "KR", name: "Korea, South", mobile_number_length: 11 },
//   { code: "XK", name: "Kosovo", mobile_number_length: 9 },
//   { code: "KW", name: "Kuwait", mobile_number_length: 8 },
//   { code: "KG", name: "Kyrgyzstan", mobile_number_length: 9 },
//   { code: "LA", name: "Laos", mobile_number_length: 9 },
//   { code: "LV", name: "Latvia", mobile_number_length: 8 },
//   { code: "LB", name: "Lebanon", mobile_number_length: 8 },
//   { code: "LS", name: "Lesotho", mobile_number_length: 8 },
//   { code: "LR", name: "Liberia", mobile_number_length: 8 },
//   { code: "LY", name: "Libya", mobile_number_length: 9 },
//   { code: "LI", name: "Liechtenstein", mobile_number_length: 9 },
//   { code: "LT", name: "Lithuania", mobile_number_length: 8 },
//   { code: "LU", name: "Luxembourg", mobile_number_length: 9 },
//   { code: "MG", name: "Madagascar", mobile_number_length: 9 },
//   { code: "MW", name: "Malawi", mobile_number_length: 9 },
//   { code: "MY", name: "Malaysia", mobile_number_length: 10 },
//   { code: "MV", name: "Maldives", mobile_number_length: 7 },
//   { code: "ML", name: "Mali", mobile_number_length: 8 },
//   { code: "MT", name: "Malta", mobile_number_length: 9 },
//   { code: "MH", name: "Marshall Islands", mobile_number_length: 7 },
//   { code: "MR", name: "Mauritania", mobile_number_length: 8 },
//   { code: "MU", name: "Mauritius", mobile_number_length: 8 },
//   { code: "MX", name: "Mexico", mobile_number_length: 10 },
//   { code: "FM", name: "Micronesia", mobile_number_length: 7 },
//   { code: "MD", name: "Moldova", mobile_number_length: 8 },
//   { code: "MC", name: "Monaco", mobile_number_length: 9 },
//   { code: "MN", name: "Mongolia", mobile_number_length: 8 },
//   { code: "ME", name: "Montenegro", mobile_number_length: 9 },
//   { code: "MA", name: "Morocco", mobile_number_length: 10 },
//   { code: "MZ", name: "Mozambique", mobile_number_length: 9 },
//   { code: "MM", name: "Myanmar", mobile_number_length: 9 },
//   { code: "NA", name: "Namibia", mobile_number_length: 9 },
//   { code: "NR", name: "Nauru", mobile_number_length: 5 },
//   { code: "NP", name: "Nepal", mobile_number_length: 10 },
//   { code: "NL", name: "Netherlands", mobile_number_length: 10 },
//   { code: "NZ", name: "New Zealand", mobile_number_length: 10 },
//   { code: "NI", name: "Nicaragua", mobile_number_length: 8 },
//   { code: "NE", name: "Niger", mobile_number_length: 8 },
//   { code: "NG", name: "Nigeria", mobile_number_length: 11 },
//   { code: "MK", name: "North Macedonia", mobile_number_length: 8 },
//   { code: "NO", name: "Norway", mobile_number_length: 8 },
//   { code: "OM", name: "Oman", mobile_number_length: 8 },
//   { code: "PK", name: "Pakistan", mobile_number_length: 10 },
//   { code: "PW", name: "Palau", mobile_number_length: 7 },
//   { code: "PS", name: "Palestine", mobile_number_length: 9 },
//   { code: "PA", name: "Panama", mobile_number_length: 8 },
//   { code: "PG", name: "Papua New Guinea", mobile_number_length: 7 },
//   { code: "PY", name: "Paraguay", mobile_number_length: 9 },
//   { code: "PE", name: "Peru", mobile_number_length: 9 },
//   { code: "PH", name: "Philippines", mobile_number_length: 10 },
//   { code: "PL", name: "Poland", mobile_number_length: 9 },
//   { code: "PT", name: "Portugal", mobile_number_length: 9 },
//   { code: "QA", name: "Qatar", mobile_number_length: 8 },
//   { code: "RO", name: "Romania", mobile_number_length: 10 },
//   { code: "RU", name: "Russia", mobile_number_length: 10 },
//   { code: "RW", name: "Rwanda", mobile_number_length: 9 },
//   { code: "KN", name: "Saint Kitts and Nevis", mobile_number_length: 7 },
//   { code: "LC", name: "Saint Lucia", mobile_number_length: 7 },
//   {
//     code: "VC",
//     name: "Saint Vincent and the Grenadines",
//     mobile_number_length: 7,
//   },
//   { code: "WS", name: "Samoa", mobile_number_length: 7 },
//   { code: "SM", name: "San Marino", mobile_number_length: 9 },
//   { code: "ST", name: "Sao Tome and Principe", mobile_number_length: 7 },
//   { code: "SA", name: "Saudi Arabia", mobile_number_length: 9 },
//   { code: "SN", name: "Senegal", mobile_number_length: 9 },
//   { code: "RS", name: "Serbia", mobile_number_length: 9 },
//   { code: "SC", name: "Seychelles", mobile_number_length: 7 },
//   { code: "SL", name: "Sierra Leone", mobile_number_length: 8 },
//   { code: "SG", name: "Singapore", mobile_number_length: 8 },
//   { code: "SK", name: "Slovakia", mobile_number_length: 9 },
//   { code: "SI", name: "Slovenia", mobile_number_length: 9 },
//   { code: "SB", name: "Solomon Islands", mobile_number_length: 7 },
//   { code: "SO", name: "Somalia", mobile_number_length: 8 },
//   { code: "ZA", name: "South Africa", mobile_number_length: 9 },
//   { code: "SS", name: "South Sudan", mobile_number_length: 9 },
//   { code: "ES", name: "Spain", mobile_number_length: 9 },
//   { code: "LK", name: "Sri Lanka", mobile_number_length: 10 },
//   { code: "SD", name: "Sudan", mobile_number_length: 9 },
//   { code: "SR", name: "Suriname", mobile_number_length: 7 },
//   { code: "SZ", name: "Eswatini", mobile_number_length: 9 },
//   { code: "SE", name: "Sweden", mobile_number_length: 9 },
//   { code: "CH", name: "Switzerland", mobile_number_length: 9 },
//   { code: "SY", name: "Syria", mobile_number_length: 9 },
//   { code: "TW", name: "Taiwan", mobile_number_length: 10 },
//   { code: "TJ", name: "Tajikistan", mobile_number_length: 9 },
//   { code: "TZ", name: "Tanzania", mobile_number_length: 9 },
//   { code: "TH", name: "Thailand", mobile_number_length: 10 },
//   { code: "TL", name: "Timor-Leste", mobile_number_length: 8 },
//   { code: "TG", name: "Togo", mobile_number_length: 8 },
//   { code: "TO", name: "Tonga", mobile_number_length: 7 },
//   { code: "TT", name: "Trinidad and Tobago", mobile_number_length: 10 },
//   { code: "TN", name: "Tunisia", mobile_number_length: 8 },
//   { code: "TR", name: "Turkey", mobile_number_length: 10 },
//   { code: "TM", name: "Turkmenistan", mobile_number_length: 8 },
//   { code: "TV", name: "Tuvalu", mobile_number_length: 5 },
//   { code: "UG", name: "Uganda", mobile_number_length: 9 },
//   { code: "UA", name: "Ukraine", mobile_number_length: 9 },
//   { code: "AE", name: "United Arab Emirates", mobile_number_length: 9 },
//   { code: "GB", name: "United Kingdom", mobile_number_length: 10 },
//   { code: "US", name: "United States of America", mobile_number_length: 10 },
//   { code: "UY", name: "Uruguay", mobile_number_length: 9 },
//   { code: "UZ", name: "Uzbekistan", mobile_number_length: 9 },
//   { code: "VU", name: "Vanuatu", mobile_number_length: 7 },
//   { code: "VA", name: "Vatican City", mobile_number_length: 9 },
//   { code: "VE", name: "Venezuela", mobile_number_length: 10 },
//   { code: "VN", name: "Vietnam", mobile_number_length: 10 },
//   { code: "YE", name: "Yemen", mobile_number_length: 9 },
//   { code: "ZM", name: "Zambia", mobile_number_length: 9 },
//   { code: "ZW", name: "Zimbabwe", mobile_number_length: 9 },
// ];

/**
 * Taxation types
 * @constant
 * String Dropdown (STRING_DD)
 */
export const TAXATION_OPTIONS: Array<T_STRING_DD> = [
  { label: "with Tax", value: "WT" },
  { label: "without Tax", value: "WOT" },
];

/**
 * Tax rates
 * @constant
 * Number Dropdown (STRING_DD)
 */
export const TAX_RATES: Array<T_NUMERIC_DD> = [
  { label: "5%", value: 5 },
  { label: "12%", value: 12 },
  { label: "18%", value: 18 },
  { label: "28%", value: 28 },
];

//ENUMS
/**
 * Represents all the statuses that an invoice could be in
 * @enum
 */
export enum INVOICE_STATUS {
  "Pending" = 0,
  "Partially Paid" = 1,
  "Paid" = 2,
  "Cancelled" = 3,
}

/**
 * Represents the type of parties supported
 * @enum
 */
export enum PARTY_TYPE {
  "Customer" = 0,
  "Vendor" = 1,
}

/**
 * Types of addresses
 * @enum
 */
export enum ADDRESS_TYPES {
  "Residential" = 0,
  "Business" = 1,
  "Other" = 2,
}

/**
 * Types of transactions that can happen against an invoice
 * @enum
 */
export enum INVOICE_TRANSACTION_TYPES {
  "Payment" = 0,
  "Refund" = 1,
}

/**
 * Types of stock flows
 * @enum
 */
export enum STOCKFLOW_TYPES {
  "StockIn" = 0,
  "StockOut" = 1,
}

export enum CREATION_SIDEPANEL_DATA_TYPE {
  "inventory",
  "customer",
  "stockIn",
  "stockout",
  "address",
}

export enum LEDGER_TYPES {
  "Payment" = 0,
  "Refund" = 1,
  "Invoice" = 2,
  "StockIn" = 3,
  "StockOut" = 4,
  "PurchaseInvoice" = 5,
  "PurchaseOrder" = 6,
  "CreditNote" = 7,
  "Subscription" = 8,
  "DebitNote" = 9,
  "Challan" = 10,
}

export enum LEDGER_STATUS {
  "Success" = 0,
}

/**
 * Types of inventory items
 * @enum
 */
export enum INVENTORY_ITEM_TYPE {
  Product = 0,
  Service = 1,
}

/**
 * Inventory Type Options
 * @constant
 * Number Dropdown (STRING_DD)
 */
export const INVENTORY_ITEM_OPTIONS = [
  { label: "Product", value: INVENTORY_ITEM_TYPE.Product },
  { label: "Service", value: INVENTORY_ITEM_TYPE.Service },
];

/**
 * Available weight units in bilvoic
 * @enum
 */
export enum ITEM_WEIGHT_UNIT {
  "Kilogram (kg)" = 0,
  "Gram (gm)" = 1,
  "Milligram (mg)" = 2,
  "Metric Tonne (tonne)" = 3,
  "Quintal (q)" = 4,
  "Ounce (oz)" = 5,
}

/**
 * Types of challans
 * @enum
 */
export enum CHALLAN_TYPE {
  Inward = 0,
  Outward = 1,
}

export const WEIGHT_CONVERSION_RATES = {
  0: {
    // Kilogram
    0: 1,
    1: 1000,
    2: 1000000,
    3: 0.001,
    4: 0.01,
    5: 35.274,
    6: 2.20462,
  },
  1: {
    // Gram
    0: 0.001,
    1: 1,
    2: 1000,
    3: 0.000001,
    4: 0.00001,
    5: 0.035274,
    6: 0.00220462,
  },
  2: {
    // Milligram
    0: 0.000001,
    1: 0.001,
    2: 1,
    3: 0.000000001,
    4: 0.00000001,
    5: 0.000035274,
    6: 0.00000220462,
  },
  3: {
    // Metric Tonne
    0: 1000,
    1: 1000000,
    2: 1000000000,
    3: 1,
    4: 10,
    5: 35274,
    6: 2204.62,
  },
  4: {
    // Quintal
    0: 100,
    1: 100000,
    2: 100000000,
    3: 0.1,
    4: 1,
    5: 3527.4,
    6: 220.462,
  },
  5: {
    // Ounce
    0: 0.0283495,
    1: 28.3495,
    2: 28349.5,
    3: 0.0000283495,
    4: 0.000283495,
    5: 1,
    6: 0.0625,
  },
  6: {
    // Pound
    0: 0.453592,
    1: 453.592,
    2: 453592,
    3: 0.000453592,
    4: 0.00453592,
    5: 16,
    6: 1,
  },
};

/**
 * Available options for dashboard periods
 * @enum
 */
export enum DASHBOARD_PERIODS {
  "Month" = 0,
  "Quarter" = 1,
  "BiAnnual" = 2,
  "Annual" = 3,
}
