import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "([formControlName], [formControl])[disableControl]",
})
export class DisableControlDirective implements OnChanges {
  // @Input() set disableControl(condition: boolean) {
  //   const action = condition ? "disable" : "enable";
  //   if (this.ngControl.control) {
  //     this.ngControl?.control[action]();
  //   }
  // }
  @Input() disableControl!: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["disableControl"].currentValue) {
      this.ngControl.control?.disable();
    } else {
      this.ngControl.control?.enable();
    }
  }

  constructor(private ngControl: NgControl) {}
}
