import { Component, Input, OnInit } from "@angular/core";
import Party from "src/app/core/models/party.model";
import { Address } from "src/app/core/models/address.model";
import {
  LedgerService,
  LedgerFilters,
} from "src/app/core/services/ledger.service";
import { Ledger } from "src/app/core/models/ledger.model";
import { get } from "lodash";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { LEDGER_TYPES } from "src/app/core/constants/constants";

interface LedgerColumn {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
  shouldConcat?: boolean;
  concatField?: string;
  subField?: string;
}

@Component({
  selector: "app-view-party",
  templateUrl: "./view-party.component.html",
  styleUrls: ["./view-party.component.scss"],
})
export class ViewPartyComponent implements OnInit {
  @Input() party!: Party;

  _ = get;

  math = Math;

  parseFloat = parseFloat;

  LEDGER_TYPES = LEDGER_TYPES;

  isLedgerLoaded = false;

  columns: LedgerColumn[] = [];

  ledger: Ledger[] = [];

  constructor(public lds: LedgerService, public utilities: UtilitiesService) {}

  ngOnInit(): void {
    this.getLedgerForParty(this.party.id!);

    this.columns = [
      {
        field: "type",
        header: "",
        showAs: "icon",
        width: "2rem",
      },
      {
        field: "title",
        header: "Title",
        shouldConcat: false,
      },
      {
        field: "value",
        header: "Amount",
        shouldConcat: false,
        showAs: "currency",
      },
      {
        field: "status",
        header: "Status",
        shouldConcat: false,
      },
      {
        field: "date",
        header: "Date",
        shouldConcat: false,
        showAs: "date",
      },
    ];
  }

  getLedgerForParty(partyId: string) {
    const ledgerFilters: LedgerFilters = { partyId };

    this.lds.getFilteredLedger(ledgerFilters).subscribe({
      next: (response: any) => {
        console.log("response of filtered ledger :: ", response);
        this.ledger = response.data;
      },
    });
  }

  displayAddress(address: Address | undefined): string {
    let formattedAddress = "";

    if (address === undefined) return formattedAddress;

    formattedAddress = formattedAddress
      .concat(address.nick_name || "")
      .concat(address.nick_name ? ", " : "")
      .concat(address.line_1 || "")
      .concat(address.line_1 ? ", " : "")
      .concat(address.line_2 || "")
      .concat(address.line_2 ? ", " : "")
      .concat(address.landmark || "")
      .concat(address.landmark ? ", " : "")
      .concat(address.city || "")
      .concat(address.city ? " " : "")
      .concat(address.state)
      .concat(", ")
      .concat(address.postal_code || "");

    return formattedAddress;
  }
}
