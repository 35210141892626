import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appTypewriterEffect]",
})
export class TypewriterEffectDirective {
  @Input() textArray: string[] = [];
  @Input() typingSpeed: number = 50; // Default typing speed
  @Input() pauseDuration: number = 2000; // Default pause duration between strings

  private currentTextIndex: number = 0;

  constructor(private el: ElementRef) {}
  ngOnInit() {
    if (this.textArray.length > 0) {
      this.typeText();
    }
  }

  private async typeText() {
    const text = this.textArray[this.currentTextIndex];
    for (let i = 0; i <= text.length; i++) {
      this.el.nativeElement.innerHTML = text.substring(0, i);
      await this.delay(this.typingSpeed);
    }
    await this.delay(this.pauseDuration);
    await this.deleteText();
    this.currentTextIndex = (this.currentTextIndex + 1) % this.textArray.length;
    this.typeText();
  }

  private async deleteText() {
    const text = this.textArray[this.currentTextIndex];
    for (let i = text.length; i >= 0; i--) {
      this.el.nativeElement.innerHTML = text.substring(0, i);
      await this.delay(this.typingSpeed);
    }
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
