import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

export interface ToastMessage {
  show: boolean;
  severity: "success" | "info" | "warn" | "error";
  summary: string;
  detail: string;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private subject = new BehaviorSubject<ToastMessage>({} as ToastMessage);

  sendMessage(toastMessage: ToastMessage) {
    this.subject.next(toastMessage);
  }

  getMessasge(): Observable<ToastMessage> {
    return this.subject.asObservable();
  }
}
