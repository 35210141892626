import { createSelector } from "@ngrx/store";
import { Company } from "../models/company.model";
import * as CompanyActions from "../store_actions/company.actions";

export interface CompaniesState {
  companies: Array<Company>;
  currentCompany: Company;
}

const initialCompaniesState: CompaniesState = {
  companies: [],
  currentCompany: {} as Company,
};

export function companiesReducer(
  companiesState = initialCompaniesState,
  action: any
): CompaniesState {
  switch (action.type) {
    case CompanyActions.setCompanies.type:
      return {
        companies: action.companies,
        currentCompany: action.currentCompany,
      };
    case CompanyActions.resetCompanies.type:
      return {
        companies: [],
        currentCompany: {} as Company,
      };
    default:
      break;
  }
  return companiesState;
}

export const selectCompaniesState = (state: any) => state?.companiesState;

export const selectCompanies = createSelector(
  selectCompaniesState,
  (state) => state?.companies
);
