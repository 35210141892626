import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { SideNavToggle } from "./layout/side-nav/side-nav-toggle.interface";
import { PrimeNGConfig } from "primeng/api";
import { LoginStatusService } from "./core/services/login-status.service";
import { Store, select } from "@ngrx/store";
import { AppState } from "./app.state";
import { selectAppState } from "./core/state_reducers/index";
import { selectUser } from "./core/state_reducers/user.reducer";
import User from "./core/models/user.model";
import { Router } from "@angular/router";
import {
  SlidepanelService,
  SidePanelData,
} from "./core/services/slidepanel.service";
import { Subscription } from "rxjs";
import { LoadingspinnerService } from "./core/services/loadingspinner.service";
import { UtilitiesService } from "./core/services/utilities.service";
import {
  CreationSlidepanelService,
  CreationSlidePanelData,
} from "./core/services/creation-slidepanel.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "bilvoic-frontend-base";

  isSideNavCollapsed = false;

  screenWidth = 0;

  contentClass = "";

  isLoggedIn: boolean = false;

  isSidePanelOpened: boolean = false;

  sidePanelData!: SidePanelData;

  isCreationPanelOpened: boolean = false;

  createPanelData: CreationSlidePanelData = {} as CreationSlidePanelData;

  isLoading: boolean | null = false;

  loadingSpinnerSubscription!: Subscription;

  constructor(
    private primengConfig: PrimeNGConfig,
    private lss: LoginStatusService,
    private store: Store<AppState>,
    private router: Router,
    private sps: SlidepanelService,
    private csps: CreationSlidepanelService,
    private loaderService: LoadingspinnerService,
    private utilities: UtilitiesService
  ) {
    this.lss.getMessasge().subscribe((loginStatus) => {
      this.isLoggedIn = loginStatus;
      if (!loginStatus) {
        const user = new User({});
        this.utilities.setUserInService(user);
      }
    });

    this.sps.getMessasge().subscribe((data) => {
      this.isSidePanelOpened = data.openStatus;
      this.sidePanelData = data;
    });

    this.csps.getMessasge().subscribe((data) => {
      this.isCreationPanelOpened = data.openStatus;
      this.createPanelData = data;
    });
  }

  @HostListener("window:unload", ["$event"])
  @HostListener("document.pagehide", ["$event"])
  beforeUnloadHandler = () => {
    this.store.pipe(select(selectAppState)).subscribe((appState) => {
      console.log("whole app state :: ", JSON.stringify(appState));
      localStorage.setItem("state", JSON.stringify(appState));
    });
  };

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenWidth = event.target?.innerWidth;
  }

  ngOnInit() {
    this.primengConfig.ripple = true;

    // detect broswer theme and set favicon
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      document.querySelector("#favIcon") !== null
    ) {
      let favIcon: HTMLLinkElement = document.querySelector("#favIcon")!;
      favIcon.href = `assets/img/astral-logo-light.svg`;
    }

    this.store.pipe(select(selectAppState)).subscribe((app) => {
      const userState = app.userState;
      const companiesState = app.companiesState;

      if (
        userState &&
        userState.user?.isLoggedIn &&
        sessionStorage.getItem("ast-usr-tk")
      ) {
        this.utilities.setUserInService(userState.user);
        this.utilities.setCompaniesInService(companiesState.companies);
        this.utilities.setCurrentCompany(companiesState.currentCompany);
        this.isLoggedIn = userState.user.isLoggedIn;
        if (
          window.location.pathname.includes("/login") ||
          window.location.pathname === "/"
        ) {
          this.router.navigate(["sales"]);
        }
      } else {
        if (!window.location.pathname.includes("/signup")) {
          localStorage.removeItem("state");
          // sessionStorage.clear();
          this.router.navigate(["login"]);
        }
      }
    });

    // this.loadingSpinnerSubscription = this.loaderService
    //   .getMessage()
    //   .subscribe((isLoading) => {
    //     this.isLoading = isLoading;
    //   });
  }

  ngAfterViewInit() {
    this.loadingSpinnerSubscription = this.loaderService
      .getMessage()
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  ngOnDestroy(): void {
    this.loadingSpinnerSubscription?.unsubscribe();
  }

  onToggleSideNav(data: SideNavToggle) {
    this.isSideNavCollapsed = data.collapsed;
    this.screenWidth = data.screenWidth;
  }
}
