import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByEpoch",
})
export class SortByEpochPipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    return value.sort((n1, n2) => {
      const epochA = n1[key] || 0;
      const epochB = n2[key] || 0;

      return epochB - epochA;
    });
  }
}
