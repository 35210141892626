import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/core/services/user.service";
import { ProductService } from "src/app/core/services/product.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { InvoiceItem } from "src/app/core/models/invoice-item.model";
import { InvoiceService } from "src/app/core/services/invoice.service";
import {
  CreationSlidepanelService,
  CreationSlidePanelData,
} from "src/app/core/services/creation-slidepanel.service";
import { CustomerService } from "src/app/core/services/customer.service";
import {
  PaymentTransaction,
  PAYMENT_TRANSACTION_TYPE,
  PAYMENT_METHOD_OPTIONS,
} from "src/app/core/models/payment-transaction";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  ToastMessage,
  ToastService,
} from "src/app/core/services/toast.service";
import { CHALLAN_TYPE, INVOICE_STATUS } from "src/app/core/constants/constants";
import { CREATION_SIDEPANEL_DATA_TYPE } from "src/app/core/constants/constants";
import { isAfter } from "date-fns";
import Party from "src/app/core/models/party.model";
import { ChallanService } from "src/app/core/services/challan.service";
import { Challan, ChallanItem } from "src/app/core/models/challan.model";

interface Dropdown {
  name: string;
  code: string;
}

interface TaxComponent {
  tax_amount: number;
  tax_type: string;
  tax_rate: number;
}

export type FormControlType<T> = { [key in keyof T]: FormControl<T[key]> };

@Component({
  selector: "app-create-invoice",
  templateUrl: "./create-invoice.component.html",
  styleUrls: ["./create-invoice.component.scss"],
})
export class CreateInvoiceComponent {
  customers: Array<Party> = [];

  customersCopy: Array<Party> = [];

  selectedCustomer!: Party;

  products: Array<Inventory_Item> = [];

  productsCopy: Array<any> = [];

  selectedProduct: Inventory_Item | undefined;

  selectedProductQuantity: number = 0;

  companyId = "";

  total_taxable_amount = 0.0;

  total_tax_amount = 0.0;

  total_discount_amount = 0.0;

  total_amount = 0.0;

  raw_total_amount = 0.0;

  additional_discount_amount = 0.0;

  paymentStatus: boolean = false;

  discountMode: Array<Dropdown> = [
    {
      name: "₹",
      code: "amount",
    },
    {
      name: "%",
      code: "percent",
    },
  ];

  paymentMethodOptions: any[] = PAYMENT_METHOD_OPTIONS;

  readonly taxTypes = ["CGST", "IGST"];

  isCreatingCustomer = false;

  invoiceStatus = INVOICE_STATUS;

  isContentLoaded = false;

  createInvoiceForm = new FormGroup({
    selectedCustomer: new FormControl<Party | undefined>(undefined, [
      Validators.required,
    ]),
    invoice_date: new FormControl<Date | undefined>(new Date(), [
      Validators.required,
    ]),
    due_date: new FormControl<Date | undefined>(new Date(), [
      Validators.required,
    ]),
    invoiceItems: new FormArray<FormControl<InvoiceItem>>(
      [],
      [Validators.required]
    ),
    additional_discount_amount: new FormControl<number | undefined>(0),
    transaction: new FormGroup(
      {
        payment_method: new FormControl<number | undefined>(undefined),
        amount: new FormControl<number | undefined>(undefined),
        record_date: new FormControl<number | undefined>(undefined),
        receipt_number: new FormControl<string>(""),
        type: new FormControl(PAYMENT_TRANSACTION_TYPE.Payment),
      },
      {
        validators: [this.transactionValidator()],
      }
    ),
    should_round_off: new FormControl<boolean>(true, [Validators.required]),
  });

  isSubmitted = false;

  isChallanLinkVisible = false;

  challansToLink: Array<Challan> = [];

  linkedChallans: Array<Challan> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private us: UserService,
    private ps: ProductService,
    private utilities: UtilitiesService,
    private is: InvoiceService,
    private csps: CreationSlidepanelService,
    private cs: CustomerService,
    private ts: ToastService,
    private chs: ChallanService
  ) {
    this.createInvoiceForm.patchValue({
      due_date: new Date(new Date().setDate(new Date().getDate() + 7)),
    });
    this.companyId = this.utilities.getUserFromService().companyId;

    forkJoin({
      parties: this.us.getPartiesOfUser().pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
      inventory: this.ps.getProductsByCompany(this.companyId).pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
    }).subscribe((result: any) => {
      console.log("final result :: ", result);
      const { parties, inventory } = result;

      this.customers = parties ? parties.data : [];
      this.customersCopy = this.customers;

      this.products = inventory ? inventory.data : [];
      this.productsCopy = this.utilities.cloneDeep(this.products);

      this.isContentLoaded = true;
    });

    this.createInvoiceForm.controls.transaction.controls.payment_method.valueChanges.subscribe(
      () => {
        this.createInvoiceForm.controls.transaction.controls.amount.updateValueAndValidity();
      }
    );

    this.cs.getMessage().subscribe((customerId) => {
      if (customerId && customerId !== "") {
        this.us.getPartiesOfUser().subscribe({
          next: (response: any) => {
            this.customers =
              response.data && response.data.length ? response.data : [];

            this.selectedCustomer = this.customers.find(
              (customer) => customer.id === customerId
            )!;
            this.csps.closeSlidePanel();
          },
        });
      }
    });

    this.ps.getItemCreation().subscribe((itemId) => {
      if (itemId && itemId != "") {
        this.getProductsForCompany();
        this.csps.closeSlidePanel();
      }
    });
  }

  invoiceItemsList(): FormArray {
    return this.createInvoiceForm.get("invoiceItems") as FormArray;
  }

  partySelected(event: any) {
    if (!event.value) return;
    console.log("party is selected. get challans now... :: ", event);

    const selectedPartyId = event.value;
    this.chs.getChallansByCompany().subscribe({
      next: (response: any) => {
        console.log(response);

        if (!response.data.length) return;

        const allChallans: Array<Challan> = response.data;

        this.challansToLink = allChallans.filter(
          (challan) =>
            challan.party.id === selectedPartyId &&
            challan.type === CHALLAN_TYPE.Outward &&
            (!challan.linked_to || challan.linked_to?.sales_invoice_id === "")
        );

        console.log("challansToLink :: ", this.challansToLink);
      },
      error: (error: any) => {
        console.error("failed to get challans :: ", error);
      },
    });
  }

  backToInvoiceListing(invoiceId?: string) {
    this.router.navigate(["../sales"], {
      state: { invoiceId },
      relativeTo: this.route.parent,
    });
  }

  issueOnChanged(date: Date) {
    if (!date) return;

    if (
      isAfter(date, this.createInvoiceForm.controls.due_date.getRawValue()!)
    ) {
      this.createInvoiceForm.controls.due_date.setValue(date);
    }
  }

  getCustomersOfUser() {
    this.us.getPartiesOfUser().subscribe({
      next: (response: any) => {
        this.customers =
          response.data && response.data.length ? response.data : [];
      },
    });
  }

  getProductsForCompany() {
    const companyId = this.utilities.getUserFromService().companyId;

    this.ps.getProductsByCompany(companyId).subscribe({
      next: (response: any) => {
        this.products =
          response.data && response.data.length ? response.data : [];
        this.productsCopy = this.utilities.cloneDeep(this.products);
      },
      error: () => {
        this.products = [];
      },
    });
  }

  addInvoiceItem() {
    if (this.selectedProduct && this.selectedProductQuantity) {
      const invoiceItem = new InvoiceItem({});

      invoiceItem.product_service_id = this.selectedProduct.id!;
      invoiceItem.quantity = this.selectedProductQuantity;
      invoiceItem.title = this.selectedProduct.name;
      invoiceItem.price_w_tax = this.selectedProduct.selling_price;
      invoiceItem.unit_price = this.utilities.getPriceWithoutTax(
        this.selectedProduct.selling_price,
        this.selectedProduct.tax_rate
      );
      invoiceItem.tax_rate = this.selectedProduct.tax_rate;
      invoiceItem.unit = this.selectedProduct.unit
        ? this.selectedProduct.unit
        : "";

      // this.invoiceItems.push(invoiceItem);
      this.invoiceItemsList().push(new FormControl<InvoiceItem>(invoiceItem));

      const usedProduct = this.productsCopy.find(
        (element) => element.id === this.selectedProduct?.id
      );

      usedProduct.inactive = true;
    }
    this.selectedProduct = undefined;
    this.selectedProductQuantity = 0;
    this.calculateInvoiceTotal();
  }

  getInvoiceItemNetAmount(invoiceItem: InvoiceItem): number {
    // let unitPrice = invoiceItem.unit_price;

    // if (invoiceItem.discount_amount > 0 || invoiceItem.discount_percent > 0) {
    //   unitPrice = this.getUnitPriceAfterDiscount(invoiceItem);
    // }
    return Number(invoiceItem.quantity * invoiceItem.unit_price);
  }

  getInvoiceItemNetAmountAfterDiscount(invoiceItem: InvoiceItem): number {
    let unitPrice = invoiceItem.unit_price;

    if (invoiceItem.discount_amount > 0 || invoiceItem.discount_percent > 0) {
      unitPrice = this.getUnitPriceAfterDiscount(invoiceItem);
    }
    return Number(invoiceItem.quantity * unitPrice);
  }

  getInvoiceItemTax(
    productId: string,
    unit_price: number,
    quantity: number,
    invoiceItem: InvoiceItem
  ): number {
    // const invoice_item_product = this.products.find(
    //   (product) => product.id === productId
    // );

    // if (!invoice_item_product) return 0;

    // return Number((invoice_item_product.selling_price - unit_price) * quantity);
    const netAmount = this.getInvoiceItemNetAmountAfterDiscount(invoiceItem);
    const taxAmt = this.utilities.getTaxAmount(netAmount, invoiceItem.tax_rate);
    console.log("TAX AMT :: ", taxAmt, ", Net Amt :: ", netAmount);
    return taxAmt;
  }

  unitPriceChanged(updatedItem: InvoiceItem) {
    const item = this.invoiceItemsList().controls.find(
      (element) =>
        element.value.product_service_id === updatedItem.product_service_id
    );

    const product = this.products.find(
      (element) => element.id === updatedItem.product_service_id
    );

    if (item === undefined || product === undefined) {
      return;
    }

    item.value.price_w_tax = Number(
      updatedItem.unit_price +
        this.utilities.getTaxAmount(updatedItem.unit_price, product.tax_rate)
    );

    this.calculateInvoiceTotal();
  }

  discountChanged(invoiceItem: InvoiceItem, event: any) {
    const product = this.products.find(
      (item) => item.id === invoiceItem.product_service_id
    );

    if (product === undefined) {
      return;
    }

    switch (invoiceItem.discount_mode) {
      case "amount":
        // check for max discount. max discount cannot exceed invoice item total
        if (event.value > invoiceItem.price_w_tax * invoiceItem.quantity) {
          event.originalEvent.target.value =
            invoiceItem.unit_price * invoiceItem.quantity;
          event.value = invoiceItem.unit_price * invoiceItem.quantity;
          event.formattedValue = (
            invoiceItem.unit_price * invoiceItem.quantity
          ).toFixed(2);
        }

        invoiceItem.discount_amount = event.value;
        invoiceItem.discount_percent =
          (100 * event.value) /
          (invoiceItem.price_w_tax * invoiceItem.quantity);
        break;
      case "percent":
        if (event.value > 100) {
          event.originalEvent.target.value = 100;
          event.value = 100;
          event.formattedValue = "100.00";
        }

        invoiceItem.discount_percent = event.value;
        invoiceItem.discount_amount =
          (invoiceItem.price_w_tax * invoiceItem.quantity * event.value) / 100;
        break;
      default:
        break;
    }

    this.calculateInvoiceTotal();
  }

  getPriceWithTaxAfterDiscount(invoiceItem: InvoiceItem): number {
    const product = this.products.find(
      (item) => item.id === invoiceItem.product_service_id
    );

    if (!product) return 0.0;

    return Number(product.selling_price - invoiceItem.discount_amount);
  }

  getUnitPriceAfterDiscount(invoiceItem: InvoiceItem): number {
    const product = this.products.find(
      (item) => item.id === invoiceItem.product_service_id
    );

    if (!product) return 0.0;

    // const discount_wo_tax = product
    //   ? this.utilities.getPriceWithoutTax(
    //       invoiceItem.discount_amount,
    //       product?.tax_rate
    //     )
    //   : 0;

    // const originalUnitPrice = this.utilities.getPriceWithoutTax(
    //   product.selling_price,
    //   product.tax_rate
    // );

    const discountPerUnit = invoiceItem.discount_amount / invoiceItem.quantity;

    return Number(invoiceItem.unit_price - discountPerUnit);
  }

  calculateInvoiceTotal() {
    this.total_taxable_amount = 0.0;
    this.total_tax_amount = 0.0;
    this.total_discount_amount = 0.0;
    this.total_amount = 0.0;
    this.raw_total_amount = 0.0;

    this.invoiceItemsList().controls.forEach((item) => {
      this.total_taxable_amount += this.getInvoiceItemNetAmount(item.value);
      this.total_tax_amount += this.getInvoiceItemTax(
        item.value.product_service_id,
        item.value.unit_price,
        item.value.quantity,
        item.value
      );
      this.total_discount_amount += item.value.discount_amount;
      this.raw_total_amount += this.getInvoiceItemTotal(item.value);
    });

    if (
      this.createInvoiceForm.controls.additional_discount_amount?.getRawValue()
    ) {
      this.total_discount_amount +=
        this.createInvoiceForm.controls.additional_discount_amount?.getRawValue() ||
        0;

      this.raw_total_amount -=
        this.createInvoiceForm.controls.additional_discount_amount?.getRawValue() ||
        0;
      this.total_amount -=
        this.createInvoiceForm.controls.additional_discount_amount?.getRawValue() ||
        0;
    }

    if (this.createInvoiceForm.controls.should_round_off.value) {
      this.total_amount = Math.round(this.raw_total_amount);
    } else {
      this.total_amount = this.raw_total_amount;
    }
  }

  getInvoiceItemTotal(invoiceItem: InvoiceItem): number {
    const product = this.products.find(
      (item) => item.id === invoiceItem.product_service_id
    );

    if (!product) return 0.0;

    const unitPriceAfterDiscount = this.getUnitPriceAfterDiscount(invoiceItem);

    return Number(
      Number(
        unitPriceAfterDiscount +
          this.utilities.getTaxAmount(unitPriceAfterDiscount, product.tax_rate)
      ) * invoiceItem.quantity
    );
  }

  removeInvoiceItem(itemToRemove: InvoiceItem) {
    const index = this.invoiceItemsList().controls.findIndex(
      (item) =>
        item.value.product_service_id === itemToRemove.product_service_id
    );

    const usedProduct = this.productsCopy.find(
      (element) => element.id === itemToRemove.product_service_id
    );

    usedProduct.inactive = false;

    this.invoiceItemsList().controls.splice(index, 1);
    this.calculateInvoiceTotal();
  }

  createInvoice() {
    this.isSubmitted = true;
    // this.validateAllFormFields(this.createInvoiceForm);
    console.log(
      "this.createInvoiceForm.value :: ",
      this.createInvoiceForm.value
    );
    if (!this.createInvoiceForm.valid) {
      this.isSubmitted = false;
      return;
    }

    console.log("creating invoice ...");

    const companyId = this.utilities.getUserFromService().companyId;

    // Check if payment is being recorded while creating the invoice
    if (this.createInvoiceForm.controls.transaction.controls.amount !== null) {
      // this.payment.record_date = this.utilities.dateToEpoch(new Date());
      // this.payment.type = PAYMENT_TRANSACTION_TYPE.PAYMENT;

      this.createInvoiceForm.controls.transaction.patchValue({
        record_date: this.utilities.dateToEpoch(new Date()),
        type: PAYMENT_TRANSACTION_TYPE.Payment,
      });
    }

    const invoiceObj = this.createInvoiceForm.value;

    const newInvoice: Record<string, unknown> = {
      party_id: invoiceObj.selectedCustomer,
      due_date: this.utilities.dateToEpoch(invoiceObj.due_date),
      invoice_date: this.utilities.dateToEpoch(invoiceObj.invoice_date),
      linked_challans: this.linkedChallans.map((challan) => challan.id),
      invoice_type: "b2c",
      invoice_items: invoiceObj.invoiceItems,
      status: this.getPaymentStatus(),
      additional_discount_amount: invoiceObj.additional_discount_amount,
      total_discount_percentage:
        (100 * this.total_discount_amount) / this.total_amount,
      total_discount_amount: this.total_discount_amount,
      transaction:
        invoiceObj.transaction?.amount || invoiceObj.transaction?.payment_method
          ? invoiceObj.transaction
          : undefined,
    };

    this.is.createInvoice(companyId, newInvoice).subscribe({
      next: (response: any) => {
        const toastMessage: ToastMessage = {
          show: true,
          severity: "success",
          summary: "Success",
          detail: "Invoice created.",
        };
        this.ts.sendMessage(toastMessage);
        this.backToInvoiceListing(response.data?.id);
      },
    });
  }

  // discountModeChanged(invoiceItem: InvoiceItem) {
  //   switch (invoiceItem.discount_mode) {
  //     case "amount":
  //       invoiceItem.discount_amount =
  //         (invoiceItem.price_w_tax * invoiceItem.discount_value) / 100;
  //       invoiceItem.discount_value = invoiceItem.discount_amount;
  //       break;
  //     case "percent":
  //       invoiceItem.discount_percent =
  //         (100 * invoiceItem.discount_value) / invoiceItem.price_w_tax;
  //       invoiceItem.discount_value = invoiceItem.discount_percent;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  calculateTaxComponents(
    total_tax_amount: number,
    tax_rate: number
  ): Array<TaxComponent> {
    let taxComponents: Array<TaxComponent> = [];

    this.taxTypes.forEach((type) => {
      let taxComponent: TaxComponent = {
        tax_amount: Number((total_tax_amount / 2).toFixed(2)),
        tax_type: type,
        tax_rate: Number((tax_rate / 2).toFixed(2)),
      };

      taxComponents.push(taxComponent);
    });

    return taxComponents;
  }

  createCustomer() {
    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType: CREATION_SIDEPANEL_DATA_TYPE.customer,
    };

    this.csps.openSlidePanel(creationPanelData);
  }

  createInventoryItem() {
    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType: CREATION_SIDEPANEL_DATA_TYPE.inventory,
    };

    this.csps.openSlidePanel(creationPanelData);
  }

  getPaymentStatus(): number {
    let status = this.invoiceStatus.Pending;
    if (this.createInvoiceForm.controls.transaction?.controls.amount) {
      if (
        this.createInvoiceForm.controls.transaction?.controls.amount.getRawValue()! <
        this.total_amount
      ) {
        status = this.invoiceStatus["Partially Paid"];
      }

      if (
        this.createInvoiceForm.controls.transaction?.controls.amount.getRawValue()! ===
        this.total_amount
      ) {
        status = this.invoiceStatus.Paid;
      }
    }

    if (this.paymentStatus) {
      status = this.invoiceStatus.Paid;
    }

    return status;
  }

  markAsPaid(event: any) {
    console.log("markAsPaid :: ", event);
    if (event.checked[0]) {
      // this.payment.amount = this.total_amount;
      this.createInvoiceForm.controls.transaction.patchValue({
        amount: this.total_amount,
      });
    } else {
      this.paymentStatus = false;
    }
  }

  onRoundOffChange(event: any) {
    if (event.target.checked) {
      this.total_amount = Math.round(this.raw_total_amount);
    } else {
      this.total_amount = this.raw_total_amount;
    }
  }

  getRoundOffDifference() {
    return (
      Number(this.total_amount.toFixed(2)) -
      Number(this.raw_total_amount.toFixed(2))
    );
  }

  transactionValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      // const amountControl =
      //   formGroup.controls.amount;
      // const markedForFullyPaid = this.paymentStatus;
      // const paymentMethodControl =
      //   formGroup.controls.payment_method;

      if (!Object.keys(group).length) return null;

      const amountControl = group.get("amount");
      const markedForFullyPaid = this.paymentStatus;
      const paymentMethodControl = group.get("payment_method");

      if (paymentMethodControl?.value !== null && !amountControl?.value) {
        amountControl?.setValidators([Validators.required]);
        amountControl?.setErrors({ required: true });
      }

      if (
        (markedForFullyPaid || amountControl?.value) &&
        paymentMethodControl?.value === null
      ) {
        paymentMethodControl?.setValidators([Validators.required]);
        paymentMethodControl?.setErrors({ required: true });
      }

      return !(
        (paymentMethodControl?.value === null &&
          !markedForFullyPaid &&
          amountControl?.value === null) ||
        (paymentMethodControl?.value !== null &&
          amountControl?.value !== null &&
          !markedForFullyPaid)
      )
        ? { allRequiredFields: true }
        : null;
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  resetPayment() {
    this.createInvoiceForm.controls.transaction.reset();
    this.createInvoiceForm.controls.transaction.controls.amount.removeValidators(
      [Validators.required]
    );
    this.createInvoiceForm.controls.transaction.controls.amount.setErrors(null);
    this.createInvoiceForm.controls.transaction.controls.payment_method.removeValidators(
      [Validators.required]
    );
    this.createInvoiceForm.controls.transaction.controls.payment_method.setErrors(
      null
    );
  }

  filterParties(event: any) {
    if (event.filter === "" || event.filter === null) {
      this.customers = this.customersCopy;
      return;
    }

    event.filter = event.filter.toLowerCase();

    console.log("filtering by :: ", event.filter);

    this.customers = this.customersCopy.filter((customer) =>
      // customer.contact_details.mobile_number.value.includes(event.filter) ||
      // customer.first_name.toLowerCase().includes(event.filter) ||
      customer.last_name.toLowerCase().includes(event.filter)
    );
    console.log("filtered customers :: ", this.customers);
  }

  filterP(item: any) {
    console.log("inside filterP :: ", item);
  }

  isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
    return k in x;
  }

  openLinkChallanDialog() {
    this.isChallanLinkVisible = true;
  }

  closeLinkChallanDialog() {
    this.isChallanLinkVisible = false;
  }

  linkChallans() {
    if (!this.linkedChallans.length) return;

    // gather all inventory items in challans
    let itemsInChallans: Array<ChallanItem> = [];
    this.linkedChallans.forEach((challan: Challan) => {
      itemsInChallans = itemsInChallans.concat(challan.challan_items);
    });

    // filter only the unique items that are to be added to the invoice
    let uniqueItemsInChallans = Array.from(
      new Map(
        itemsInChallans.map((item) => [item.product_service_id, item])
      ).values()
    );

    const currentInvoiceItems = this.utilities.cloneDeep(
      this.createInvoiceForm.get("invoiceItems")?.getRawValue()
    );
    currentInvoiceItems.forEach((invoiceItemControl: any) => {
      this.removeInvoiceItem(invoiceItemControl as InvoiceItem);
    });
    uniqueItemsInChallans.forEach((item: ChallanItem) => {
      this.selectedProduct = this.products.find(
        (inventory_item) => inventory_item.id === item.product_service_id
      );
      this.selectedProductQuantity = item.quantity;

      this.addInvoiceItem();
    });

    this.closeLinkChallanDialog();
  }
}
