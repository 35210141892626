import { createSelector } from "@ngrx/store";
import User from "../models/user.model";
import * as UserActions from "../store_actions/user.actions";

export interface UserState {
  user: User;
}

const initialUserState: UserState = {
  user: new User({}),
};

export function userReducer(
  userState = initialUserState,
  action: any
): UserState {
  switch (action.type) {
    case UserActions.setUser.type:
      return {
        ...userState,
        user: new User({
          id: action.id && action.id !== "" ? action.id : userState.user.id,
          first_name:
            action.first_name && action.first_name !== ""
              ? action.first_name
              : userState.user.first_name,
          last_name:
            action.last_name && action.last_name !== ""
              ? action.last_name
              : userState.user.last_name,
          contact_details: {
            email:
              action.contact_details?.email &&
              action.contact_details?.email !== ""
                ? action.contact_details?.email
                : userState.user.contact_details?.email,
            mobile_number:
              action.contact_details.mobile_number &&
              action.contact_details.mobile_number !== ""
                ? action.contact_details.mobile_number
                : userState.user.contact_details.mobile_number,
          },
          role:
            action.role && action.role !== ""
              ? action.role
              : userState.user.role,
          companyId:
            action.companyId && action.companyId !== ""
              ? action.companyId
              : userState.user.companyId,
          companyName:
            action.companyName && action.companyName !== ""
              ? action.companyName
              : userState.user.companyName,
          isLoggedIn: action.isLoggedIn
            ? action.isLoggedIn
            : userState.user.isLoggedIn,
        }),
      };
    case UserActions.resetUser.type:
      return Object.assign({}, ...[initialUserState]);
    case UserActions.logoutUser.type:
      return Object.assign({}, ...[initialUserState]);
    default:
      break;
  }
  return userState;
}

export const selectUserState = (state: any) => state?.userState;

export const selectUser = createSelector(
  selectUserState,
  (state) => state?.user
);
