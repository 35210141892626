import { Component, OnInit, OnDestroy } from "@angular/core";
import { LoginStatusService } from "src/app/core/services/login-status.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as UserActions from "../../core/store_actions/user.actions";
import * as CompanyActions from "../../core/store_actions/company.actions";
import User from "src/app/core/models/user.model";
import { AuthService } from "src/app/core/services/auth.service";
import { UserService } from "src/app/core/services/user.service";
import { Subscription } from "rxjs";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { WindowService } from "src/app/core/services/window.service";
import { COUNTRIES, T_COUNTRY } from "src/app/core/constants/constants";
// import { PhoneNumberService } from "src/app/core/services/phone-number.service";
import { Phonenumberutility } from "src/app/core/utils/phone-number.util";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  readonly countries = COUNTRIES;

  userData: User = new User({});

  mobileNumber!: string;

  selectedCountry: T_COUNTRY = {} as T_COUNTRY;

  dial_code: string = "91";

  mobileNumberRegex = new RegExp("^[6-9]\\d{9}$", "g");

  MOBILE_NUMBER_MSG = "We will be sending an OTP to this number.";

  otp: string = "";

  OTP_MSG = "";

  otpLength = 6;

  resendOtpCounter = 60;

  OTP_RESENT_MSG = "OTP resent successfully!";

  readonly OTP_ID_PREFIX = "otp";

  isMobileNumberValid = false;

  isOtpSent = false;

  isOtpTriggered = false;

  isInvalidOtp = false;

  isValidatingOtp = false;

  getUserSubcription!: Subscription;

  getCompaniesForUserSubscription!: Subscription;

  windowRef!: any;

  maxMobileNumberLength!: number | null;

  constructor(
    private lss: LoginStatusService,
    private as: AuthService,
    private router: Router,
    private store: Store,
    private us: UserService,
    private utilities: UtilitiesService,
    private ws: WindowService,
    private pnu: Phonenumberutility
  ) {}

  ngOnInit(): void {
    this.selectedCountry = {
      code: "IN",
      dial_code: "91",
      flag: "🇮🇳",
      mobile_number_length: 10,
      name: "India",
    };
    const localState = localStorage.getItem("state");

    if (this.as.auth.currentUser && localState) {
      const user = this.as.auth.currentUser;
      this.userData.id = user.uid;
      this.utilities.setUserInService(this.userData);
      this.store.dispatch(UserActions.setUser(this.userData));
      this.performLogin(user.uid);
    }

    this.maxMobileNumberLength = this.pnu.getExpectedMobileNumberLength(
      this.selectedCountry.dial_code
    );
  }

  ngOnDestroy(): void {
    this.getUserSubcription?.unsubscribe();
    this.getCompaniesForUserSubscription?.unsubscribe();
  }

  onMobileNumberBlur() {
    // handle any onBlur actions
  }

  onMobileNumberChange() {
    // this.isMobileNumberValid = this.mobileNumberRegex.test(this.mobileNumber);
    this.isMobileNumberValid = this.pnu.isValidMobileNumber(
      this.selectedCountry.dial_code,
      this.mobileNumber
    );
  }

  onOtpChange(event: any, prev: any, curr: any, next: any) {
    if (event.key === "Backspace") {
      const prevOtpElem = document.getElementById(
        this.OTP_ID_PREFIX.concat(prev.toString())
      ) as HTMLInputElement;
      this.otp =
        this.otp.substring(0, curr) +
        this.otp.substring(curr + 1, this.otp.length);
      setTimeout(() => {
        prevOtpElem?.focus();
        prevOtpElem?.select();
      }, 0);
      return;
    }

    if (event.target.value.length) {
      const nextOtpElem = document.getElementById(
        this.OTP_ID_PREFIX.concat(next.toString())
      ) as HTMLInputElement;
      setTimeout(() => {
        nextOtpElem?.focus();
        nextOtpElem?.select();
      }, 0);
    }

    if (this.otp.length > curr) {
      let otpArray = this.otp.split("");
      otpArray.splice(curr, 1, event.target.value);
      this.otp = otpArray.join("");
    } else {
      this.otp = this.otp.concat(event.target.value);
    }
  }

  onOtpFocus(event: any, curr: number) {
    const focusedOtpElem = document.getElementById(
      this.OTP_ID_PREFIX.concat(curr.toString())
    ) as HTMLInputElement;
    setTimeout(() => {
      focusedOtpElem?.select();
    }, 0);
  }

  onOtpBlur(event: any) {}

  sendOtp(isResend?: boolean) {
    this.windowRef = this.ws;
    this.isOtpTriggered = true;
    this.as.initializeAuth().subscribe((isInitialised: boolean) => {
      if (isInitialised) {
        if (this.isMobileNumberValid) {
          const mobileNumberWithCountry = "+"
            .concat(this.selectedCountry.dial_code)
            .concat(this.mobileNumber);

          this.as
            .signInWithPhone(mobileNumberWithCountry)
            .then((confirmationResult: any) => {
              this.windowRef.confirmationResult = confirmationResult;

              this.userData = new User({
                ...this.userData,
                contact_details: {
                  mobile_number: {
                    dial_code: this.selectedCountry.dial_code,
                    value: this.mobileNumber,
                  },
                },
              });

              this.utilities.setUserInService(this.userData);
              this.store.dispatch(UserActions.setUser(this.userData));

              this.isOtpSent = true;
              this.OTP_MSG = `OTP sent to ${mobileNumberWithCountry}.`;

              this.isOtpTriggered = false;

              setTimeout(() => {
                const firstOtpElem = document.getElementById(
                  this.OTP_ID_PREFIX.concat("0")
                ) as HTMLInputElement;
                firstOtpElem?.focus();
              }, 250);
              this.otpCountdown();
            })
            .catch((error) => {
              console.log("SMS could not be sent due to :: ", error);
            });
        }
      }
    });
  }

  otpCountdown() {
    setInterval(() => {
      this.resendOtpCounter -= 1;
    }, 1000);
  }

  validateOtp() {
    if (this.otp.length === 6) {
      this.isValidatingOtp = true;
      this.isInvalidOtp = false;
      this.windowRef.confirmationResult
        .confirm(this.otp)
        .then((verificationResult: any) => {
          console.log("successfully verified");
          const user = verificationResult.user;
          console.log("logged in user is :: ", user);

          this.as.auth.currentUser?.getIdToken(true).then((jwt: string) => {
            console.log("getIdToken :::::: ", jwt);
            sessionStorage.setItem("ast-usr-tk", jwt);

            this.userData.id = user.uid;
            this.utilities.setUserInService(this.userData);
            this.store.dispatch(UserActions.setUser(this.userData));

            this.performLogin(user.uid);
          });
        })
        .catch((error: any) => {
          console.log("OTP verification failed due to :: ", error);
          this.isInvalidOtp = true;
          this.isValidatingOtp = false;
        });
    }
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  performLogin(userId: string) {
    this.getUserSubcription = this.us.getUser().subscribe({
      next: (response: any) => {
        console.log("getUser response :: ", response);

        if (
          !response.data?.id ||
          response.data?.id === "" ||
          response.data?.id === null
        ) {
          // user doesn't exist
          console.log("new user detected. route to company signup page...");
          this.performSignup();
        } else {
          this.userData.first_name = response.data.first_name;
          this.userData.last_name = response.data.last_name;
          this.userData.contact_details = response.data.contact_details;
          this.utilities.setUserInService(this.userData);
          this.store.dispatch(UserActions.setUser(this.userData));

          this.us.getCompaniesForUser().subscribe({
            next: (companiesResponse: any) => {
              console.log(
                "getCompaniesForUser response :: ",
                companiesResponse
              );

              if (!companiesResponse.data || !companiesResponse.data?.length) {
                console.log(
                  "user does not have an registered company. route to company signup page..."
                );
                this.performSignup();
              } else {
                const defaultCompany = companiesResponse.data.find(
                  (company: any) => company?.isDefault === true
                );

                const userData: User = {
                  id: response.data.id,
                  first_name: response.data.first_name,
                  last_name: response.data.last_name,
                  isLoggedIn: true,
                  companyId: defaultCompany.id,
                  companyName: defaultCompany.company_name,
                  contact_details: {
                    mobile_number: {
                      dial_code:
                        response.data.contact_details.mobile_number.dial_code,
                      value: response.data.contact_details.mobile_number.value,
                    },
                  },
                  role: "admin",
                };

                this.store.dispatch(UserActions.setUser(userData));
                this.utilities.setUserInService(userData);
                this.utilities.setCompaniesInService(companiesResponse.data);
                this.utilities.setCurrentCompany(defaultCompany);
                this.store.dispatch(
                  CompanyActions.setCompanies({
                    companies: companiesResponse.data,
                    currentCompany: defaultCompany,
                  })
                );

                this.lss.sendMessage(true);
                this.router.navigate(["sales"]);
              }
            },
            error: (error) => {
              this.performSignup();
            },
          });
        }
      },
      error: (error: any) => {
        if (error.status === 404) {
          // this.userData.isLoggedIn = true;
          this.utilities.setUserInService(this.userData);
          this.store.dispatch(UserActions.setUser(this.userData));
          this.performSignup();
        }
      },
    });
  }

  performSignup() {
    this.router.navigate(["signup"]);
  }

  countryChanged(event: any) {
    this.mobileNumber = "";
    this.maxMobileNumberLength = this.pnu.getExpectedMobileNumberLength(
      this.selectedCountry.dial_code
    );
  }

  editMobileNumber() {
    this.as.resetCaptcha().subscribe((isReset) => {
      if (isReset) {
        this.isOtpSent = !this.isOtpSent;
      }
    });
  }
}
