import { Component, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  PaymentTransaction,
  PAYMENT_TRANSACTION_TYPE,
  PAYMENT_METHOD_OPTIONS,
} from "src/app/core/models/payment-transaction";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { EventEmitter } from "@angular/core";
import { InvoiceService } from "src/app/core/services/invoice.service";

@Component({
  selector: "app-record-payment",
  templateUrl: "./record-payment.component.html",
  styleUrls: ["./record-payment.component.scss"],
})
export class RecordPaymentComponent {
  @Input() invoice: any;

  paymentRecord: PaymentTransaction = {} as PaymentTransaction;

  newPaymentRecord = new FormGroup({
    amount: new FormControl(0, [
      Validators.required,
      this.utilities.currencyValidator,
    ]),
    payment_method: new FormControl("", Validators.required),
    receipt_number: new FormControl(""),
    record_date: new FormControl(new Date(), Validators.required),
    type: new FormControl(
      PAYMENT_TRANSACTION_TYPE.Payment,
      Validators.required
    ),
    remarks: new FormControl(""),
  });

  readonly paymentMethodOptions: any[] = PAYMENT_METHOD_OPTIONS;

  recordingPayment = false;

  @Output() paymentRecordResult: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private utilities: UtilitiesService,
    private is: InvoiceService
  ) {}

  dateChanged(selectedDate: Date) {
    const now = new Date();
    console.log("selectedDate :: ", selectedDate);
    selectedDate.setHours(now.getHours());
    selectedDate.setMinutes(now.getMinutes());
    selectedDate.setSeconds(now.getSeconds());
    this.newPaymentRecord.patchValue({
      record_date: selectedDate,
    });
  }

  recordPayment(event: any) {
    if (!this.newPaymentRecord.valid || this.recordingPayment) return;
    console.log(this.newPaymentRecord.value);

    const paymentPayload: PaymentTransaction = {
      amount: this.newPaymentRecord.value.amount!,
      payment_method: this.newPaymentRecord.value.payment_method!,
      record_date: this.utilities.dateToEpoch(
        this.newPaymentRecord.value.record_date!
      ),
      type: this.newPaymentRecord.value.type!,
      receipt_number: this.newPaymentRecord.value.receipt_number!,
      remarks: this.newPaymentRecord.value.remarks!,
    };

    this.recordingPayment = true;

    this.is
      .recordPayment(
        paymentPayload,
        this.invoice.id,
        this.utilities.getUserFromService().companyId
      )
      .subscribe({
        next: (response: any) => {
          console.log("payment recorded");
          this.paymentRecordResult.emit(true);
          this.is.sendPaymentRecordMessage(true);
        },
        error: (error) => {
          this.recordingPayment = false;
        },
      });
  }
}
