<div class="section-header-wrapper pb-1 mb-3">
  <div class="flex justify-content-space-between align-items-center">
    <p class="heading">New Invoice</p>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-sm p-button-secondary p-button-text"
      (click)="backToInvoiceListing()"
    ></p-button>
  </div>
</div>
<div *ngIf="!isContentLoaded" class="flex flex-column gap-3">
  <div class="flex gap-3">
    <div class="flex-1">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          'height': '64px',
          'border-radius': '8px',
          'margin': '0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="flex-1">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          'height': '64px',
          'border-radius': '8px',
          'margin': '0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{ 'height': '256px', 'border-radius': '8px', 'margin': '0' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{ 'height': '256px', 'border-radius': '8px', 'margin': '0' }"
  ></ngx-skeleton-loader>
</div>
<ng-container *ngIf="isContentLoaded">
  <form [formGroup]="createInvoiceForm" (ngSubmit)="createInvoice()">
    <div id="new-invoice-wrapper" class="flex flex-column gap-4">
      <section>
        <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <label for="productName">Select a Customer</label>
                <p-button
                  icon="pi pi-plus-circle"
                  label="Add new customer"
                  styleClass="p-button-text p-1 extra-small"
                  (onClick)="createCustomer()"
                ></p-button>
              </div>
              {{ customers.length }} - {{ customersCopy.length }}
              <p-dropdown
                [options]="customers"
                optionValue="id"
                optionLabel="first_name"
                [filter]="true"
                (onFilter)="filterParties($event)"
                (onChange)="partySelected($event)"
                [showClear]="true"
                placeholder="Search existing customers"
                formControlName="selectedCustomer"
              >
                <ng-template let-party pTemplate="item">
                  <div class="flex flex-column justify-content-start gap-0">
                    <div>{{ party.first_name }} {{ party.last_name }}</div>
                    <small>{{
                      party.contact_details.mobile_number.value
                    }}</small>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <app-field-error
              [isFormSubmitted]="isSubmitted"
              [fieldControl]="createInvoiceForm.controls.selectedCustomer"
            >
            </app-field-error>
          </div>
          <div class="flex flex-1 gap-3">
            <div class="input-group flex-1">
              <label for="unit">Issued On</label>
              <p-calendar
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                formControlName="invoice_date"
                (onSelect)="issueOnChanged($event)"
              ></p-calendar>
              <app-field-error
                [isFormSubmitted]="isSubmitted"
                [fieldControl]="createInvoiceForm.controls.invoice_date"
              >
              </app-field-error>
            </div>
            <div class="input-group flex-1">
              <label for="unit">Due On</label>
              <p-calendar
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
                formControlName="due_date"
                [minDate]="
                  createInvoiceForm.controls.invoice_date.getRawValue()!
                "
              ></p-calendar>
              <app-field-error
                [isFormSubmitted]="isSubmitted"
                [fieldControl]="createInvoiceForm.controls.due_date"
              >
              </app-field-error>
            </div>
          </div>
        </div>
      </section>
      <hr class="section-divider" />
      <section>
        <p class="title">Invoice Items</p>
        <div class="banner">
          <div class="flex gap-1 align-items-center mb-2 banner-header">
            <app-icon icon="link"></app-icon>
            <p class="title title-sm">Link challans</p>
          </div>
          <div class="banner-content">
            <div class="flex align-items-center">
              <div class="flex-1">
                <span
                  >You can automatically add invoice items by linking a
                  challan.</span
                >
              </div>
              <p-button
                label="Link now"
                styleClass="p-button-sm"
                appendTo="body"
                modal="true"
                (onClick)="openLinkChallanDialog()"
                [disabled]="challansToLink.length ? false : true"
              ></p-button>
            </div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <label for="productName">Select a product</label>
                <p-button
                  icon="pi pi-plus-circle"
                  label="Add new item"
                  styleClass="p-button-text p-1 extra-small"
                  (onClick)="createInventoryItem()"
                ></p-button>
              </div>
              <!-- {{ selectedProduct | json }} -->
              <!-- <br /><br />
              {{ this.products | json }}
              <br /><br /> -->
              <p-dropdown
                [options]="productsCopy"
                optionLabel="name"
                optionDisabled="inactive"
                placeholder="Search existing products"
                [showClear]="true"
                [(ngModel)]="selectedProduct"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="linkedChallans.length ? true : false"
              >
                <ng-template let-product pTemplate="item">
                  <div class="flex flex-column align-items-start gap-min">
                    <div class="flex justify-content-space-between w-100">
                      <div>{{ product.name }}</div>
                      <div
                        class="meta-data flex flex-column align-items-end gap-0"
                      >
                        <span
                          >Quantity: {{ product.stock_quantity }}
                          {{ product.unit }}</span
                        >
                        <small
                          >Selling Price: &#x20B9;
                          {{ product.selling_price.toFixed(2) }}</small
                        >
                      </div>
                    </div>
                    <small
                      ><em *ngIf="product.inactive"
                        >Already added to invoice</em
                      ></small
                    >
                  </div>
                </ng-template>
              </p-dropdown>
              <small
                *ngIf="isSubmitted && !invoiceItemsList().controls.length"
                class="error-block flex align-items-center gap-min mt-1"
                style="color: var(--error-red)"
              >
                <span class="pi pi-exclamation-circle"></span>
                At least one item is required to create an invoice
              </small>
            </div>
          </div>
          <div class="flex flex-1 gap-3">
            <div class="input-group flex-1">
              <label for="unit">Quantity</label>
              <div class="p-inputgroup">
                <p-inputNumber
                  [useGrouping]="false"
                  [(ngModel)]="selectedProductQuantity"
                  [ngModelOptions]="{ standalone: true }"
                  mode="decimal"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  [disabled]="linkedChallans.length ? true : false"
                ></p-inputNumber>
                <span *ngIf="selectedProduct" class="p-inputgroup-addon">{{
                  selectedProduct.unit
                }}</span>
              </div>
            </div>
            <div class="input-group flex-1 align-items-end justify-content-end">
              <p-button
                icon="pi pi-plus"
                label="Add item to invoice"
                [disabled]="
                  !selectedProduct ||
                  selectedProduct.id === '' ||
                  selectedProductQuantity === 0 ||
                  selectedProductQuantity === null
                "
                (onClick)="addInvoiceItem()"
              ></p-button>
            </div>
          </div>
        </div>
        <div class="flex flex-column">
          <div formArrayName="invoiceItems">
            <p-table
              [value]="invoiceItemsList().controls"
              [tableStyle]="{ 'min-width': '100%' }"
              styleClass="p-datatable-sm"
              class="w-100"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [resizableColumns]="true"
              columnResizeMode="expand"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 25%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Item
                    </div>
                  </th>
                  <th style="width: 12%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Quantity
                    </div>
                  </th>
                  <th style="width: 12%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Unit Price
                    </div>
                  </th>
                  <th>
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Net Amount
                    </div>
                  </th>
                  <th style="width: 15%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Discount on Net Amount
                    </div>
                  </th>
                  <th>
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Tax Amount
                    </div>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-invoiceItem>
                <tr>
                  <td>
                    <span class="p-column-title">Name</span>
                    <span class="cell-content">{{
                      invoiceItem.value.title
                    }}</span>
                  </td>
                  <td>
                    <span class="p-column-title">Quantity</span>
                    <p-inputNumber
                      [(ngModel)]="invoiceItem.value.quantity"
                      [ngModelOptions]="{ standalone: true }"
                      [useGrouping]="false"
                      mode="decimal"
                      [minFractionDigits]="0"
                      [maxFractionDigits]="2"
                      (onInput)="calculateInvoiceTotal()"
                      [disabled]="linkedChallans.length ? true : false"
                    >
                    </p-inputNumber>
                  </td>
                  <td>
                    <span class="p-column-title">Unit Price</span>
                    <div class="flex flex-column">
                      <p-inputNumber
                        [(ngModel)]="invoiceItem.value.unit_price"
                        [ngModelOptions]="{ standalone: true }"
                        [useGrouping]="false"
                        mode="currency"
                        currency="INR"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        (onInput)="unitPriceChanged(invoiceItem.value)"
                      ></p-inputNumber>
                      <small
                        *ngIf="
                          invoiceItem.value.discount_amount > 0 ||
                          invoiceItem.value.discount_percent > 0
                        "
                        ><em
                          >after discount
                          {{
                            getUnitPriceAfterDiscount(invoiceItem.value)
                              | roundOffCurrency
                          }}</em
                        ></small
                      >
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Net Amount</span>
                    <div class="flex flex-column">
                      <span class="cell-content">
                        {{
                          getInvoiceItemNetAmount(invoiceItem.value)
                            | roundOffCurrency
                        }}
                      </span>
                      <small
                        *ngIf="
                          invoiceItem.value.discount_amount > 0 ||
                          invoiceItem.value.discount_percent > 0
                        "
                        ><em
                          >after discount
                          {{
                            getInvoiceItemNetAmountAfterDiscount(
                              invoiceItem.value
                            ) | roundOffCurrency
                          }}</em
                        ></small
                      >
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Discount</span>
                    <div class="p-inputgroup">
                      <p-inputNumber
                        *ngIf="invoiceItem.value.discount_mode === 'amount'"
                        [useGrouping]="false"
                        mode="decimal"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [max]="
                          invoiceItem.value.price_w_tax *
                          invoiceItem.value.quantity
                        "
                        (onInput)="discountChanged(invoiceItem.value, $event)"
                        [(ngModel)]="invoiceItem.value.discount_amount"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </p-inputNumber>
                      <p-inputNumber
                        *ngIf="invoiceItem.value.discount_mode === 'percent'"
                        [useGrouping]="false"
                        mode="decimal"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [max]="
                          invoiceItem.value.price_w_tax *
                          invoiceItem.value.quantity
                        "
                        (onInput)="discountChanged(invoiceItem.value, $event)"
                        [(ngModel)]="invoiceItem.value.discount_percent"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </p-inputNumber>
                      <span class="p-inputgroup-addon">
                        <p-dropdown
                          [options]="discountMode"
                          appendTo="body"
                          optionLabel="name"
                          optionValue="code"
                          styleClass="dropdown-addon"
                          [(ngModel)]="invoiceItem.value.discount_mode"
                          [ngModelOptions]="{ standalone: true }"
                        ></p-dropdown>
                      </span>
                    </div>
                  </td>

                  <td>
                    <span class="p-column-title">Tax Amount</span>
                    <div class="flex flex-column">
                      <span class="cell-content">{{
                        getInvoiceItemTax(
                          invoiceItem.value.product_service_id,
                          invoiceItem.value.unit_price,
                          invoiceItem.value.quantity,
                          invoiceItem.value
                        ) | roundOffCurrency
                      }}</span>
                    </div>
                  </td>

                  <td>
                    <span class="cell-content">
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-sm p-button-danger p-button-text"
                        (onClick)="removeInvoiceItem(invoiceItem.value)"
                      ></p-button>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div id="invoice-total-wrapper" class="p-3 flex flex-column">
            <div class="flex flex-column gap-1">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <label for="unit" class="text-align-left"
                  >Additional Discount</label
                >
                <p-inputNumber
                  [useGrouping]="false"
                  mode="currency"
                  currency="INR"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                  formControlName="additional_discount_amount"
                  inputId="additional_discount_amount"
                  (onInput)="calculateInvoiceTotal()"
                ></p-inputNumber>
              </div>
            </div>
            <p-divider></p-divider>
            <div class="flex flex-column gap-1">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <span>Total Taxable Amount:</span>
                <span
                  >&#x20B9; {{ total_taxable_amount | roundOffCurrency }}</span
                >
              </div>
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <span>Total Tax Amount:</span>
                <span>&#x20B9; {{ total_tax_amount | roundOffCurrency }}</span>
              </div>
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <span>Total Discount:</span>
                <span
                  >&#x20B9; {{ total_discount_amount | roundOffCurrency }}</span
                >
              </div>
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <div class="flex gap-2 align-items-center">
                  <span>Round Off</span>
                  <label class="switch">
                    <input
                      type="checkbox"
                      formControlName="should_round_off"
                      (click)="onRoundOffChange($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <span
                  >&#x20B9;
                  {{
                    createInvoiceForm.controls.should_round_off.getRawValue()
                      ? (getRoundOffDifference() | roundOffCurrency)
                      : (0 | roundOffCurrency)
                  }}</span
                >
              </div>
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <h5>Total Amount:</h5>
                <h5>&#x20B9; {{ total_amount | roundOffCurrency }}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr class="section-divider" />
      <section>
        <div class="flex gap-3">
          <div class="flex-1 p-3" id="notes-wrapper">
            <section class="flex flex-column gap-2">
              <p class="title">Notes</p>

              <div class="input-group">
                <label for="notes">invoice related notes</label>
                <textarea
                  id="notes"
                  rows="5"
                  cols="30"
                  pInputTextarea
                ></textarea>
              </div>
            </section>
          </div>
          <div class="flex-1 p-3" id="payment-wrapper">
            <section class="flex flex-column gap-2" formGroupName="transaction">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <p class="title">Payment</p>
                <p class="link" (click)="resetPayment()">Reset Payment</p>
              </div>
              <small
                *ngIf="isSubmitted && createInvoiceForm.controls.transaction?.errors?.['allRequiredFields']"
                class="error-block flex align-items-center gap-min mt-1"
                style="color: var(--error-red)"
              >
                <span class="pi pi-exclamation-circle"></span>
                If you wish to record a payment, please fill in all the required
                fields
              </small>
              <div class="input-group">
                <label for="paymentMethodOptions">Choose payment method</label>
                <p-selectButton
                  id="paymentMethodOptions"
                  [options]="paymentMethodOptions"
                  optionLabel="label"
                  optionValue="value"
                  formControlName="payment_method"
                ></p-selectButton>
              </div>
              <div class="flex justify-content-end">
                <p-checkbox
                  id="fullyPaid"
                  name="groupname"
                  value="true"
                  label="Mark as fully paid"
                  [(ngModel)]="paymentStatus"
                  (onChange)="markAsPaid($event)"
                ></p-checkbox>
              </div>
              <div class="flex gap-3">
                <div class="input-group flex-1">
                  <label for="productType">Amount</label>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">&#x20B9;</span>
                    <p-inputNumber
                      inputId="amount"
                      mode="decimal"
                      [minFractionDigits]="2"
                      [maxFractionDigits]="2"
                      formControlName="amount"
                      [disabled]="paymentStatus"
                    >
                    </p-inputNumber>
                  </div>
                  <app-field-error
                    [isFormSubmitted]="isSubmitted"
                    [fieldControl]="
                      createInvoiceForm.controls.transaction.controls.amount
                    "
                  >
                  </app-field-error>
                </div>
                <div class="input-group flex-1">
                  <div class="flex gap-1">
                    <label for="receipt_number">receipt number</label>
                    <span class="optional">(optional)</span>
                  </div>
                  <input
                    id="receipt_number"
                    type="text"
                    pInputText
                    formControlName="receipt_number"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
    <div class="sticky pblk-1">
      <div id="action-buttons-wrapper" class="flex gap-3 pt-2">
        <p-button
          label="Discard"
          styleClass="p-button-secondary"
          (click)="backToInvoiceListing()"
        ></p-button>
        <p-button
          label="Create Invoice"
          type="submit"
          [loading]="isSubmitted"
        ></p-button>
      </div>
    </div>
  </form>
</ng-container>
<p-dialog
  header="Challans avaialble to link"
  [(visible)]="isChallanLinkVisible"
  [modal]="true"
  [style]="{ 'width': '50vw', 'min-height': '200px' }"
  appendTo="body"
>
  <!-- Challans available to link : {{ challansToLink.length }} -->
  <div class="grid link-challans-wrapper gap-3">
    <ng-container *ngFor="let challan of challansToLink">
      <label [for]="challan.id">
        <div
          class="card flex gap-2 align-items-start justify-content-space-between"
        >
          <div class="grid gap-1">
            <span
              ><b>{{ challan.challan_number }}</b></span
            >
            <span
              >{{ challan.party.first_name }}
              {{ challan.party.last_name }}</span
            >
            <span>Items in challan: {{ challan.challan_items.length }}</span>
          </div>
          <p-checkbox
            name="linked-challans"
            [value]="challan"
            [(ngModel)]="linkedChallans"
            [inputId]="challan.id!"
          ></p-checkbox>
        </div>
      </label>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <p-button
      label="Link now"
      styleClass="p-button-sm"
      (onClick)="linkChallans()"
    ></p-button>
  </ng-template>
</p-dialog>
