export const environment = {
  appVersion: require("../../../package.json").version + "-dev",
  defaultLanguage: "en",
  name: "DEV",
  isProduction: false,
  isUat: false,
  isNotLocal: true,
  apiUrl: "https://bilvoic-jd7sprlirq-el.a.run.app",
  firebase: {
    apiKey: "AIzaSyArLX9ICfunKHA065wDEAgyQIqvROPkLfw", // AIzaSyCdenWaJE0kUf4jedr6wt6QX8Ol2YEFnSc
    authDomain: "bilvoic-dev.firebaseapp.com",
    projectId: "bilvoic-dev",
    storageBucket: "bilvoic-dev.appspot.com",
    messagingSenderId: "283896244365",
    appId: "1:283896244365:web:39cf959eeccbddf5f3dac4",
    measurementId: "G-BF1Q9VDWNT",
  },
  recaptchaId: "6LfxiKwpAAAAAKjoE5bnSxiSM_xcYcZBXMXryKvO", // "6LdyhKwpAAAAAGxTIGgogG9F9WSIfb7g-09ZO32V",
};
