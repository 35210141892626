<form
  [formGroup]="newPaymentRecord"
  (ngSubmit)="recordPayment($event)"
  id="newPaymentForm"
  class="flex flex-column justify-content-space-between"
>
  <section class="flex flex-column gap-3">
    <div class="flex">
      <div class="input-group flex-1">
        <label for="amount_paid">Amount Paid</label>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">&#x20B9;</span>
          <p-inputNumber
            inputId="paid_amount"
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            formControlName="amount"
          >
          </p-inputNumber>
        </div>
        <div class="flex gap-2">
          <small>Total Amount: &#x20B9; {{ invoice.total?.toFixed(2) }}</small>
          <small *ngIf="invoice.pending_amount"
            >Pending Amount: &#x20B9;
            {{ invoice.pending_amount?.toFixed(2) }}</small
          >
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="input-group flex-1">
        <label for="unit">Payment Date</label>
        <p-calendar
          [showIcon]="true"
          dateFormat="dd-mm-yy"
          formControlName="record_date"
          (onSelect)="dateChanged($event)"
        ></p-calendar>
      </div>
    </div>
    <div class="flex">
      <div class="input-group flex-1">
        <label for="paymentMethodOptions">Choose payment method</label>
        <p-selectButton
          id="paymentMethodOptions"
          [options]="paymentMethodOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="payment_method"
        ></p-selectButton>
      </div>
    </div>
    <div class="flex">
      <div class="input-group flex-1">
        <div class="flex gap-1">
          <label for="receipt_number" class="input-label">receipt number</label>
          <span class="optional">(optional)</span>
        </div>
        <input
          type="text"
          id="receipt_number"
          pInputText
          formControlName="receipt_number"
        />
      </div>
    </div>
    <div class="flex">
      <div class="input-group flex-1">
        <div class="flex gap-1">
          <label for="receipt_number" class="input-label">remarks</label>
          <span class="optional">(optional)</span>
        </div>
        <textarea
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="remarks"
        ></textarea>
      </div>
    </div>
  </section>
  <p-button
    label="Record Payment"
    styleClass="p-button-sm mt-3"
    type="submit"
    [loading]="recordingPayment"
  ></p-button>
</form>
