import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingspinnerService {
  private subject = new Subject<boolean | null>();

  constructor() {}

  sendMessage(isLoading: boolean) {
    this.subject.next(isLoading);
  }

  clearMessage() {
    this.subject.next(null);
  }

  getMessage() {
    return this.subject.asObservable();
  }
}
