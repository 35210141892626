import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { EnvironmentService } from "./environment.service";
import { endpoints } from "./endpoints.ts.service";
import { BehaviorSubject, Observable } from "rxjs";
import Party from "../models/party.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private subject = new BehaviorSubject<string>("");

  constructor(private httpClient: HttpClient) {}

  sendMessage(customerId: string) {
    this.subject.next(customerId);
  }

  getMessage(): Observable<string> {
    return this.subject.asObservable();
  }

  createCustomer(payload: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.CUSTOMERS}`,
      payload
    );
  }

  deleteParty(partyId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}${endpoints.PARTIES}/${partyId}`
    );
  }

  createParty(payload: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.PARTIES}`,
      payload
    );
  }

  updateParty(payload: Party, partyId: string) {
    return this.httpClient.patch(
      `${environment.apiUrl}${endpoints.PARTIES}/${partyId}`,
      payload
    );
  }
}
