<header class="flex align-items-center justify-content-space-between pinl-3">
  <div id="brand" class="flex align-items-center gap-1">
    <div class="logo">
      <img src="../../../assets/img/astral_logo.png" alt="ASTRAL BILLING" />
    </div>
    <small id="appVersion">{{ appVersion }}</small>
  </div>
  <div id="otherHeaderEssentials" class="flex align-items-center gap-2">
    <div id="company" class="flex gap-1">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="store"
      >
        <mask
          id="mask0_3233_1431"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3233_1431)">
          <path
            d="M5.0002 4H19.0002C19.2835 4 19.521 4.09583 19.7127 4.2875C19.9044 4.47917 20.0002 4.71667 20.0002 5C20.0002 5.28333 19.9044 5.52083 19.7127 5.7125C19.521 5.90417 19.2835 6 19.0002 6H5.0002C4.71686 6 4.47936 5.90417 4.2877 5.7125C4.09603 5.52083 4.0002 5.28333 4.0002 5C4.0002 4.71667 4.09603 4.47917 4.2877 4.2875C4.47936 4.09583 4.71686 4 5.0002 4ZM5.0002 20C4.71686 20 4.47936 19.9042 4.2877 19.7125C4.09603 19.5208 4.0002 19.2833 4.0002 19V14H3.8252C3.50853 14 3.2502 13.8792 3.0502 13.6375C2.8502 13.3958 2.78353 13.1167 2.8502 12.8L3.8502 7.8C3.9002 7.56667 4.01686 7.375 4.2002 7.225C4.38353 7.075 4.59186 7 4.8252 7H19.1752C19.4085 7 19.6169 7.075 19.8002 7.225C19.9835 7.375 20.1002 7.56667 20.1502 7.8L21.1502 12.8C21.2169 13.1167 21.1502 13.3958 20.9502 13.6375C20.7502 13.8792 20.4919 14 20.1752 14H20.0002V19C20.0002 19.2833 19.9044 19.5208 19.7127 19.7125C19.521 19.9042 19.2835 20 19.0002 20C18.7169 20 18.4794 19.9042 18.2877 19.7125C18.096 19.5208 18.0002 19.2833 18.0002 19V14H14.0002V19C14.0002 19.2833 13.9044 19.5208 13.7127 19.7125C13.521 19.9042 13.2835 20 13.0002 20H5.0002ZM6.0002 18H12.0002V14H6.0002V18ZM5.0502 12H18.9502L18.3502 9H5.6502L5.0502 12Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>

      <p-dropdown
        [options]="companies"
        optionLabel="company_name"
        styleClass="company_selector"
      ></p-dropdown>
    </div>
    <div id="user">
      <p-overlayPanel
        #op
        styleClass="user-overlay"
        [style]="{ width: '350px', overflow: 'hidden' }"
      >
        <ng-template pTemplate="content" class="userOverlay">
          <div id="overlayTop" class="flex flex-column gap-1">
            <div class="flex flex-column gap-1 pinl-2">
              <b
                ><span>Hi {{ user.first_name }} {{ user.last_name }}</span></b
              >
              <span>{{ user.contact_details.mobile_number.value }}</span>
              <span>{{ user.contact_details.email }}</span>
            </div>
            <hr class="pinl-2 mt-2" />
            <div class="flex flex-column">
              <p-button
                label="Settings"
                icon="pi pi-cog"
                styleClass="p-button-link grey-hover pblk-1 pinl-2 w-100"
                (click)="op.hide(); openSettings()"
              ></p-button>
              <p-button
                label="Logout"
                icon="pi pi-sign-out"
                styleClass="p-button-link grey-hover pblk-1 pinl-2 w-100"
                (click)="doLogout()"
              ></p-button>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
      <button
        pButton
        class="p-button-outlined p-button-rounded p-button-sm userProfile flex align-items-center justify-content-center p-0"
        (click)="op.toggle($event)"
        icon="pi pi-user"
      ></button>
    </div>
  </div>
</header>
