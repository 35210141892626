import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  items: MenuItem[];

  activeItem: MenuItem;

  constructor() {
    this.items = [
      { label: "User", icon: "pi pi-user", routerLink: "user" },
      { label: "Company", icon: "pi pi-building", routerLink: "company" },
      // { label: "Edit", icon: "pi pi-fw pi-pencil" },
      // { label: "Documentation", icon: "pi pi-fw pi-file" },
      // { label: "Settings", icon: "pi pi-fw pi-cog" },
    ];

    this.activeItem = this.items[0];
  }

  ngOnInit(): void {}

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }
}
