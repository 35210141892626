import { Component, Input, OnInit } from "@angular/core";

const allowedIcons = ["plus", "plus-circle"];

declare type ButtonIconPosition = "left" | "right" | "top" | "bottom";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() label = "";

  @Input() variant = "primary";

  @Input() size = "";

  @Input() icon = "";

  @Input() iconPosition!: ButtonIconPosition;

  @Input() disabled = false;

  styleClasses = ["button"];

  constructor() {}

  ngOnInit(): void {
    switch (this.size?.toLowerCase()) {
      case "small":
        this.styleClasses.push("p-button-sm");
        break;
      case "medium":
        this.styleClasses.push("p-button-md");
        break;
      case "large":
        this.styleClasses.push("p-button-lg");
        break;
      default:
        this.styleClasses.push("p-button-sm");
    }

    if (this.variant?.toLowerCase() === "secondary") {
      this.styleClasses.push("p-button-outlined");
    }

    if (this.iconPosition?.toLowerCase() === "right") {
      this.styleClasses.push("icon-right");
    }
  }

  isIconValid(icon: string): boolean {
    return allowedIcons.includes(icon);
  }

  getColor(colorName: string) {
    const PREFIX = "--";
    return window
      .getComputedStyle(document.body)
      .getPropertyValue(PREFIX.concat(colorName));
  }
}
