import { ActionReducer, INIT, UPDATE } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import * as _ from "lodash";

export const hydrationMetaReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const stateInStorage = _.cloneDeep(localStorage.getItem("state"));

      if (stateInStorage) {
        try {
          return JSON.parse(stateInStorage);
        } catch (error) {
          console.error("ERROR in hydrating state :: ", error);
        }
      } else {
        console.warn("State not found in storage. Perhaps not offloaded...");
      }
    }

    return reducer(state, action);
  };
};
