import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  AbstractControlDirective,
  FormControl,
  FormGroup,
} from "@angular/forms";

@Component({
  selector: "app-field-error",
  templateUrl: "./field-error.component.html",
  styleUrls: ["./field-error.component.scss"],
})
export class FieldErrorComponent {
  @Input() fieldControl!: FormControl;

  @Input() isFormSubmitted: boolean = false;

  errorMsgList: any = [];

  readonly errorMessage = {
    required: () => `This field is required`,
    invalid: (message: string) => message,
    maxlength: (params: any) =>
      `Maximum ${params.requiredLength} characters are allowed`,
    minlength: (params: any) =>
      `Minimum ${params.requiredLength} characters are required`,
    pattern: () => `Invalid format`,
    min: (params: any) => `Minimum amount should be ₹ ${params.min}`,
    whitespace: () => `White spaces are not allowed`,
    customError: () => `This is a custom error`,
  };

  listErrors() {
    if (!this.fieldControl) return [];
    if (this.fieldControl.errors) {
      this.errorMsgList = [];
      Object.keys(this.fieldControl.errors).map((error) => {
        this.isFormSubmitted ||
        this.fieldControl.touched ||
        this.fieldControl.dirty
          ? this.errorMsgList.push(
              this.errorMessage[error as keyof typeof this.errorMessage](
                this.fieldControl.errors![error]
              )
            )
          : "";
      });
      return this.errorMsgList;
    } else {
      return [];
    }
  }
}
