export class Address {
  id: string = "";

  type: number | null = null;

  city: string = "";

  is_default: boolean = false;

  landmark?: string = "";

  line_1: string = "";

  line_2?: string = "";

  nick_name?: string = "";

  postal_code: string = "";

  state: string = "";

  state_code: string = "";

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export type T_STATE = {
  name: string;
  code: string;
  gstCode: string;
};

export enum ADDRESS_KIND {
  "Billing",
  "Shipping",
}
