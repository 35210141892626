import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  FormControlName,
} from "@angular/forms";
import { CustomerService } from "src/app/core/services/customer.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  STATES,
  PARTY_TYPE,
  ADDRESS_TYPES,
  CREATION_SIDEPANEL_DATA_TYPE,
} from "src/app/core/constants/constants";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Address, ADDRESS_KIND } from "src/app/core/models/address.model";
import {
  CreationSlidePanelData,
  CreationSlidepanelService,
} from "src/app/core/services/creation-slidepanel.service";
import { UserService } from "src/app/core/services/user.service";
import Party from "src/app/core/models/party.model";
import {
  ToastMessage,
  ToastService,
} from "src/app/core/services/toast.service";
import { ConfirmExitModalComponent } from "src/app/shared/confirm-exit-modal/confirm-exit-modal.component";

@Component({
  selector: "app-create-party",
  templateUrl: "./create-party.component.html",
  styleUrls: ["./create-party.component.scss"],
})
export class CreatePartyComponent implements OnInit {
  @Input() isInSidePanel = false;

  partyTypeOptions = this.utilities.convertStringEnumToMapList(PARTY_TYPE);

  addressTypeOptions = this.utilities.convertStringEnumToMapList(ADDRESS_TYPES);

  ADDRESS_KIND = ADDRESS_KIND;

  states = STATES.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  isShippingAddressSame = true;

  partyForm = new FormGroup({
    id: new FormControl<string | null>(""),
    type: new FormControl<number | undefined>(undefined, [Validators.required]),
    first_name: new FormControl("", [Validators.required]),
    last_name: new FormControl(""),
    contact_details: new FormGroup({
      email: new FormControl(""),
      mobile_number: new FormGroup({
        dial_code: new FormControl("91"),
        value: new FormControl("", [Validators.required]),
      }),
    }),
    gstin: new FormControl(""),
    company_name: new FormControl(""),
    credit_info: new FormGroup({
      balance: new FormControl<number | undefined>(undefined),
      limit: new FormControl<number | undefined>(undefined),
      period: new FormControl<number | undefined>(undefined),
    }),
    billing_address: new FormArray<FormControl<Address | null>>([]),
    shipping_address: new FormArray<FormControl<Address | null>>([]),
  });

  isSubmitted = false;

  addressKind: ADDRESS_KIND | undefined = undefined;

  isForEditing = false;

  partyForEditing!: Party;

  constructor(
    private cs: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    public utilities: UtilitiesService,
    private csps: CreationSlidepanelService,
    private us: UserService,
    private ts: ToastService,
    private cem: ConfirmExitModalComponent
  ) {
    console.log(
      "party for edit :: ",
      this.router.getCurrentNavigation()?.extras.state?.party
    );

    this.isForEditing = this.router.getCurrentNavigation()?.extras.state?.party
      ? true
      : false;

    if (this.isForEditing) {
      this.partyForEditing =
        this.router.getCurrentNavigation()?.extras.state?.party;

      console.log("edit party type is :: ", this.partyForEditing.type);
      this.partyForm.patchValue({
        id:
          this.partyForEditing.id && this.partyForEditing.id !== ""
            ? this.partyForEditing.id
            : "",
        type:
          this.partyForEditing.type !== undefined
            ? this.partyForEditing.type
            : undefined,
        first_name: this.partyForEditing.first_name
          ? this.partyForEditing.first_name
          : "",
        last_name: this.partyForEditing.last_name
          ? this.partyForEditing.last_name
          : "",
        contact_details: {
          email: this.partyForEditing.contact_details.email
            ? this.partyForEditing.contact_details.email
            : "",
          mobile_number: {
            dial_code: this.partyForEditing.contact_details.mobile_number
              .dial_code
              ? this.partyForEditing.contact_details.mobile_number.dial_code
              : "",
            value: this.partyForEditing.contact_details.mobile_number.value
              ? this.partyForEditing.contact_details.mobile_number.value
              : "",
          },
        },
        gstin: this.partyForEditing.gstin ? this.partyForEditing.gstin : "",
        company_name: this.partyForEditing.company_name
          ? this.partyForEditing.company_name
          : "",
        credit_info: {
          balance: this.partyForEditing.credit_info.balance
            ? this.partyForEditing.credit_info.balance
            : null,
          limit: this.partyForEditing.credit_info.limit
            ? this.partyForEditing.credit_info.limit
            : null,
          period: this.partyForEditing.credit_info.period
            ? this.partyForEditing.credit_info.period
            : null,
        },
      });

      console.log(
        "edit party form raw value :: ",
        this.partyForm.getRawValue()
      );

      this.partyForEditing.billing_address?.forEach((address) => {
        this.partyForm.controls.billing_address.push(
          new FormControl<Address>(new Address(address))
        );
      });

      this.partyForEditing.shipping_address?.forEach((address) => {
        this.partyForm.controls.shipping_address.push(
          new FormControl<Address>(new Address(address))
        );
      });
    }
  }

  ngOnInit(): void {
    this.us.getCompanyAddressUpdate().subscribe((address: Address) => {
      console.log("address for party :: ", address);

      if (this.addressKind === ADDRESS_KIND.Billing) {
        if (address.id && address.id !== "") {
          const billingAddresses = this.partyForm.controls.billing_address;

          if (billingAddresses?.controls.length) {
            for (let control of billingAddresses?.controls) {
              const addressControl = control.getRawValue() as Address;
              if (
                Object.keys(addressControl).length &&
                addressControl.id === address.id
              ) {
                control.setValue(address);
              }
            }
          } else {
            this.partyForm.controls.billing_address.push(
              new FormControl<Address>(new Address(address))
            );
          }
        } else {
          this.partyForm.controls.billing_address.push(
            new FormControl<Address>(new Address(address))
          );
        }
      }

      if (this.addressKind === ADDRESS_KIND.Shipping) {
        if (address.id && address.id !== "") {
          const shippingAddresses = this.partyForm.controls.shipping_address;

          if (shippingAddresses?.controls.length) {
            for (let control of shippingAddresses?.controls) {
              const addressControl = control.getRawValue() as Address;
              if (
                Object.keys(addressControl).length &&
                addressControl.id === address.id
              ) {
                control.setValue(address);
              }
            }
          } else {
            this.partyForm.controls.shipping_address.push(
              new FormControl<Address>(new Address(address))
            );
          }
        } else {
          this.partyForm.controls.shipping_address.push(
            new FormControl<Address>(new Address(address))
          );
        }
      }

      this.addressKind = undefined;
      this.csps.closeSlidePanel();
    });
  }

  // ngAfterViewInit(): void {
  //   for (const controlProperty in this.newParty.controls.billing_address
  //     .controls) {
  //     this.newParty.controls.billing_address.controls[
  //       controlProperty
  //     ].valueChanges.subscribe((result: any) => {
  //       if (
  //         this.isShippingAddressSame &&
  //         this.newParty.controls.shipping_address.controls.hasOwnProperty(
  //           controlProperty
  //         )
  //       ) {
  //         this.newParty.controls.shipping_address
  //           .get(controlProperty)
  //           ?.setValue(result);

  //         if (controlProperty.toUpperCase() === "STATE_CODE") {
  //           const stateCode =
  //             this.newParty.controls.billing_address.controls[
  //               controlProperty
  //             ].getRawValue();

  //           const stateName = STATES.find(
  //             (el) => el.gstCode === stateCode
  //           )?.name;

  //           if (stateName) {
  //             this.newParty.controls.billing_address.patchValue({
  //               state: stateName,
  //             });
  //             this.newParty.controls.shipping_address.patchValue({
  //               state: stateName,
  //             });
  //           }
  //         }
  //       } else {
  //         if (controlProperty.toUpperCase() === "STATE_CODE") {
  //           const stateCode =
  //             this.newParty.controls.billing_address.controls[
  //               controlProperty
  //             ].getRawValue();

  //           const stateName = STATES.find(
  //             (el) => el.gstCode === stateCode
  //           )?.name;

  //           if (stateName) {
  //             const parentFormGroup =
  //               this.newParty.controls.billing_address.controls[controlProperty]
  //                 .parent;
  //             parentFormGroup.patchValue({
  //               state: stateName,
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }
  // }

  backToCustomerListing(partyId?: string) {
    if (this.partyForm.pristine || (partyId && partyId !== "")) {
      this.router.navigate(["../parties"], {
        state: { partyId },
        relativeTo: this.route.parent,
      });
      return;
    }

    this.cem.confirm().subscribe((isConfirmed) => {
      if (!isConfirmed) return;

      this.router.navigate(["../parties"], {
        state: { partyId },
        relativeTo: this.route.parent,
      });
    });
  }

  createUpdateParty() {
    this.isSubmitted = true;

    if (!this.partyForm.valid) return;

    console.log("creating customer...");
    let payload: Party = this.partyForm.getRawValue() as Party;

    console.log("party payload :: ", payload);

    payload.billing_address.forEach((address) => {
      address.id = address.id.length <= 5 ? "" : address.id;
    });

    payload.shipping_address.forEach((address) => {
      address.id = address.id.length <= 5 ? "" : address.id;
    });

    if (payload.id && payload.id !== "") {
      this.cs.updateParty(payload, payload.id).subscribe({
        next: (response: any) => {
          console.log("update party response :: ", response);
          const toastMessage: ToastMessage = {
            show: true,
            severity: "success",
            summary: "Success",
            detail: "Party updated.",
          };
          this.ts.sendMessage(toastMessage);
          this.backToCustomerListing();
        },
      });
    } else {
      this.cs.createParty(payload).subscribe({
        next: (response: any) => {
          if (this.isInSidePanel) {
            this.cs.sendMessage(response.data.id);
          } else {
            this.backToCustomerListing(response.data?.id);
          }
        },
        error: (error: any) => {
          console.log("error in creating party :: ", error);
          if (error.status === 400) {
            error.error.details?.forEach((error: any) => {
              console.log(
                "setting error for :: ",
                this.partyForm.get(error.key)
              );
              this.partyForm
                .get(error.key)
                ?.setErrors({ invalid: error.message });

              // TODO: smooth scroll
            });
          }
        },
      });
    }
  }

  copyBillingAddress(event: any) {
    if (!event.checked) return;
    this.partyForm.controls.shipping_address.patchValue(
      this.partyForm.controls.billing_address.getRawValue()
    );
  }

  partyTypeChanged(event: any) {
    // console.log("partyTypeChanged :: ", event);
    const gstin = this.partyForm.controls.gstin;
    const company_name = this.partyForm.controls.company_name;

    // if party type is customer
    if (event.value === 0) {
      gstin.removeValidators(Validators.required);
      gstin.setErrors(null);
      gstin.updateValueAndValidity();

      company_name.removeValidators(Validators.required);
      company_name.setErrors(null);
      company_name.updateValueAndValidity();
    }
    // if party type is vendor
    if (event.value === 1) {
      gstin.setValidators(Validators.required);
      gstin.setErrors({ required: true });
      gstin.updateValueAndValidity();

      company_name.setValidators(Validators.required);
      company_name.setErrors({ required: true });
      company_name.updateValueAndValidity();
    }
  }

  editAddress(addressGroup: AbstractControl, addressKind: ADDRESS_KIND) {
    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType: CREATION_SIDEPANEL_DATA_TYPE.address,
      data: addressGroup.value,
    };

    this.addressKind = addressKind;

    this.csps.openSlidePanel(creationPanelData);
  }

  addNewAddress(addressKind: ADDRESS_KIND) {
    const newAddress: Address = new Address({
      id: Math.floor(Math.random() * 90000),
    });
    this.addressKind = addressKind;

    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType: CREATION_SIDEPANEL_DATA_TYPE.address,
      data: newAddress,
    };

    this.csps.openSlidePanel(creationPanelData);
  }
}
