<form action="" [formGroup]="company" class="flex flex-column gap-3">
  <section class="flex flex-column gap-3">
    <h6>Basic Details</h6>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <label for="companyName">Company Name</label>
          <input
            pInputText
            maxlength="250"
            formControlName="company_name"
            id="companyName"
          />
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <label for="brandName">Brand Name</label>
          <input
            pInputText
            maxlength="250"
            formControlName="brand_name"
            id="brandName"
          />
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <label for="gstin">gstin</label>
          <input
            pInputText
            alphaNumeric
            maxlength="50"
            formControlName="gstin"
            id="gstin"
          />
        </div>
      </div>
      <div class="flex-1">
        <!-- <div class="input-group">
          <label for="unit">Email Address</label>
          <input pInputText maxlength="250" formControlName="email" />
        </div> -->
      </div>
    </div>
  </section>
  <p-divider></p-divider>
  <section class="flex flex-column gap-3">
    <h6>Contact Details</h6>
    <div class="flex gap-3" formGroupName="contact_details">
      <div class="flex-1">
        <div class="input-group">
          <label for="mobileNumber">Mobile Number</label>
          <div class="p-inputgroup" formGroupName="mobile_number">
            <span class="p-inputgroup-addon">+91</span>
            <input
              pInputText
              numbersOnly
              maxlength="10"
              placeholder="Enter your mobile number"
              formControlName="value"
              id="mobileNumber"
            />
          </div>
          <!-- <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="
              company.controls.contact_details.controls.mobile_number.controls
                .value
            "
          >
          </app-field-error> -->
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <label for="email">email</label>
          <input
            pInputText
            type="email"
            maxlength="250"
            placeholder="someone@example.com"
            formControlName="email"
            id="email"
          />
        </div>
        <!-- <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="company.controls.contact_details.controls.email"
        >
        </app-field-error> -->
      </div>
    </div>
    <div>
      <div class="grid gap-3 w-100">
        <!-- billing address panel -->
        <div
          id="billing-address-wrapper"
          class="flex flex-column gap-3"
          formArrayName="billing_address"
        >
          <p-panel styleClass="addressPanel">
            <ng-template pTemplate="header">
              <div
                class="flex align-items-center justify-content-space-between w-100"
              >
                <span class="vertical-align-middle"
                  ><b>Billing Address</b></span
                >
              </div>
            </ng-template>
            <div class="grid" id="address-grid">
              <div
                class="card address flex align-items-center justify-content-center"
                id="add-new-card"
              >
                <div
                  class="flex flex-column gap-1 align-items-center justify-content-center"
                >
                  <i class="pi pi-plus-circle" style="font-size: 1.5rem"></i>
                  <span>New address</span>
                </div>
              </div>
              <ng-container
                *ngFor="
                  let address of company.controls.billing_address.controls;
                  let i = index;
                  let isLast = last
                "
              >
                <!-- take this for create/update address -->
                <!-- <div [formGroupName]="i" class="grid gap-3">
                <div class="input-group">
                  <label for="productType">Choose address type</label>
                  <p-selectButton
                    [options]="addressTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="type"
                  ></p-selectButton>
                </div>
                <div class="flex gap-3">
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">address line 1</label>
                      </div>
                      <input
                        pInputText
                        maxlength="250"
                        formControlName="line_1"
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">address line 2</label>
                      </div>
                      <input
                        pInputText
                        maxlength="250"
                        formControlName="line_2"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex gap-3">
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">nick name</label>
                        <span class="optional">(optional)</span>
                      </div>
                      <input
                        pInputText
                        maxlength="250"
                        placeholder="Home Office"
                        formControlName="nick_name"
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">landmark</label>
                        <span class="optional">(optional)</span>
                      </div>
                      <input
                        pInputText
                        maxlength="250"
                        placeholder="Opposite High School"
                        formControlName="landmark"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex gap-3">
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">city</label>
                      </div>
                      <input
                        pInputText
                        maxlength="250"
                        formControlName="city"
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">state</label>
                      </div>
                      <p-dropdown
                        [options]="states"
                        formControlName="state_code"
                        optionLabel="name"
                        optionValue="gstCode"
                        placeholder="Select a State"
                        [filter]="true"
                        [filterBy]="'name'"
                        [autoDisplayFirst]="false"
                      ></p-dropdown>
                    </div>
                  </div>
                </div>
                <div class="flex gap-3">
                  <div class="flex-1">
                    <div class="input-group">
                      <div class="flex gap-1">
                        <label class="input-label">postal code</label>
                      </div>
                      <input
                        pInputText
                        numbersOnly
                        maxlength="6"
                        formControlName="postal_code"
                      />
                    </div>
                  </div>
                  <div class="flex-1"></div>
                </div>
                <p-divider *ngIf="!isLast"></p-divider>
                <p-button
                  *ngIf="isLast"
                  icon="pi pi-plus-circle"
                  label="Add billing address"
                  styleClass="p-button-text p-1"
                  (onClick)="addAddress(0)"
                  ></p-button>
                </div> -->

                <div class="card address">
                  <div class="grid gap-min">
                    <div
                      *ngIf="
                        address.value.nick_name &&
                        address.value.nick_name !== null &&
                        address.value.nick_name !== ''
                      "
                    >
                      <span
                        ><b>{{ address.value.nick_name }}</b></span
                      >
                    </div>
                    <div>
                      <span class="white-space-pre">
                        {{ utilities.getAddressForDisplay(address.value) }}
                      </span>
                    </div>
                  </div>
                  <div class="mt-3 flex gap-2 actions">
                    <span class="action flex align-items-center gap-2">
                      <p-button
                        icon="pi pi-pencil"
                        label="Edit"
                        styleClass="p-button-text p-1"
                        (onClick)="editAddress(address)"
                      ></p-button>
                    </span>
                    <span class="action flex align-items-center gap-2">
                      <p-button
                        icon="pi pi-trash"
                        label="Delete"
                        styleClass="p-button-text p-1"
                      ></p-button>
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </p-panel>
        </div>
        <!-- shipping address panel -->
        <div id="shipping-address-wrapper" formArrayName="shipping_address">
          <p-panel styleClass="addressPanel">
            <ng-template pTemplate="header">
              <div
                class="flex align-items-center justify-content-space-between w-100"
              >
                <span class="vertical-align-middle">Shipping Address</span>
              </div>
            </ng-template>
            <div class="flex flex-column gap-3">
              <ng-container
                *ngFor="
                  let address of company.controls.shipping_address.controls;
                  let i = index;
                  let isLast = last
                "
              >
                <div [formGroupName]="i" class="grid gap-3">
                  <span>#{{ i + 1 }}</span>
                  <div class="input-group">
                    <label for="productType">Choose address type</label>
                    <p-selectButton
                      [options]="addressTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="type"
                    ></p-selectButton>
                  </div>
                  <div class="flex gap-3">
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">address line 1</label>
                        </div>
                        <input
                          pInputText
                          maxlength="250"
                          formControlName="line_1"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">address line 2</label>
                        </div>
                        <input
                          pInputText
                          maxlength="250"
                          formControlName="line_2"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex gap-3">
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">nick name</label>
                          <span class="optional">(optional)</span>
                        </div>
                        <input
                          pInputText
                          maxlength="250"
                          placeholder="Home Office"
                          formControlName="nick_name"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">landmark</label>
                          <span class="optional">(optional)</span>
                        </div>
                        <input
                          pInputText
                          maxlength="250"
                          placeholder="Opposite High School"
                          formControlName="landmark"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex gap-3">
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">city</label>
                        </div>
                        <input
                          pInputText
                          maxlength="250"
                          formControlName="city"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">state</label>
                        </div>
                        <p-dropdown
                          [options]="states"
                          formControlName="state_code"
                          optionLabel="name"
                          optionValue="gstCode"
                          placeholder="Select a State"
                          [filter]="true"
                          [filterBy]="'name'"
                          [autoDisplayFirst]="false"
                        ></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="flex gap-3">
                    <div class="flex-1">
                      <div class="input-group">
                        <div class="flex gap-1">
                          <label class="input-label">postal code</label>
                        </div>
                        <input
                          pInputText
                          numbersOnly
                          maxlength="6"
                          formControlName="postal_code"
                        />
                      </div>
                    </div>
                    <div class="flex-1"></div>
                  </div>
                  <p-divider *ngIf="!isLast"></p-divider>
                  <p-button
                    *ngIf="isLast"
                    icon="pi pi-plus-circle"
                    label="Add billing address"
                    styleClass="p-button-text p-1"
                    (onClick)="addAddress(1)"
                  ></p-button>
                </div>
              </ng-container>
              <!-- here -->
            </div>
          </p-panel>
        </div>
      </div>
    </div>
  </section>
</form>
