import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { STOCKFLOW_TYPES } from "src/app/core/constants/constants";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import {
  CreationSlidePanelData,
  CreationSlidepanelService,
} from "src/app/core/services/creation-slidepanel.service";
import StockFlow from "src/app/core/models/stock-flow.model";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { ProductService } from "src/app/core/services/product.service";

@Component({
  selector: "app-stockflow",
  templateUrl: "./stockflow.component.html",
  styleUrls: ["./stockflow.component.scss"],
})
export class StockflowComponent implements OnInit {
  STOCKFLOW_TYPES = STOCKFLOW_TYPES;

  @Input() stockFlowType!: STOCKFLOW_TYPES;

  @Input() sidePanelContent!: CreationSlidePanelData;

  product!: Inventory_Item;

  stockFlow: StockFlow = {} as StockFlow;

  stockFlowForm = new FormGroup({
    quantity: new FormControl<number>(0, [Validators.required]),
    price: new FormControl<number>(0, [Validators.required]),
    transaction_date: new FormControl(new Date(), [Validators.required]),
  });

  constructor(
    private csps: CreationSlidepanelService,
    private utilities: UtilitiesService,
    private ps: ProductService
  ) {}

  ngOnInit(): void {
    if (
      this.stockFlowType === undefined ||
      this.sidePanelContent === undefined
    ) {
      console.log("did not receive any stockFlowType. closing side panel...");
      this.csps.closeSlidePanel();
    }

    this.product = this.sidePanelContent.data as Inventory_Item;
  }

  updateStock() {
    this.stockFlow = {
      type: this.stockFlowType,
      price: this.stockFlowForm.controls.price.getRawValue()!,
      quantity: this.stockFlowForm.controls.quantity.getRawValue()!,
      transaction_date: this.utilities.dateToEpoch(
        this.stockFlowForm.controls.transaction_date.getRawValue()
      ),
    };

    console.log("updating stock :: ", this.stockFlow);

    if (!this.product.id) return;

    this.ps.updateStockFlow(this.product.id, this.stockFlow).subscribe({
      next: (response: any) => {
        console.log("updateStockFlow response :: ", response);
        if (response.statusCode === 200 && response.data.id !== "") {
          this.ps.sendStockFlowUpdate(response.data.id);
        }
      },
      error: (error: any) => {
        console.log("updateStockFlow error :: ", error);
      },
    });
  }
}
