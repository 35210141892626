import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import Chart from "chart.js/auto";
import { ChartItem, registerables, ChartOptions } from "chart.js";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() data!: Array<number>;

  doughnutChartColors = [
    "rgb(255, 205, 86)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
  ];

  chartOptions!: ChartOptions;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    var chartDom = document.getElementById("myChart") as ChartItem;
    var bgColors: Array<string> = [];

    this.data.forEach((data, index) => {
      if (data > 0) {
        bgColors.push(this.doughnutChartColors[index]);
      }
    });

    const chart = new Chart(chartDom, {
      type: "doughnut",
      data: {
        labels: ["Red", "Blue"],
        datasets: [
          {
            data: this.data,
            backgroundColor: bgColors,
            borderRadius: 6,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            labels: {
              font: {
                family: "Ubuntu",
                size: 14,
              },
              boxHeight: 20,
              boxWidth: 20,
              useBorderRadius: true,
              borderRadius: 8,
            },
          },
          tooltip: {
            enabled: true,
            bodyFont: {
              family: "Ubuntu",
              size: 12,
            },
            titleFont: {
              family: "Ubuntu",
              size: 14,
            },
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 16,
          },
        },
      },
    });
  }

  ngAfterViewInit(): void {
    // this.myChart.resize({
    //   width: "auto",
    //   height: "auto",
    //   silent: false,
    // });
  }
}
