<div *ngIf="!isInSidePanel" class="section-header-wrapper pb-1 mb-3">
  <div class="flex justify-content-space-between align-items-center">
    <p class="heading">New Party</p>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-sm p-button-secondary p-button-text"
      (click)="backToCustomerListing()"
    ></p-button>
  </div>
</div>
<form [formGroup]="partyForm" (ngSubmit)="createUpdateParty()">
  <div id="new-customer-wrapper" class="flex flex-column gap-4">
    <section>
      <p class="title">Basic Details</p>
      <div class="input-group">
        <label for="productType">party type</label>
        <p-selectButton
          [options]="partyTypeOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="type"
          (onChange)="partyTypeChanged($event)"
        ></p-selectButton>
        <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="partyForm.controls.type"
        >
        </app-field-error>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <label for="productName">First Name</label>
            <input
              pInputText
              maxlength="250"
              placeholder="Aakash"
              formControlName="first_name"
            />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls.first_name"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label for="unit">Last Name</label>
            <input
              pInputText
              maxlength="250"
              placeholder="Joshi"
              formControlName="last_name"
            />
          </div>
        </div>
      </div>
      <div class="flex gap-3" formGroupName="contact_details">
        <div class="flex-1">
          <div class="input-group">
            <label for="productName">Mobile Number</label>
            <div class="p-inputgroup" formGroupName="mobile_number">
              <span class="p-inputgroup-addon">+91</span>
              <input
                pInputText
                numbersOnly
                maxlength="10"
                placeholder="Enter your mobile number"
                formControlName="value"
              />
            </div>
            <app-field-error
              [isFormSubmitted]="isSubmitted"
              [fieldControl]="
                partyForm.controls.contact_details.controls.mobile_number
                  .controls.value
              "
            >
            </app-field-error>
          </div>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label for="unit">email</label>
            <input
              pInputText
              type="email"
              maxlength="250"
              placeholder="someone@example.com"
              formControlName="email"
            />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls.contact_details.controls.email"
          >
          </app-field-error>
        </div>
      </div>
    </section>
    <hr class="section-divider" />
    <section>
      <p class="title">Company Details</p>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">gstin</label>
              <span
                class="optional"
                *ngIf="partyForm.controls.type.getRawValue() !== 1"
                >(optional)</span
              >
            </div>
            <input
              pInputText
              maxlength="250"
              placeholder="32AAKTCD3200N1TS"
              formControlName="gstin"
            />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls['gstin']"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">company name</label>
              <span
                class="optional"
                *ngIf="partyForm.controls.type.getRawValue() !== 1"
                >(optional)</span
              >
            </div>
            <input
              pInputText
              maxlength="250"
              placeholder="KT Solutions Pvt. Ltd."
              formControlName="company_name"
            />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls['company_name']"
          >
          </app-field-error>
        </div>
      </div>
      <div class="flex gap-3" formGroupName="credit_info">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">Opening balance</label>
              <span class="optional">(optional)</span>
            </div>
            <p-inputNumber
              [useGrouping]="true"
              mode="currency"
              currency="INR"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              formControlName="balance"
            ></p-inputNumber>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls.credit_info.controls['balance']"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">credit limit</label>
              <span class="optional">(optional)</span>
            </div>
            <p-inputNumber
              [useGrouping]="true"
              mode="currency"
              currency="INR"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              formControlName="limit"
            ></p-inputNumber>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls.credit_info.controls['limit']"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">credit period</label>
              <span
                class="optional"
                *ngIf="partyForm.controls.type.getRawValue() !== 1"
                >(optional)</span
              >
            </div>
            <div class="p-inputgroup">
              <p-inputNumber
                [useGrouping]="false"
                formControlName="period"
              ></p-inputNumber>
              <span class="p-inputgroup-addon">days</span>
            </div>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="partyForm.controls['company_name']"
          >
          </app-field-error>
        </div>
      </div>
    </section>
    <hr class="section-divider" />
    <section>
      <p class="title">Address details</p>
      <div class="flex gap-3 w-100">
        <!-- billing address panel -->
        <p-panel class="flex-1" styleClass="addressPanel">
          <ng-template pTemplate="header">
            <div
              class="flex align-items-center justify-content-space-between w-100"
            >
              <span class="vertical-align-middle"><b>Billing Address</b></span>
            </div>
          </ng-template>
          <div class="grid" id="address-grid">
            <div
              class="card address flex align-items-center justify-content-center"
              id="add-new-card"
              (click)="addNewAddress(ADDRESS_KIND.Billing)"
            >
              <div
                class="flex flex-column gap-1 align-items-center justify-content-center"
              >
                <i class="pi pi-plus-circle" style="font-size: 1.5rem"></i>
                <span>New address</span>
              </div>
            </div>
            <ng-container
              *ngFor="
                let address of partyForm.controls.billing_address.controls;
                let i = index;
                let isLast = last
              "
            >
              <div class="card address">
                <div class="grid gap-min">
                  <div
                    *ngIf="
                      address?.value?.nick_name &&
                      address?.value?.nick_name !== null &&
                      address?.value?.nick_name !== ''
                    "
                  >
                    <span
                      ><b>{{ address?.value?.nick_name! }}</b></span
                    >
                  </div>
                  <div>
                    <span class="white-space-pre">
                      {{ utilities.getAddressForDisplay(address?.value) }}
                    </span>
                  </div>
                </div>
                <div class="mt-3 flex gap-2 actions">
                  <span class="action flex align-items-center gap-2">
                    <p-button
                      icon="pi pi-pencil"
                      label="Edit"
                      styleClass="p-button-text p-1"
                      (onClick)="editAddress(address, ADDRESS_KIND.Billing)"
                    ></p-button>
                  </span>
                  <span class="action flex align-items-center gap-2">
                    <p-button
                      icon="pi pi-trash"
                      label="Delete"
                      styleClass="p-button-text p-1"
                    ></p-button>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <div
            id="billing-address-wrapper"
            class="flex flex-column gap-3"
            formGroupName="billing_address"
          >
            <div class="input-group">
              <label for="productType">Choose address type</label>
              <p-selectButton
                [options]="addressTypeOptions"
                optionLabel="label"
                optionValue="value"
                formControlName="address_type"
              ></p-selectButton>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">address line 1</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="line_1" />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">address line 2</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="line_2" />
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">nick name</label>
                    <span class="optional">(optional)</span>
                  </div>
                  <input
                    pInputText
                    maxlength="250"
                    placeholder="Home Office"
                    formControlName="nick_name"
                  />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">landmark</label>
                    <span class="optional">(optional)</span>
                  </div>
                  <input
                    pInputText
                    maxlength="250"
                    placeholder="Opposite High School"
                    formControlName="landmark"
                  />
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">city</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="city" />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">state</label>
                  </div>
                  <p-dropdown
                    [options]="states"
                    formControlName="state_code"
                    optionLabel="name"
                    optionValue="gstCode"
                    placeholder="Select a State"
                    [filter]="true"
                    [filterBy]="'name'"
                    [autoDisplayFirst]="false"
                  ></p-dropdown>
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">postal code</label>
                  </div>
                  <input
                    pInputText
                    numbersOnly
                    maxlength="6"
                    formControlName="postal_code"
                  />
                </div>
              </div>
              <div class="flex-1"></div>
            </div>
          </div> -->
        </p-panel>
        <!-- shipping address panel -->
        <!-- <div
          id="shipping-address-wrapper"
          formGroupName="shipping_address"
          class="flex-1"
        >
          <p-panel styleClass="addressPanel">
            <ng-template pTemplate="header">
              <div
                class="flex align-items-center justify-content-space-between w-100"
              >
                <span class="vertical-align-middle">Shipping Address</span>
                <div class="flex gap-1">
                  <p-checkbox
                    [(ngModel)]="isShippingAddressSame"
                    [ngModelOptions]="{ standalone: true }"
                    [binary]="true"
                    inputId="isShippingAddressSame"
                    (onChange)="copyBillingAddress($event)"
                  ></p-checkbox>
                  <label for="isShippingAddressSame"
                    >Same as billing address</label
                  >
                </div>
              </div>
            </ng-template>
            <div id="billing-address-wrapper" class="flex flex-column gap-3">
              <div class="input-group">
                <label for="productType">Choose address type</label>
                <p-selectButton
                  [options]="addressTypeOptions"
                  optionLabel="label"
                  optionValue="value"
                  formControlName="address_type"
                  [disableControl]="isShippingAddressSame"
                ></p-selectButton>
              </div>
              <div class="flex gap-3">
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">address line 1</label>
                    </div>
                    <input
                      pInputText
                      maxlength="250"
                      formControlName="line_1"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">address line 2</label>
                    </div>
                    <input
                      pInputText
                      maxlength="250"
                      formControlName="line_2"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-3">
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">nick name</label>
                      <span class="optional">(optional)</span>
                    </div>
                    <input
                      pInputText
                      maxlength="250"
                      placeholder="Home Office"
                      formControlName="nick_name"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">landmark</label>
                      <span class="optional">(optional)</span>
                    </div>
                    <input
                      pInputText
                      maxlength="250"
                      placeholder="Opposite High School"
                      formControlName="landmark"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-3">
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">city</label>
                    </div>
                    <input
                      pInputText
                      maxlength="250"
                      formControlName="city"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">state</label>
                    </div>
                    <p-dropdown
                      [options]="states"
                      formControlName="state_code"
                      optionLabel="name"
                      optionValue="gstCode"
                      placeholder="Select a State"
                      [filter]="true"
                      [filterBy]="'name'"
                      [disableControl]="isShippingAddressSame"
                      [autoDisplayFirst]="false"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="flex gap-3">
                <div class="flex-1">
                  <div class="input-group">
                    <div class="flex gap-1">
                      <label class="input-label">postal code</label>
                    </div>
                    <input
                      pInputText
                      numbersOnly
                      maxlength="6"
                      formControlName="postal_code"
                      [disableControl]="isShippingAddressSame"
                    />
                  </div>
                </div>
                <div class="flex-1"></div>
              </div>
            </div>
          </p-panel>
        </div> -->
      </div>
      <!-- <p-accordion [multiple]="true">
        <p-accordionTab header="Billing Address">
          <div
            id="billing-address-wrapper"
            class="flex flex-column gap-3"
            formGroupName="billing_address"
          >
            <div class="input-group">
              <label for="productType">Choose address type</label>
              <p-selectButton
                [options]="addressTypeOptions"
                [(ngModel)]="addressType"
                optionLabel="label"
                optionValue="value"
                formControlName="address_type"
              ></p-selectButton>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">address line 1</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="line_1" />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">address line 2</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="line_2" />
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">nick name</label>
                    <span class="optional">(optional)</span>
                  </div>
                  <input
                    pInputText
                    maxlength="250"
                    placeholder="Home Office"
                    formControlName="nick_name"
                  />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">landmark</label>
                    <span class="optional">(optional)</span>
                  </div>
                  <input
                    pInputText
                    maxlength="250"
                    placeholder="Opposite High School"
                    formControlName="landmark"
                  />
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">city</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="city" />
                </div>
              </div>
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">state</label>
                  </div>
                  <input pInputText maxlength="250" formControlName="state_code" />
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="flex-1">
                <div class="input-group">
                  <div class="flex gap-1">
                    <label class="input-label">postal code</label>
                  </div>
                  <input
                    pInputText
                    numbersOnly
                    maxlength="250"
                    formControlName="postal_code"
                  />
                </div>
              </div>
              <div class="flex-1"></div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div
              class="flex align-items-center justify-content-space-between w-100"
            >
              <span class="vertical-align-middle">Shipping Address</span>
              <div class="flex gap-1">
                <p-checkbox
                  [(ngModel)]="isShippingAddressSame"
                  [ngModelOptions]="{ standalone: true }"
                  [binary]="true"
                  inputId="isShippingAddressSame"
                ></p-checkbox>
                <label for="isShippingAddressSame"
                  >Same as billing address</label
                >
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="m-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </ng-template>
        </p-accordionTab>
      </p-accordion> -->
    </section>
  </div>
  <div id="action-buttons-wrapper" class="flex gap-3 pt-3">
    <p-button
      label="Discard"
      styleClass="p-button-secondary"
      (click)="backToCustomerListing()"
    ></p-button>
    <p-button label="Create Party" type="submit"></p-button>
  </div>
</form>
