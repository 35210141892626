<div
  id="main-content"
  [ngClass]="{
    sideNavCollapsed: isSideNavCollapsed,
    isSmallDevice: screenWidth < 768
  }"
>
  <div class="card">
    <router-outlet></router-outlet>
  </div>
</div>
