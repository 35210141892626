import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ADDRESS_TYPES, STATES } from "src/app/core/constants/constants";
import { Address } from "src/app/core/models/address.model";
import { CreationSlidePanelData } from "src/app/core/services/creation-slidepanel.service";
import { UserService } from "src/app/core/services/user.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";

@Component({
  selector: "app-create-update-address",
  templateUrl: "./create-update-address.component.html",
  styleUrls: ["./create-update-address.component.scss"],
})
export class CreateUpdateAddressComponent implements OnInit {
  @Input() panelData!: CreationSlidePanelData;

  address!: Address;

  addressTypeOptions = this.utilities.convertStringEnumToMapList(ADDRESS_TYPES);

  states = STATES.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  addressForm = new FormGroup({
    id: new FormControl(""),
    type: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    is_default: new FormControl(false),
    landmark: new FormControl(""),
    line_1: new FormControl("", [Validators.required]),
    line_2: new FormControl("", [Validators.required]),
    nick_name: new FormControl(""),
    postal_code: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    state_code: new FormControl("", [Validators.required]),
  });

  constructor(private utilities: UtilitiesService, private us: UserService) {}

  ngOnInit() {
    this.address = this.panelData.data as Address;

    Object.entries(this.address).forEach((entry) => {
      const [key, value] = entry;
      if (this.addressForm.get(key)) {
        this.addressForm.get(key)?.setValue(value);
      }
    });
  }

  updateAddress(event: any) {
    console.log("updateAddress :: ", event);
    if (!this.addressForm.valid) return;

    this.us.sendCompanyAddressUpdate(this.addressForm.getRawValue() as Address);
  }

  stateChanged(event: any) {
    console.log("state changed :: ", event);

    const selectedState = this.states.find(
      (state) => state.gstCode === event.value
    );

    if (!selectedState) return;

    this.addressForm.get("state")?.setValue(selectedState.name);
  }
}
