<div id="signup-wrapper" class="flex width-100">
  <div id="left-col" class="col flex flex-1"></div>
  <div id="right-col" class="col flex flex-1 flex-column gap-3">
    <div class="flex flex-column gap-1">
      <h2 class="flex gap-1 align-items-center" id="title">
        Hello there
        <img id="waving-hand" src="assets/img/waving-hand.png" alt="" />
      </h2>
      <h5 id="sub-title">
        Welcome to Astral Billing! Let's take your business online
      </h5>
    </div>
    <div>
      <span>But first, let's set up your account...</span>
    </div>
    <div>
      <div id="formSteps" class="mb-3">
        <p-steps
          [model]="items"
          [readonly]="true"
          [activeIndex]="activeIndex"
          (activeIndexChange)="onActiveIndexChange($event)"
          [styleClass]="'completed-' + completed"
        ></p-steps>
      </div>
      <form [formGroup]="signupForm">
        <section
          id="personal"
          class="flex flex-column gap-3"
          formGroupName="personalDetails"
          *ngIf="activeIndex === 0"
        >
          <div class="input-group">
            <label class="input-label">first name</label>
            <input
              pInputText
              formControlName="first_name"
              placeholder="Rohit"
            />
          </div>
          <div class="input-group">
            <label class="input-label">last name</label>
            <input pInputText formControlName="last_name" placeholder="Joshi" />
          </div>
          <div class="input-group">
            <label class="input-label">email</label>
            <input
              pInputText
              formControlName="email"
              placeholder="rohitjoshi@example.com"
            />
          </div>
        </section>
        <section
          id="company"
          class="flex flex-column gap-3"
          formGroupName="companyDetails"
          *ngIf="activeIndex === 1"
        >
          <div class="input-group">
            <label class="input-label">company name</label>
            <input
              pInputText
              formControlName="company_name"
              placeholder="ABC Consultancy"
            />
          </div>
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">brand name</label>
              <span class="optional">(optional)</span>
            </div>
            <input
              pInputText
              formControlName="brand_name"
              placeholder="ABC Consultancy"
            />
          </div>
          <div class="input-group">
            <label class="input-label">gstin</label>
            <input
              pInputText
              formControlName="gstin"
              placeholder="34CIDDS5925M0XX"
            />
          </div>
        </section>
        <section id="company"></section>
      </form>
      <div
        id="signup-form-actions"
        class="flex justify-content-space-between mblk-3"
      >
        <div class="flex gap-2">
          <!-- <p-button
            label="Previous"
            styleClass="p-button-secondary"
            (click)="previous()"
            *ngIf="activeIndex !== 0"
          ></p-button> -->
          <p-button
            label="Next"
            (click)="next()"
            *ngIf="activeIndex !== 1"
          ></p-button>
        </div>
        <div class="flex">
          <p-button
            label="Submit"
            (click)="next()"
            *ngIf="activeIndex === 1"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</div>
