import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "roundOffCurrency",
})
export class RoundOffCurrencyPipe implements PipeTransform {
  transform(value: number): number {
    if (typeof value !== "number") {
      return value; // Return the input as is if it's not a number
    }

    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
}
