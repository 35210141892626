<form action="" [formGroup]="user" class="flex flex-column gap-3">
  <section class="flex flex-column gap-3">
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <label for="productName">First Name</label>
          <input pInputText maxlength="250" formControlName="first_name" />
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <label for="unit">Last Name</label>
          <input pInputText maxlength="250" formControlName="last_name" />
        </div>
      </div>
    </div>
    <div class="flex gap-3" formGroupName="contact_details">
      <div class="flex-1">
        <div class="input-group">
          <label for="productName">Mobile Number</label>
          <div class="readonly">
            <span>{{ mobile_number }}</span>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <label for="unit">Email Address</label>
          <input pInputText maxlength="250" formControlName="email" />
        </div>
      </div>
    </div>
  </section>
  <div>
    <p-button label="Save" type="submit" (onClick)="updateUser()"></p-button>
  </div>
</form>
