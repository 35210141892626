import { Component } from "@angular/core";
import { ConfirmationService, ConfirmEventType } from "primeng/api";
import { Observable } from "rxjs";

@Component({
  selector: "app-confirm-exit-modal",
  templateUrl: "./confirm-exit-modal.component.html",
  styleUrls: ["./confirm-exit-modal.component.scss"],
})
export class ConfirmExitModalComponent {
  constructor(private confirmationService: ConfirmationService) {}

  public confirm(): Observable<boolean> {
    return new Observable((observer) => {
      this.confirmationService.confirm({
        message: "You will lose all the information entered.",
        header: "Are you sure?",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
        key: "confirm-exit",
      });
    });
  }
}
