<ng-container *ngIf="isLoading">
  <div id="loading-container">
    <span class="loader"></span>
  </div>
</ng-container>
<ng-container *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="isLoggedIn">
  <app-header></app-header>
  <main class="flex">
    <app-side-nav (onToggleSideNav)="onToggleSideNav($event)"></app-side-nav>
    <app-main-content
      [isSideNavCollapsed]="isSideNavCollapsed"
      [screenWidth]="screenWidth"
    ></app-main-content>
  </main>
  <!-- <p-sidebar
    [(visible)]="isSidePanelOpened"
    position="right"
    styleClass="w-40rem"
  >
    <ng-template pTemplate="header">
      <b>Invoice <span>#ID_OF_THE_INVOICE</span></b>
    </ng-template>
    <ng-template pTemplate="content">{{ sidePanelData | json }}</ng-template>
    <ng-template pTemplate="footer">Footer Content</ng-template>
  </p-sidebar> -->
  <app-slide-out-panel
    [isVisible]="isSidePanelOpened"
    [sidePanelData]="sidePanelData"
  ></app-slide-out-panel>
  <app-creation-slide-out-panel
    [isVisible]="createPanelData.openStatus"
    [sidePanelData]="createPanelData"
  ></app-creation-slide-out-panel>
</ng-container>
<div id="recaptcha-container"></div>
<app-toast></app-toast>
<app-confirm-delete-modal></app-confirm-delete-modal>
<app-confirm-exit-modal></app-confirm-exit-modal>
