import { Directive, HostListener, Input } from "@angular/core";
import { Editor } from "primeng/editor";

@Directive({
  selector: "[editorMaxChars]",
})
export class EditorMaxCharsDirective {
  @Input("editorMaxChars") maxChars!: number;

  constructor(private editor: Editor) {}

  @HostListener("input", ["$event"]) onInput(event: Event) {
    this.handleContent();
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent) {
    // Delay the execution to wait for pasted content to be available
    setTimeout(() => this.handleContent(), 50);
  }

  private handleContent() {
    const content = this.editor.getQuill().getText();
    if (content.length > this.maxChars) {
      const truncatedContent = content.slice(0, this.maxChars);
      this.editor.writeValue(truncatedContent);
    }
  }
}
