import { Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigLoaderService {
  private configuration!: any;

  private readonly CONFIG_URL = "./assets/environment-config.json";

  constructor(private httpClient: HttpClient) {}

  public loadConfiguration(): Observable<any> {
    if (!this.configuration) {
      this.configuration = this.httpClient
        .get<any>(this.CONFIG_URL)
        .pipe(shareReplay(1));
    }

    return this.configuration;
  }
}
