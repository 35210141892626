import { Component } from "@angular/core";
import { MessageService } from "primeng/api";
import {
  ToastService,
  ToastMessage,
} from "src/app/core/services/toast.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  constructor(
    private messageService: MessageService,
    private ts: ToastService
  ) {
    this.ts.getMessasge().subscribe((toast: ToastMessage) => {
      if (toast.show) {
        this.showToast(toast);
      }
    });
  }

  showToast(toast: ToastMessage) {
    this.messageService.add({
      severity: toast.severity,
      summary: toast.summary,
      detail: toast.detail,
    });
    // this.ts.sendMessage(false);
  }
}
