<div id="party-details" class="flex flex-column gap-1">
  <!-- {{ party | json }} -->
  <div
    class="balance_info flex align-item-center justify-content-space-between mb-3"
    *ngIf="
      party?.credit_info?.balance !== undefined &&
        parseFloat(math.abs(party.credit_info.balance!).toFixed(2)) !== 0.0;
      else balanceSettled
    "
  >
    <div>
      <div *ngIf="party?.credit_info?.balance! > 0" id="owes-you">
        <b>Owes you</b>
      </div>
      <div *ngIf="party?.credit_info?.balance! < 0" id="you-owe">
        <b>You owe</b>
      </div>
      <div>
        <h5
          appCurrencyFormat
          [appCurrencyFormat]="math.abs(party.credit_info.balance!).toFixed(2)"
        ></h5>
      </div>
    </div>
    <div id="reminder-button-wrapper">
      <button
        id="reminder-button"
        class="flex align-items-center gap-1"
        *ngIf="party?.credit_info?.balance! > 0"
      >
        <app-icon icon="whatsapp" color="white"></app-icon>
        Send Reminder
      </button>
    </div>
  </div>
  <ng-template #balanceSettled>
    <div id="all-settled-wrapper" class="flex gap-1 align-items-center mb-2">
      <div>
        <app-icon icon="check" color="white"></app-icon>
      </div>
      <b>All settled</b>
    </div>
  </ng-template>
  <p-tabView>
    <p-tabPanel header="Details">
      <div>
        <section>
          <b class="title mb-2 block">Contact Details</b>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">Mobile number</label>
              <span class="field-value"
                >+{{ party.contact_details.mobile_number.dial_code }}
                {{ party.contact_details.mobile_number.value }}</span
              >
            </div>
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">email</label>
              <span class="field-value">{{ party.contact_details.email }}</span>
            </div>
          </div>
        </section>
        <p-divider></p-divider>
        <section class="flex flex-column gap-3">
          <b class="title">GST & PAN</b>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">gst registration type</label>
              <span class="field-value">not yet bound</span>
            </div>
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">gstin</label>
              <span
                class="field-value"
                *ngIf="party?.gstin !== undefined; else valueNotFound"
                >{{ party.gstin }}</span
              >
            </div>
          </div>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">pan number</label>
              <span class="field-value">not yet bound</span>
            </div>
          </div>
        </section>
        <p-divider></p-divider>
        <section>
          <b class="title mb-2 block">Address</b>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">billing address</label>
              <span
                class="field-value"
                *ngIf="party?.billing_address !== undefined; else valueNotFound"
              >
                {{ displayAddress(party.billing_address![0]) }}
              </span>
            </div>
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">shipping address</label>
              <span
                class="field-value"
                *ngIf="
                  party?.shipping_address !== undefined;
                  else valueNotFound
                "
              >
                {{ displayAddress(party.shipping_address![0]) }}
              </span>
            </div>
          </div>
        </section>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Transactions">
      <!-- {{ ledger | json }} -->
      <p-table
        [columns]="columns"
        [value]="ledger | sortByEpoch : 'date'"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [tableStyle]="{ 'max-width': '100%' }"
        styleClass="p-datatable-sm"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [resizableColumns]="false"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!-- <th *ngFor="let col of columns">
              {{ col.header }}
            </th> -->
            <th></th>
            <th>Title</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ledger>
          <tr>
            <td [style.width]="'2rem'">
              <app-icon [icon]="lds.getLedgerItemIcon(ledger.type)"></app-icon>
            </td>
            <td>{{ LEDGER_TYPES[ledger.type] }} | {{ ledger.title }}</td>
            <td>&#x20B9; {{ ledger.value.toFixed(2) }}</td>
            <td>{{ utilities.epochToDate(ledger.date, "dd-MM-yyyy") }}</td>
            <!-- <td *ngFor="let col of columns" [style.width]="col?.width">
              <div [ngSwitch]="col.showAs">
                <div *ngSwitchCase="'icon'">
                  <app-icon
                    [icon]="lds.getLedgerItemIcon(_(rowData, col.field))"
                  ></app-icon>
                </div>
                <div *ngSwitchCase="'currency'">
                  &#x20B9; {{ _(rowData, col.field).toFixed(2) }}
                </div>
                <div *ngSwitchCase="'chip'">
                  <p-chip
                        [label]="
                          utilities.getInvoiceStatusLabel(_(rowData, col.field))
                        "
                        [styleClass]="
                          utilities
                            .getInvoiceStatusLabel(_(rowData, col.field))
                            .toLowerCase()
                            .replace('/\s/g', '')
                        "
                      ></p-chip>
                </div>
                <div *ngSwitchCase="'date'">
                  {{
                    utilities.epochToDate(_(rowData, col.field), "dd-MM-yyyy")
                  }}
                </div>
                <div *ngSwitchDefault>
                  <ng-container *ngIf="col.shouldConcat; else default">
                    {{ _(rowData, col.field) }}
                    {{ _(rowData, col.concatField) }}
                  </ng-container>
                  <ng-template #default>
                    {{ _(rowData, col.field) }}
                  </ng-template>
                </div>
              </div>
              <ng-container *ngIf="col.subField">
                <small>{{ _(rowData, col.subField) }}</small>
              </ng-container>
            </td> -->
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>
<ng-template #valueNotFound>
  <span>--</span>
</ng-template>
