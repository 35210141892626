<form action="" [formGroup]="addressForm" (ngSubmit)="updateAddress($event)">
  <div class="grid gap-3">
    <div class="input-group">
      <label for="productType">Choose address type</label>
      <p-selectButton
        [options]="addressTypeOptions"
        optionLabel="label"
        optionValue="value"
        formControlName="type"
      ></p-selectButton>
    </div>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">address line 1</label>
          </div>
          <input pInputText maxlength="250" formControlName="line_1" />
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">address line 2</label>
          </div>
          <input pInputText maxlength="250" formControlName="line_2" />
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">nick name</label>
            <span class="optional">(optional)</span>
          </div>
          <input
            pInputText
            maxlength="250"
            placeholder="Home Office"
            formControlName="nick_name"
          />
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">landmark</label>
            <span class="optional">(optional)</span>
          </div>
          <input
            pInputText
            maxlength="250"
            placeholder="Opposite High School"
            formControlName="landmark"
          />
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">city</label>
          </div>
          <input pInputText maxlength="250" formControlName="city" />
        </div>
      </div>
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">state</label>
          </div>
          <p-dropdown
            [options]="states"
            formControlName="state_code"
            optionLabel="name"
            optionValue="gstCode"
            placeholder="Select a State"
            [filter]="true"
            [filterBy]="'name'"
            [autoDisplayFirst]="false"
            (onChange)="stateChanged($event)"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <div class="flex gap-1">
            <label class="input-label">postal code</label>
          </div>
          <input
            pInputText
            numbersOnly
            maxlength="6"
            formControlName="postal_code"
          />
        </div>
      </div>
      <div class="flex-1"></div>
    </div>
  </div>
  <p-button
    label="Update Address"
    styleClass="p-button-sm mt-3"
    type="submit"
  ></p-button>
</form>
