import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProductService } from "src/app/core/services/product.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import {
  SidePanelData,
  SlidepanelService,
} from "src/app/core/services/slidepanel.service";
import { MenuItem } from "primeng/api";
import { ContextMenu } from "primeng/contextmenu";
import {
  ToastService,
  ToastMessage,
} from "src/app/core/services/toast.service";
import { ConfirmDeleteModalComponent } from "src/app/shared/confirm-delete-modal/confirm-delete-modal.component";
import { STOCKFLOW_TYPES } from "src/app/core/constants/constants";
import {
  CreationSlidePanelData,
  CreationSlidepanelService,
} from "src/app/core/services/creation-slidepanel.service";
import {
  CREATION_SIDEPANEL_DATA_TYPE,
  INVENTORY_ITEM_TYPE,
} from "src/app/core/constants/constants";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import { Subscription } from "rxjs";

interface Column {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
  shouldConcat?: boolean;
  concatField?: string;
}

// interface Inventory_Item {
//   name: string;
//   id: string;
//   quantity: number;
//   unit: string;
//   selling_price: string;
//   purchase_price: string;
// }

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit, OnDestroy {
  parseFloat = parseFloat;

  math = Math;

  INVENTORY_ITEM_TYPE = INVENTORY_ITEM_TYPE;

  cols: Array<Column> = [];

  products: Array<Inventory_Item> = [];

  lowStockItems: number = 0;

  positiveStockItems: number = 0;

  readonly lowStockThreshold = 10;

  stockValueSalesPrice = "";

  stockValuePurchasePrice = "";

  sidePanelData: SidePanelData = {
    openStatus: false,
    dataType: "product",
  };

  productActions!: MenuItem[];

  @ViewChild("contextMenu", { static: false }) contextMenu!: ContextMenu;

  contextMenuItems: MenuItem[] = [];

  contentLoaded = false;

  stockFlowUpdateSubscription: Subscription;

  inventoryIdToOpen!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ps: ProductService,
    private utils: UtilitiesService,
    private sps: SlidepanelService,
    private ts: ToastService,
    private cdm: ConfirmDeleteModalComponent,
    private csps: CreationSlidepanelService
  ) {
    this.stockFlowUpdateSubscription = this.ps.getStockFlowUpdate().subscribe({
      next: (stockFlowId: String) => {
        if (stockFlowId && stockFlowId !== "") {
          this.csps.closeSlidePanel();
          const toastMessage: ToastMessage = {
            show: true,
            severity: "success",
            summary: "Success",
            detail: "Inventory_Item stock has been updated.",
          };
          this.ts.sendMessage(toastMessage);
          this.ps.clearStockFlowUpdate();
          this.contentLoaded = false;
          this.getProductsForCompany();
        }
      },
    });

    this.inventoryIdToOpen =
      this.router.getCurrentNavigation()?.extras.state?.["inventoryId"];
  }

  ngOnInit(): void {
    this.cols = [
      { field: "name", header: "Name", width: "30%", showAs: "" },
      {
        field: "stock_quantity",
        header: "Quantity",
        showAs: "",
        shouldConcat: true,
        concatField: "unit",
      },
      {
        field: "type",
        header: "Item Type",
        showAs: "tag",
      },
      { field: "selling_price", header: "Selling Price", showAs: "currency" },
      { field: "purchase_price", header: "Purchase Price", showAs: "currency" },
      { field: "", header: "", width: "5%", showAs: "action" },
    ];

    this.productActions = [
      {
        label: "Bookmark",
        icon: "pi pi-fw pi-bookmark",
      },
      {
        label: "Video",
        icon: "pi pi-fw pi-video",
      },
    ];

    this.getProductsForCompany();
  }

  ngOnDestroy(): void {
    this.stockFlowUpdateSubscription?.unsubscribe();
  }

  createNewProduct() {
    this.router.navigate(["new"], { relativeTo: this.route });
  }

  getProductsForCompany() {
    this.ps.getProductsByCompany(this.utils.getCurrentCompany().id).subscribe({
      next: (res: any) => {
        this.products = res.data && res.data.length ? res.data : [];
        this.calculateStats();
        this.contentLoaded = true;

        if (this.inventoryIdToOpen && this.inventoryIdToOpen !== "") {
          const inventoryItem = this.products.find(
            (product) => product.id === this.inventoryIdToOpen
          );
          this.inventoryIdToOpen = "";
          this.viewProduct(inventoryItem!);
        }
      },
      error: (error) => {
        if (error.status === 404) {
          this.products = [];
          this.contentLoaded = true;
        }
      },
    });
  }

  viewProduct(product: Inventory_Item) {
    if (!product) return;

    this.sidePanelData.product = product;
    this.sidePanelData.openStatus = true;
    this.sps.openSlidePanel(this.sidePanelData);
  }

  deleteProduct(product: Inventory_Item) {
    this.cdm.confirm().subscribe((isConfirmed) => {
      console.log("isConfirmed :: ", isConfirmed);
      if (!isConfirmed) return;

      this.ps
        .deleteProduct(
          product.id ? product.id : "",
          this.utils.getCurrentCompany().id
        )
        .subscribe({
          next: (response: any) => {
            if (response.status !== 200) {
              console.error("Error while deleting product :: ", product.id);
            }
            const toastMessage: ToastMessage = {
              show: true,
              severity: "success",
              summary: "Success",
              detail: "Inventory_Item has been deleted.",
            };
            this.ts.sendMessage(toastMessage);
            this.contentLoaded = false;
            this.getProductsForCompany();
          },
        });
    });
  }

  calculateStats() {
    let stockSalesPrice: any = 0;
    let stockPurchasePrice: any = 0;
    this.lowStockItems = 0;
    this.positiveStockItems = 0;

    this.products.forEach((product: any) => {
      if (product.stock_quantity) {
        if (product.stock_quantity > this.lowStockThreshold) {
          this.positiveStockItems += 1;
        } else {
          this.lowStockItems += 1;
        }

        stockSalesPrice =
          stockSalesPrice + product.selling_price * product.stock_quantity;
        stockPurchasePrice =
          stockPurchasePrice +
          product.purchase_price_w_tax * product.stock_quantity;

        this.stockValueSalesPrice = Intl.NumberFormat("en-IN", {
          notation: "compact",
        }).format(stockSalesPrice);

        this.stockValuePurchasePrice = Intl.NumberFormat("en-IN", {
          notation: "compact",
        }).format(stockPurchasePrice);
      }
    });
  }

  openContextMenu(event: MouseEvent, product: any): void {
    this.contextMenuItems = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: (event) => this.editProduct(product),
      },
      {
        label: "Stock In",
        icon: "pi pi-download",
        command: (event) =>
          this.createStockflow(product, STOCKFLOW_TYPES.StockIn),
      },
      {
        label: "Stock Out",
        icon: "pi pi-upload",
        command: (event) =>
          this.createStockflow(product, STOCKFLOW_TYPES.StockOut),
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        styleClass: "danger",
        command: (event) => this.deleteProduct(product),
      },
    ];
    this.contextMenu.toggle(event);
    event.stopPropagation();
  }

  editProduct(product: Inventory_Item) {
    const state = { product };
    this.router.navigate(["./", product.id], {
      relativeTo: this.route.parent,
      state,
    });
  }

  createStockflow(product: Inventory_Item, flowType: STOCKFLOW_TYPES) {
    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType:
        flowType === STOCKFLOW_TYPES.StockIn
          ? CREATION_SIDEPANEL_DATA_TYPE.stockIn
          : CREATION_SIDEPANEL_DATA_TYPE.stockout,
      data: product,
    };

    this.csps.openSlidePanel(creationPanelData);
  }

  doActionOne(aaa: any) {
    console.log("inside doActionOne :: ", JSON.stringify(aaa));
  }
}
