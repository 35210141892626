import { Injectable } from "@angular/core";
import { PhoneNumberUtil, PhoneNumberType } from "google-libphonenumber";
import { getAllInfoByISO } from "iso-country-currency";

@Injectable({
  providedIn: "root",
})
export class Phonenumberutility {
  private pnu = PhoneNumberUtil.getInstance();

  constructor() {}

  getExpectedMobileNumberLength(dialCode: string): number | null {
    const dialCodeNumber = parseInt(dialCode, 10);

    if (isNaN(dialCodeNumber)) return null;

    const sampleMobileNumber = this.pnu.getExampleNumberForType(
      this.pnu.getRegionCodeForCountryCode(dialCodeNumber),
      PhoneNumberType.MOBILE
    );

    return sampleMobileNumber?.getNationalNumber()
      ? sampleMobileNumber.getNationalNumber()!.toString().length
      : null;
  }

  isValidMobileNumber(dialCode: string, mobileNumber: any): boolean {
    const dialCodeNumber = parseInt(dialCode, 10);

    if (isNaN(dialCodeNumber) || mobileNumber.length < 3) return false;

    return this.pnu.isValidNumberForRegion(
      this.pnu.parse(
        mobileNumber,
        this.pnu.getRegionCodeForCountryCode(dialCodeNumber)
      ),
      this.pnu.getRegionCodeForCountryCode(dialCodeNumber)
    );
  }

  getCountryDetailsFromDialCode(dialCode: string): any {
    const dialCodeNumber = parseInt(dialCode, 10);

    if (isNaN(dialCodeNumber)) return {};

    const countryCode = this.pnu.getRegionCodeForCountryCode(dialCodeNumber);

    return getAllInfoByISO(countryCode);
  }
}
