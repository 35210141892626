<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Inventory</h5>
  </div>
</div>
<p-tabView>
  <p-tabPanel header="Warehouse">
    <div class="flex flex-column gap-3">
      <div class="grid gap-3" id="stock-stats">
        <div class="flex flex-column gap-1 p-2 stock-stat-card" id="low-stock">
          <span>Low Stock</span>
          <h5>{{ lowStockItems }} items</h5>
        </div>
        <div
          class="flex flex-column gap-1 p-2 stock-stat-card"
          id="positive-stock"
        >
          <span>Positive Stock</span>
          <h5>{{ positiveStockItems }} items</h5>
        </div>
        <div
          class="flex flex-column gap-1 p-2 stock-stat-card"
          id="stock-sales-price"
        >
          <span>Stock Value Sales Price</span>
          <h5>&#x20B9; {{ stockValueSalesPrice }}</h5>
        </div>
        <div
          class="flex flex-column gap-1 p-2 stock-stat-card"
          id="stock-purchase-price"
        >
          <span>Stock Value Purchase Price</span>
          <h5>&#x20B9; {{ stockValuePurchasePrice }}</h5>
        </div>
      </div>
      <ng-container *ngIf="products.length > 0">
        <p-table
          [columns]="cols"
          [value]="products"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          styleClass="p-datatable-sm"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [resizableColumns]="false"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr (click)="viewProduct(rowData)">
              <td *ngFor="let col of columns" [style.width]="col?.width">
                <div [ngSwitch]="col.showAs">
                  <div *ngSwitchCase="'chip'">
                    <p-chip
                      [label]="rowData[col.field]"
                      styleClass="yellow"
                    ></p-chip>
                  </div>
                  <div *ngSwitchCase="'currency'">
                    <span
                      >&#x20B9;
                      {{ parseFloat(rowData[col.field]).toFixed(2) }}</span
                    >
                  </div>
                  <div *ngSwitchCase="'action'" class="flex gap-2">
                    <p-button
                      icon="pi pi-trash"
                      label="Stock In"
                      styleClass="p-button-sm btn-success-bg"
                      (onClick)="deleteProduct(rowData)"
                    ></p-button>
                    <p-button
                      icon="pi pi-trash"
                      label="Stock Out"
                      styleClass="p-button-sm btn-error-bg"
                      (onClick)="deleteProduct(rowData)"
                    ></p-button>
                  </div>
                  <div *ngSwitchDefault>{{ rowData[col.field] }}</div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Transactions">
    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
      doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
      inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
      fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
      sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
      modi.
    </p>
  </p-tabPanel>
</p-tabView>
