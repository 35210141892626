import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WindowService {
  constructor() {}

  get windowReference() {
    return window;
  }
}
