import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as UserActions from "../store_actions/user.actions";
import { LoginStatusService } from "./login-status.service";
import { UserService } from "./user.service";
import { WindowService } from "./window.service";
import { Observable } from "rxjs";
import { FirebaseApp } from "@angular/fire/app";
import {
  Auth,
  User as FirebaseUser,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "@angular/fire/auth";
import { UtilitiesService } from "./utilities.service";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "@angular/fire/app-check";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  auth: Auth;

  windowRef: any;

  constructor(
    private store: Store,
    private lss: LoginStatusService,
    private us: UserService,
    private ws: WindowService,
    private ofApp: FirebaseApp,
    private utilities: UtilitiesService
  ) {
    this.auth = getAuth(this.ofApp);
    this.windowRef = this.ws;

    // TODO: Optimize this listener
    // Listener for changes in ID token
    this.auth.onIdTokenChanged((user: FirebaseUser | null) => {
      if (user) {
        user.getIdToken(true).then((jwt: string) => {
          sessionStorage.setItem("ast-usr-tk", jwt);
        });
      }
    });

    // TODO: Optimize this listener
    // Listener for change in authentication state
    onAuthStateChanged(this.auth, (user: FirebaseUser | null) => {
      if (user) {
        // User is still signed in
        const uid = user.uid;
      } else {
        // User is signed out
        this.performLogout();
      }
    });
  }

  initializeAuth() {
    this.auth.languageCode = "en";
    return new Observable<boolean>((observer) => {
      if (!this.windowRef.recaptchaVerifier) {
        this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          this.auth
        );
      }
      observer.next(true);
    });
  }

  refreshIdToken() {
    return new Observable<string>((observer) => {
      this.auth.currentUser
        ?.getIdToken(true)
        .then((jwt: string) => {
          sessionStorage.setItem("ast-usr-tk", jwt);
          observer.next(jwt);
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  signInWithPhone(phoneNumber: string) {
    const appVerifier = this.windowRef.recaptchaVerifier;
    return signInWithPhoneNumber(this.auth, phoneNumber, appVerifier);
  }

  performLogout() {
    console.log("performing logout...");
    this.store.dispatch(UserActions.logoutUser());
    sessionStorage.clear();
    this.lss.sendMessage(false);
  }

  isAuthenticated(): boolean {
    return this.utilities.getUserFromService().isLoggedIn;
  }

  resetCaptcha() {
    return new Observable((observer) => {
      this.windowRef.recaptchaVerifier.render().then((widgetId: any) => {
        this.windowRef.recaptchaVerifier.recaptcha.reset();
        // this.windowRef.recaptchaVerifier.recaptcha.clear();
        observer.next(true);
      });
    });
  }
}
