import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { UtilitiesService } from "../services/utilities.service";
import { NgControl } from "@angular/forms";
import { Calendar } from "primeng/calendar";

@Directive({
  selector: "[appDateToEpoch]",
})
export class DateToEpochDirective {
  constructor(
    private utilities: UtilitiesService,
    private el: ElementRef,
    private formControl: NgControl
  ) {}

  @HostListener("ngModelChange", ["$event"]) onInputChange(event: any) {
    if (event) {
      const inputValue = new Date(event);
      this.formControl.control?.setValue(
        this.utilities.dateToEpoch(inputValue)
      );
    }

    // console.log("inputValue :: ", event);
  }
}
