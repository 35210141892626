import { Component, Input } from "@angular/core";
import {
  SidePanelData,
  SlidepanelService,
} from "src/app/core/services/slidepanel.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { InvoiceService } from "src/app/core/services/invoice.service";
import {
  getPaymentMethodLabel,
  getPaymentTagBackground,
} from "src/app/core/models/payment-transaction";
import { PdfService } from "src/app/core/services/pdf.service";
import { INVOICE_STATUS } from "src/app/core/models/invoice-item.model";
import {
  INVOICE_TRANSACTION_TYPES,
  STOCKFLOW_TYPES,
} from "src/app/core/constants/constants";
import { Address } from "src/app/core/models/address.model";

@Component({
  selector: "app-slide-out-panel",
  templateUrl: "./slide-out-panel.component.html",
  styleUrls: ["./slide-out-panel.component.scss"],
})
export class SlideOutPanelComponent {
  parseFloat = parseFloat;

  getPaymentMethodLabel = getPaymentMethodLabel;

  getPaymentTagBackground = getPaymentTagBackground;

  math = Math;

  INVOICE_TXN_TYPES = INVOICE_TRANSACTION_TYPES;

  STOCKFLOW_TYPES = STOCKFLOW_TYPES;

  @Input() isVisible: boolean = false;

  @Input() sidePanelData!: SidePanelData;

  mainSidebarVisible: boolean = false;

  mainSideBarStyleClasses: Array<string> = ["mainSideBar w-40rem"];

  subSidebarVisible: boolean = false;

  readonly SUBSIDEBARSTATUS = "hasSubSideBar";

  constructor(
    private sps: SlidepanelService,
    public utilities: UtilitiesService,
    private is: InvoiceService,
    private pdf: PdfService
  ) {
    this.mainSidebarVisible = this.isVisible;
  }

  close() {
    this.sps.closeSlidePanel();
  }

  closeSubPanel() {
    this.mainSideBarStyleClasses = this.mainSideBarStyleClasses.filter(
      (ele) => {
        return ele !== this.SUBSIDEBARSTATUS;
      }
    );
    this.subSidebarVisible = false;
  }

  markInvoiceAsPaid(invoice: any) {
    this.mainSideBarStyleClasses.push(this.SUBSIDEBARSTATUS);
    this.subSidebarVisible = true;
    // const companyId = this.utilities.getCurrentCompany().id;
    // const payload = invoice;

    // payload.status = "paid";

    // this.is.updateInvoice(companyId, payload).subscribe({
    //   next: (response: any) => {
    //     this.close();
    //   },
    // });
  }

  onPaymentRecord(recordResult: boolean) {
    this.subSidebarVisible = false;
    this.mainSideBarStyleClasses.splice(
      this.mainSideBarStyleClasses.findIndex(
        (ele) => ele === this.SUBSIDEBARSTATUS
      ),
      1
    );
    if (recordResult) {
      this.is
        .getInvoiceById(
          this.sidePanelData.company.id,
          this.sidePanelData.invoice.id
        )
        .subscribe({
          next: (response: any) => {
            console.log("getInvoiceById response :: ", response);
            const { company, ...invoice } = response.data;
            this.sidePanelData.invoice = invoice;
            this.sidePanelData.company = company;
          },
        });
    } else {
    }
  }

  downloadInvoice() {
    console.log(this.sidePanelData.invoice, this.sidePanelData.company);
    this.pdf.createInvoicePdf(
      this.sidePanelData.invoice,
      this.sidePanelData.company
    );
  }

  public get invoiceStatus(): typeof INVOICE_STATUS {
    return INVOICE_STATUS;
  }
}
