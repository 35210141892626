<ng-container
  *ngIf="
    isFormSubmitted ||
    (fieldControl.invalid && (fieldControl.dirty || fieldControl.touched))
  "
>
  <small
    class="error-block flex align-items-center gap-min mt-1"
    *ngFor="let errorMessage of listErrors(); let last = last"
    style="color: var(--error-red)"
  >
    <span class="pi pi-exclamation-circle"></span>
    {{ last ? errorMessage : "" }}
  </small>
</ng-container>
