import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginStatusService {
  private subject = new BehaviorSubject<boolean>(false);

  sendMessage(loginStatus: boolean) {
    this.subject.next(loginStatus);
  }

  getMessasge(): Observable<any> {
    return this.subject.asObservable();
  }
}
