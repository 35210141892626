import { NgClass } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  hostDirectives: [NgClass],
})
export class IconComponent implements OnInit, OnChanges {
  imageUrl: string = "";

  @Input() variant!: string;

  // @Input()
  // public set variant(variantName: string) {
  //   this._variant = variantName;
  // }

  @HostBinding("style.-webkit-mask-image")
  private _icon!: string;

  @Input()
  public set icon(iconName: string) {
    this._icon = `url('/assets/img/icons/${iconName}.svg')`;
  }

  @Input() color!: string;

  @HostBinding("class")
  get cssClass(): string[] {
    return [this.color ? `${this.color}` : ""];
  }

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    // if (this.variant === "duotone") {
    //   this.imageUrl = `/assets/img/icons/${this.icon}-duotone.svg`;
    //   this._icon = "";
    // } else {
    //   this._icon = `url('/assets/img/icons/${this.icon}.svg')`;
    //   this.imageUrl = "";
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes["variant"]) {
    //   if (this.variant === "duotone") {
    //     this.imageUrl = `/assets/img/icons/${this.icon}-duotone.svg`;
    //     this._icon = "";
    //   } else {
    //     this._icon = `url('/assets/img/icons/${this.icon}.svg')`;
    //     this.imageUrl = "";
    //   }
    // }
  }

  isDuotone = () => this.variant === "duotone";

  getVariant = () => this.variant;

  // getSvg() {
  //   return `/assets/img/icons/${this.icon}.svg`;
  // }
}
