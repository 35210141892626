import { ActionReducerMap, MetaReducer, createSelector } from "@ngrx/store";
import { userReducer, UserState } from "./user.reducer";
import { companiesReducer, CompaniesState } from "./company.reducer";
import { hydrationMetaReducer } from "./hydration.reducer";

export interface AppStateFromReducers {
  userState: UserState;
  companiesState: CompaniesState;
}

export const reducers: ActionReducerMap<AppStateFromReducers> = {
  userState: userReducer,
  companiesState: companiesReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];

export const selectState = (state: any) => state;

export const selectAppState = createSelector(
  selectState,
  (state: any) => state
);
