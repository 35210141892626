<div
  id="login-wrapper"
  class="flex width-100 align-items-center justify-content-center"
>
  <div class="col flex flex-1 p-4">
    <div id="login-form" class="flex">
      <div class="content flex flex-column p-4 gap-1">
        <div
          class="flex flex-column gap-3 align-items-center justify-content-center"
        >
          <div id="brand">
            <img src="../../../assets/img/astral_logo.png" alt="" />
          </div>
          <span id="slogan"
            >One solution for all your<br />
            <b
              id="dynamicText"
              appTypewriterEffect
              [textArray]="['Inventory', 'Invoicing']"
              [typingSpeed]="100"
              [pauseDuration]="2000"
            ></b>
            needs</span
          >
        </div>
        <p-divider></p-divider>
        <div class="flex flex-column gap-4 w-100 justify-content-center">
          <h5>{{ !isOtpSent ? "Login" : "Verification" }}</h5>
          <div class="flex align-items-center justify-content-space-between">
            <span>{{ !isOtpSent ? MOBILE_NUMBER_MSG : OTP_MSG }}</span>
            <span *ngIf="isOtpSent" class="link" (click)="editMobileNumber()"
              >Edit mobile number</span
            >
          </div>
          <div class="form-section-wrapper">
            <div class="flex flex-column gap-3 form-section" *ngIf="!isOtpSent">
              <div class="input-group">
                <label class="input-label">Mobile number</label>
                <div class="p-inputgroup">
                  <!-- <span class="p-inputgroup-addon"> +91 </span> -->
                  <span class="p-inputgroup-addon">
                    <p-dropdown
                      ngDefaultControl
                      [options]="countries"
                      styleClass="dropdown-addon"
                      [(ngModel)]="selectedCountry"
                      id="dial_code_dropdown"
                      [filter]="true"
                      [filterBy]="'name'"
                      (onChange)="countryChanged($event)"
                      (onBlur)="countryChanged($event)"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div>
                          <a
                            [class]="
                              'flag-icon flag-icon-' +
                              selectedCountry.code.toLowerCase()
                            "
                          ></a>
                        </div>
                      </ng-template>
                      <ng-template let-country pTemplate="item">
                        <div
                          class="flex align-items-center justify-content-space-between"
                        >
                          <div class="flex align-items-center gap-2">
                            <a
                              [class]="
                                'flag-icon flag-icon-' +
                                country.code.toLowerCase()
                              "
                            ></a>
                            <div>{{ country.name }}</div>
                          </div>
                          <span>+{{ country.dial_code }}</span>
                        </div>
                      </ng-template></p-dropdown
                    >
                  </span>
                  <input
                    pInputText
                    numbersOnly
                    [maxlength]="maxMobileNumberLength"
                    [(ngModel)]="mobileNumber"
                    (input)="onMobileNumberChange()"
                    placeholder="Enter your mobile number"
                  />
                </div>
              </div>
              <!-- <app-button
                label="Continue"
                size="medium"
                [disabled]="!isMobileNumberValid"
                (click)="sendOtp()"
              ></app-button> -->
              <p-button
                label="Continue"
                id="signIn-button"
                [disabled]="!isMobileNumberValid"
                (onClick)="sendOtp()"
                [loading]="isOtpTriggered"
              ></p-button>
            </div>
            <div class="flex flex-column gap-3 form-section" *ngIf="isOtpSent">
              <div class="input-group">
                <label class="input-label">Enter otp</label>
                <div
                  class="flex justify-content-space-between otp-fields-wrapper"
                >
                  <ng-container
                    *ngFor="
                      let n of numSequence(6);
                      let i = index;
                      let isFirst = first;
                      let isLast = last
                    "
                  >
                    <input
                      pInputText
                      numbersOnly
                      class="otp-field"
                      maxlength="1"
                      [id]="OTP_ID_PREFIX.concat(i.toString())"
                      (keyup)="
                        onOtpChange(
                          $event,
                          isFirst ? '' : i - 1,
                          i,
                          isLast ? '' : i + 1
                        )
                      "
                      (focus)="onOtpFocus($event, i)"
                      (blur)="onOtpBlur($event)"
                    />
                  </ng-container>
                </div>
                <div id="invalid-otp" *ngIf="isInvalidOtp">
                  <span class="error-message"
                    >You have entered an invalid OTP. Please try again.</span
                  >
                </div>
              </div>
              <div
                class="flex justify-content-space-between align-items-center"
              >
                <p-button
                  label="Confirm OTP"
                  [disabled]="otp.length !== otpLength"
                  (onClick)="validateOtp()"
                  [loading]="isValidatingOtp"
                ></p-button>
                <ng-container *ngIf="resendOtpCounter > 0; else resendOtp">
                  <small>Resend OTP in {{ resendOtpCounter }} sec</small>
                </ng-container>
                <ng-template #resendOtp>
                  <p-button
                    (onClick)="sendOtp(true)"
                    label="Resend OTP"
                    styleClass="p-button-link p-0"
                  ></p-button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="get-started-wrapper">
          <span>New to Astral Billing?</span>
          <span class="pl-1">Get Started</span>
        </div> -->
      </div>
    </div>
  </div>
</div>
