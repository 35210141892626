import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/core/services/user.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import User from "src/app/core/models/user.model";

@Component({
  selector: "app-user-update",
  templateUrl: "./user-update.component.html",
  styleUrls: ["./user-update.component.scss"],
})
export class UserUpdateComponent implements OnInit {
  user = new FormGroup({
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    contact_details: new FormGroup({
      email: new FormControl("", Validators.email),
      mobile_number: new FormGroup({
        dial_code: new FormControl(""),
        value: new FormControl(""),
      }),
    }),
  });

  mobile_number: string = "";

  constructor(private us: UserService) {}

  ngOnInit(): void {
    this.getUser();
  }

  private getUser() {
    this.us.getUser().subscribe({
      next: (response: any) => {
        console.log("update-user : ", response);
        this.user.patchValue({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          contact_details: {
            email: response.data.contact_details.email,
            mobile_number: {
              dial_code: response.data.contact_details.mobile_number.dial_code,
              value: response.data.contact_details.mobile_number.value,
            },
          },
        });
        this.mobile_number = "+".concat(
          response.data.contact_details.mobile_number.dial_code.concat(
            response.data.contact_details.mobile_number.value
          )
        );
      },
    });
  }

  updateUser() {
    const user = this.user.value;

    this.us.updateUser(user).subscribe({
      next: (response: any) => {
        console.log("user updated");
        this.getUser();
      },
    });
  }
}
