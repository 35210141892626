<div class="input-group">
  <label *ngIf="showLabel" [for]="id" class="input-label">{{ label }}</label>
  <input
    pInputText
    class="p-inputtext-sm"
    type="text"
    [id]="id"
    autocomplete="off"
  />
</div>
