import ContactDetails from "./contact-details.model";

export default class User {
  id: string = "";

  first_name: string = "";

  last_name: string = "";

  contact_details: ContactDetails = {} as ContactDetails;

  isLoggedIn: boolean = false;

  companyId: string = "";

  companyName: string = "";

  role?: string = "";

  constructor(data: any) {
    Object.assign(this, data);
  }
}
