<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Challans</h5>
    <p-button
      label="New challan"
      styleClass="p-button-sm"
      icon="pi pi-plus"
      iconPosition="left"
      (click)="createChallan()"
    ></p-button>
  </div>
</div>
<div *ngIf="!contentLoaded" class="flex flex-column gap-3">
  <ngx-skeleton-loader
    count="10"
    appearance="line"
    [theme]="{ 'height': '48px', 'border-radius': '8px' }"
  ></ngx-skeleton-loader>
</div>
<ng-container *ngIf="contentLoaded">
  <ng-container *ngIf="challans.length; else noDataFound">
    <p-table
      [columns]="columns"
      [value]="challans | sortByEpoch : 'update_date'"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [resizableColumns]="false"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [pSortableColumn]="col.field" *ngFor="let col of columns">
            {{ col.header }}
            <p-sortIcon
              *ngIf="col.showAs !== 'action' && col.showAs !== 'tag'"
              [field]="col.field"
            ></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr (click)="viewChallan(rowData)">
          <td *ngFor="let col of columns" [style.width]="col?.width">
            <div [ngSwitch]="col.showAs">
              <div *ngSwitchCase="'chip'">
                <p-chip
                  [label]="rowData[col.field]"
                  styleClass="yellow"
                ></p-chip>
              </div>
              <div *ngSwitchCase="'currency'">
                <span
                  >&#x20B9;
                  {{ parseFloat(rowData[col.field]).toFixed(2) }}</span
                >
              </div>
              <div *ngSwitchCase="'action'" class="flex gap-1">
                <!-- pi-ellipsis-v -->
                <!-- <p-button
                icon="pi pi-ellipsis-v"
                styleClass="p-button-sm p-button-secondary p-button-text"
                (onClick)="openContextMenu($event, rowData)"
              ></p-button>
              <p-contextMenu
                #contextMenu
                appendTo="body"
                [model]="contextMenuItems"
              ></p-contextMenu> -->
              </div>
              <div *ngSwitchCase="'tag'">
                <p-tag
                  [severity]="
                    rowData[col.field] === CHALLAN_TYPE.Inward
                      ? 'success'
                      : 'info='
                  "
                  [value]="CHALLAN_TYPE[_(rowData, col.field)]"
                ></p-tag>
              </div>
              <div *ngSwitchCase="'date'">
                {{
                  utilities.epochToDate(_(rowData, col.field), "dd MMM yyyy")
                }}
              </div>
              <div *ngSwitchDefault>
                <ng-container *ngIf="col.shouldConcat; else default">
                  {{ _(rowData, col.field) }} {{ _(rowData, col.concatField) }}
                </ng-container>
                <ng-template #default>
                  {{ _(rowData, col.field) }}
                </ng-template>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table></ng-container
  >
</ng-container>
<ng-template #noDataFound>
  <div class="flex flex-column align-items-center justify-content-center">
    <img
      class="no-data-image"
      src="../../../assets/img/empty-folder.png"
      alt="empty"
    />
    <span>You have not created any challans.</span>
  </div>
</ng-template>
