import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  environmentSubject = new ReplaySubject<any>(1);

  environmentObservable = this.environmentSubject.asObservable();

  constructor() {}

  markEnvironmentReady() {
    this.environmentSubject.next(true);
  }
}
