import { Component } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "src/app/core/services/user.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import User from "src/app/core/models/user.model";
import { Company } from "src/app/core/models/company.model";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as UserActions from "../../core/store_actions/user.actions";
import * as CompanyActions from "../../core/store_actions/company.actions";
import { LoginStatusService } from "src/app/core/services/login-status.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  providers: [MessageService],
})
export class SignupComponent {
  currentUser: User;

  items!: MenuItem[];

  activeIndex: number = 0;

  completed: number = 0;

  subscription!: Subscription;

  signupForm = new FormGroup({
    personalDetails: new FormGroup({
      first_name: new FormControl("", Validators.required),
      last_name: new FormControl("", Validators.required),
      email: new FormControl<string | undefined>(undefined, [
        Validators.required,
        Validators.email,
      ]),
    }),
    companyDetails: new FormGroup({
      company_name: new FormControl("", Validators.required),
      brand_name: new FormControl(""),
      gstin: new FormControl("", Validators.required),
    }),
  });

  completedSteps = 0;

  constructor(
    public stepperMessageService: MessageService,
    private userService: UserService,
    private utilities: UtilitiesService,
    private router: Router,
    private store: Store,
    private lss: LoginStatusService,
    private us: UserService
  ) {
    this.currentUser = this.utilities.getUserFromService();

    this.signupForm.controls.personalDetails.controls.first_name.setValue(
      this.currentUser.first_name
    );
    this.signupForm.controls.personalDetails.controls.last_name.setValue(
      this.currentUser.last_name
    );
    this.signupForm.controls.personalDetails.controls.email.setValue(
      this.currentUser.contact_details.email
        ? this.currentUser.contact_details.email
        : ""
    );

    // this.us.getUser().subscribe({
    //   next: (userData: any) => {
    //     console.log("userData :: ", userData);
    //   },
    //   error: (error: any) => {
    //     console.log("user not found");
    //   },
    // });
  }

  ngOnInit() {
    this.items = [
      {
        label: "Personal Details",
      },
      {
        label: "Company Setup",
      },
    ];

    if (this.signupForm.controls.personalDetails.valid) {
      this.incrementActiveIndex();
    }

    // this.subscription = this.ticketService.paymentComplete$.subscribe((personalInformation) => {
    //     this.messageService.add({ severity: 'success', summary: 'Order submitted', detail: 'Dear, ' + personalInformation.first_name + ' ' + personalInformation.last_name + ' your order completed.' });
    // });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onActiveIndexChange(event: number) {
    this.stepperMessageService.add;
    this.activeIndex = event;
  }

  next() {
    if (this.activeIndex !== 1) {
      if (this.signupForm.controls.personalDetails.valid) {
        console.log("personalDetails section is valid");
        if (this.userHasBasicDetails()) {
          this.updateUser();
        } else {
          this.createUser();
        }
        console.log("activeIndex after next :: ", this.activeIndex);
      } else {
        console.log("personalDetails section is invalid");
      }
    } else {
      if (this.signupForm.controls.companyDetails.valid) {
        this.createUserCompany();
      }
    }
  }

  previous() {
    this.decrementActiveIndex();
  }

  getUserPayload(): User {
    const user = this.utilities.getUserFromService();
    const payload: User = {
      ...this.currentUser,
      first_name:
        this.signupForm.controls.personalDetails.controls.first_name
          .getRawValue()
          ?.toString() || "",
      last_name:
        this.signupForm.controls.personalDetails.controls.last_name
          .getRawValue()
          ?.toString() || "",
      contact_details: {
        email: this.signupForm.controls.personalDetails.controls.email
          .getRawValue()
          ?.toString(),
        mobile_number: user.contact_details.mobile_number,
      },
    };

    return payload;
  }

  userHasBasicDetails(): boolean {
    if (!this.createUser) return false;

    return (
      this.currentUser.first_name !== "" && this.currentUser.last_name !== ""
    );
  }

  createUser() {
    this.userService.createUser(this.getUserPayload()).subscribe({
      next: (response: any) => {
        console.log("response for updateUser :: ", response);
        this.store.dispatch(UserActions.setUser(this.getUserPayload()));
        this.utilities.setUserInService(this.getUserPayload());
        this.incrementActiveIndex();
      },
    });
  }

  updateUser() {
    this.userService.updateUser(this.getUserPayload()).subscribe({
      next: (response: any) => {
        console.log("response for updateUser :: ", response);
        this.store.dispatch(UserActions.setUser(this.getUserPayload()));
        this.utilities.setUserInService(this.getUserPayload());
        this.incrementActiveIndex();
      },
    });
  }

  createUserCompany() {
    const company = this.signupForm.controls.companyDetails.value;
    const payload: Omit<Company, "id"> = {
      isDefault: true,
      company_name: company.company_name ? company.company_name : "",
      brand_name: company.brand_name || "",
      gstin: company.gstin || "",
    };

    this.userService.createCompanyForUser(payload).subscribe({
      next: (response: any) => {
        const userData: User = {
          id: this.currentUser.id,
          first_name: this.currentUser.first_name,
          last_name: this.currentUser.last_name,
          isLoggedIn: true,
          companyId: response.data.id,
          companyName: payload.company_name,
          contact_details: {
            mobile_number: {
              dial_code:
                this.currentUser.contact_details.mobile_number.dial_code,
              value: this.currentUser.contact_details.mobile_number.value,
            },
          },
          role: "admin",
        };

        this.store.dispatch(UserActions.setUser(userData));

        const defaultCompany: Company = {
          id: response.data.id,
          ...payload,
        };

        this.utilities.setCompaniesInService([defaultCompany]);
        this.utilities.setCurrentCompany(defaultCompany);
        this.store.dispatch(
          CompanyActions.setCompanies({
            companies: [defaultCompany],
            currentCompany: defaultCompany,
          })
        );

        this.lss.sendMessage(true);
        this.router.navigate(["sales"]);
      },
      error: (error) => {
        console.log("createCompanyForUser error :: ", error);
      },
    });
  }

  incrementActiveIndex() {
    this.activeIndex += 1;
    this.completed = this.activeIndex;
  }

  decrementActiveIndex() {
    this.activeIndex -= 1;
    this.completed = this.activeIndex;
  }
}
