export const endpoints = {
  USERS: "/users",
  COMPANIES: "/companies",
  INVENTORY: "/inventory",
  CUSTOMERS: "/customers",
  PARTIES: "/parties",
  INVOICES: "/invoices",
  CATEGORIES: "/categories",
  TRANSACTIONS: "/transactions",
  STOCK_FLOW: "/stockflow",
  LEDGER: "/ledger",
  CHALLAN: "/challan",
  DASHBOARD: "/dashboard",
};
