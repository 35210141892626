<p-sidebar
  [(visible)]="isVisible"
  position="right"
  styleClass="w-40rem"
  transitionOptions="400ms cubic-bezier(0, 0, 0.2, 1)"
  (onHide)="close()"
>
  <ng-template pTemplate="header">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.customer">
        <h5>New Customer</h5>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.stockIn">
        <h5>Stock In</h5>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.stockout">
        <h5>Stock Out</h5>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.inventory">
        <h5>New Inventory Item</h5>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.address">
        <h5>{{ isAddressForEdit() ? "Edit" : "New" }} Address</h5>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.customer">
        <app-create-party [isInSidePanel]="true"></app-create-party>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.stockIn">
        <app-stockflow
          [stockFlowType]="STOCKFLOW_TYPES.StockIn"
          [sidePanelContent]="sidePanelData"
        ></app-stockflow>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.stockout">
        <app-stockflow
          [stockFlowType]="STOCKFLOW_TYPES.StockOut"
          [sidePanelContent]="sidePanelData"
        ></app-stockflow>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.inventory">
        <app-create-product [isInSidePanel]="true"></app-create-product>
      </ng-container>
      <ng-container *ngSwitchCase="CREATION_SIDEPANEL_DATA_TYPE.address">
        <app-create-update-address
          [panelData]="sidePanelData"
        ></app-create-update-address>
      </ng-container>
    </ng-container>
  </ng-template>
  <!-- <ng-template pTemplate="footer">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="'customer'">
        <p-button
          label="Create Customer"
          styleClass="p-button-sm"
          (click)="createCustomer()"
        ></p-button>
      </ng-container>
    </ng-container>
  </ng-template> -->
</p-sidebar>
