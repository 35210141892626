import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { endpoints } from "./endpoints.ts.service";
import { UtilitiesService } from "./utilities.service";
import { LEDGER_TYPES } from "../constants/constants";

export type LedgerFilters = {
  invoiceId?: string;
  partyId?: string;
  invoiceItemId?: string;
  type?: string;
};

@Injectable({
  providedIn: "root",
})
export class LedgerService {
  constructor(
    private httpClient: HttpClient,
    private utilities: UtilitiesService
  ) {}

  getLedgerByCompany() {
    const headers = new HttpHeaders().set(
      "company_id",
      this.utilities.getCurrentCompany().id
    );

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.COMPANIES}${endpoints.LEDGER}`,
      {
        headers,
      }
    );
  }

  getFilteredLedger(filters: LedgerFilters) {
    const headers = new HttpHeaders().set(
      "company_id",
      this.utilities.getCurrentCompany().id
    );

    let queryParams = new HttpParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value && value !== "") {
        queryParams = queryParams.append(key, value);
      }
    });

    return this.httpClient.get(`${environment.apiUrl}${endpoints.LEDGER}`, {
      headers,
      params: queryParams,
    });
  }

  getLedgerItemIcon(ledgerType: LEDGER_TYPES): string {
    let icon = "";

    switch (ledgerType) {
      case LEDGER_TYPES.Payment:
        icon = "payment";
        break;
      case LEDGER_TYPES.Refund:
        icon = "refund";
        break;
      case LEDGER_TYPES.Invoice:
        icon = "invoice";
        break;
      case LEDGER_TYPES.StockIn:
        icon = "inventory_stock_in";
        break;
      case LEDGER_TYPES.StockOut:
        icon = "inventory_stock_out";
        break;
      default:
        break;
    }

    return icon;
  }
}
