import { Component, OnInit } from "@angular/core";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import { ProductService } from "src/app/core/services/product.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";

interface Column {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
}

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit {
  parseFloat = parseFloat;

  cols: Array<Column> = [];

  products: Array<Inventory_Item> = [];

  companyId = "";

  lowStockItems: number = 0;

  positiveStockItems: number = 0;

  readonly lowStockThreshold = 10;

  stockValueSalesPrice = "";

  stockValuePurchasePrice = "";

  constructor(private ps: ProductService, private utilities: UtilitiesService) {
    this.companyId = this.utilities.getUserFromService().companyId;

    this.cols = [
      { field: "name", header: "Name", width: "30%", showAs: "" },
      { field: "stock_quantity", header: "Quantity", showAs: "" },
      { field: "selling_price", header: "Selling Price", showAs: "currency" },
      { field: "purchase_price", header: "Purchase Price", showAs: "currency" },
      { field: "delete", header: "", showAs: "action" },
    ];
  }

  ngOnInit(): void {
    this.ps.getProductsByCompany(this.companyId).subscribe({
      next: (response: any) => {
        this.products = response.data;
        this.calculateStats();
      },
    });
  }

  calculateStats() {
    let stockSalesPrice: any = 0;
    let stockPurchasePrice: any = 0;

    this.products.forEach((product) => {
      if (product.stock_quantity) {
        if (product.stock_quantity > this.lowStockThreshold) {
          this.positiveStockItems += 1;
        } else {
          this.lowStockItems += 1;
        }

        stockSalesPrice =
          stockSalesPrice + product.selling_price * product.stock_quantity;
        stockPurchasePrice =
          stockPurchasePrice +
          product.purchase_price_w_tax * product.stock_quantity;

        this.stockValueSalesPrice = Intl.NumberFormat(navigator.language, {
          notation: "compact",
        }).format(stockSalesPrice);

        this.stockValuePurchasePrice = Intl.NumberFormat(navigator.language, {
          notation: "compact",
        }).format(stockPurchasePrice);
      }
    });
  }

  viewProduct(product: Inventory_Item) {}

  deleteProduct(product: Inventory_Item) {}
}
