import { Component, OnInit } from "@angular/core";
import { LedgerService } from "src/app/core/services/ledger.service";
import { Subscription } from "rxjs";
import { Ledger } from "src/app/core/models/ledger.model";
import {
  DASHBOARD_PERIODS,
  LEDGER_TYPES,
} from "src/app/core/constants/constants";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { UserService } from "src/app/core/services/user.service";
import { Dashboard } from "src/app/core/models/dashboard.model";
import Chart from "chart.js/auto";
import { ChartItem, registerables, ChartOptions } from "chart.js";
import { T_STRING_DD, T_NUMERIC_DD } from "src/app/core/models/shared.model";
import { get } from "lodash";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  LEDGER_TYPES = LEDGER_TYPES;

  math = Math;

  _ = get;

  ledgerSubscription!: Subscription;

  ledger!: Array<Ledger>;

  dashboardData!: Dashboard;

  chartData: Array<number> = [];

  chartLabels: Array<string> = [];

  chart!: any;

  readonly CHART_COLORS = [
    "rgb(255, 205, 86)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
  ];

  periods = this.utilities.convertStringEnumToMapList(DASHBOARD_PERIODS);

  selectedPeriod!: string;

  chartDetails = [
    {
      field: "sales.total_amount",
      label: "Sales",
    },
    {
      field: "inventory.purchase_stock_value",
      label: "Inventory Stock Value",
    },
  ];

  isContentLoaded = false;

  constructor(
    private lds: LedgerService,
    private utilities: UtilitiesService,
    private us: UserService
  ) {
    // register chartjs registerables
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.getDashboardDetails();
  }

  getDashboardDetails(period?: DASHBOARD_PERIODS) {
    this.isContentLoaded = false;
    this.us.getDashboardInfo(period).subscribe({
      next: (response: any) => {
        console.log("dashboard response :: ", response);
        this.dashboardData = response.data;
        this.chartData = [];
        this.chartLabels = [];
        this.chartDetails.forEach((detail: any) => {
          if (
            this._(this.dashboardData, detail.field) !== undefined &&
            this._(this.dashboardData, detail.field) > 0
          ) {
            this.chartData.push(this._(this.dashboardData, detail.field));
            this.chartLabels.push(detail.label);
          }
        });

        this.isContentLoaded = true;

        setTimeout(() => {
          if (response.data) {
            this.renderChart();
          }
        }, 250);
      },
    });
    this.ledgerSubscription = this.lds.getLedgerByCompany().subscribe({
      next: (response: any) => {
        console.log("ledger by company response :: ", response);
        this.ledger = response.data
          .sort((n1: any, n2: any) => {
            const epochA = n1["date"] || 0;
            const epochB = n2["date"] || 0;

            return epochB - epochA;
          })
          .splice(0, 10);
      },
      error: (error: any) => {
        console.log("ledger by company error :: ", error);
      },
    });
  }

  getSeverity(status: string) {
    switch (status) {
      case "INSTOCK":
        return "success";
      case "LOWSTOCK":
        return "warning";
      case "OUTOFSTOCK":
        return "danger";
      default:
        return "gray";
    }
  }

  getDateFromEpoch = (epoch: number) =>
    this.utilities.epochToDate(epoch, "dd MMM yyyy");

  renderChart() {
    var chartDom = document.getElementById("myChart") as ChartItem;
    var bgColors: Array<string> = [];

    this.chartData.forEach((data, index) => {
      if (data > 0) {
        bgColors.push(this.CHART_COLORS[index]);
      }
    });

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(chartDom, {
      type: "doughnut",
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.chartData,
            backgroundColor: bgColors,
            borderRadius: 6,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        responsive: true,
        cutout: "60%",
        aspectRatio: 2,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              font: {
                family: "Ubuntu",
                size: 14,
              },
              boxHeight: 20,
              boxWidth: 20,
              useBorderRadius: true,
              borderRadius: 8,
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }
                if (context.parsed !== null) {
                  label += new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "INR",
                  }).format(context.parsed);
                }
                return label;
              },
            },
            bodyFont: {
              family: "Ubuntu",
              size: 12,
            },
            titleFont: {
              family: "Ubuntu",
              size: 14,
            },
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 16,
          },
        },
      },
    });

    this.chart.update();
  }

  calculateTrendPercentage(data: any) {
    if (
      data.count === undefined ||
      data.count === 0 ||
      data.previous_period_count === undefined ||
      data.previous_period_count === 0
    )
      return "";

    const trendAmount = (100 * data.count) / data.previous_period_count;

    return trendAmount - 100;
  }

  periodChanged(event: any) {
    console.log(event);
    this.getDashboardDetails(event.value.value);
  }
}
