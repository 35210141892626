<p-confirmDialog
  #cem
  [style]="{ 'min-width': '25vw' }"
  key="confirm-exit"
  styleClass="exit-confirmation-modal"
>
  <ng-template pTemplate="icon"> </ng-template>
  <ng-template pTemplate="message">
    <p>Message Template</p>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      label="Cancel"
      styleClass="p-button-sm p-button-secondary"
      (click)="cem.reject()"
    ></p-button>
    <p-button
      label="Exit"
      styleClass="p-button-sm p-button-danger"
      (click)="cem.accept()"
    ></p-button>
  </ng-template>
</p-confirmDialog>
