import { Component, Input, OnInit } from "@angular/core";

interface CheckBoxOption {
  value: string;
  label: string;
}

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input() id!: string;

  @Input() label!: string;

  @Input() options: Array<CheckBoxOption> = [];

  showLabel: boolean = false;

  ngOnInit(): void {
    if (this.label && this.label.length) {
      this.showLabel = true;
    }
  }
}
