import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UserService } from "src/app/core/services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Table } from "primeng/table";
import {
  SidePanelData,
  SlidepanelService,
} from "src/app/core/services/slidepanel.service";
import Customer from "src/app/core/models/party.model";
import { PARTY_TYPE } from "src/app/core/constants/constants";
import Party from "src/app/core/models/party.model";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { MenuItem } from "primeng/api";
import { ContextMenu } from "primeng/contextmenu";
import { CustomerService } from "src/app/core/services/customer.service";
import {
  ToastMessage,
  ToastService,
} from "src/app/core/services/toast.service";
import { ConfirmDeleteModalComponent } from "src/app/shared/confirm-delete-modal/confirm-delete-modal.component";

interface Column {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
}

@Component({
  selector: "app-parties",
  templateUrl: "./parties.component.html",
  styleUrls: ["./parties.component.scss"],
})
export class PartiesComponent implements OnInit {
  parties: Array<Party> = [];

  partiesCopy: Array<Party> = [];

  isContentLoaded = false;

  @ViewChild("customerTable") customerTable!: Table;

  sidePanelData: SidePanelData = {
    openStatus: false,
    dataType: "party",
  };

  readonly tabToTypeMap = [
    {
      index: 0,
      tabLabel: "All",
      status: null,
      noDataMessage: "No invoices found",
    },
    {
      index: 1,
      tabLabel: "Customers",
      type: PARTY_TYPE.Customer,
      noDataMessage: "You have not added any customers.",
    },
    {
      index: 1,
      tabLabel: "Vendors",
      type: PARTY_TYPE.Vendor,
      noDataMessage: "You have not added any vendors.",
    },
  ];

  activeIndex = 0;

  partyIdToOpen!: string;

  @ViewChild("contextMenu", { static: false }) contextMenu!: ContextMenu;

  contextMenuItems: MenuItem[] = [];

  constructor(
    private us: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sps: SlidepanelService,
    private utilities: UtilitiesService,
    private cs: CustomerService,
    private ts: ToastService,
    private cdm: ConfirmDeleteModalComponent
  ) {
    this.partyIdToOpen =
      this.router.getCurrentNavigation()?.extras?.state?.["partyId"];
  }

  ngOnInit(): void {
    this.getParties();
  }

  getParties() {
    this.us.getPartiesOfUser().subscribe({
      next: (response: any) => {
        this.parties =
          response.data && response.data.length ? response.data : [];
        this.partiesCopy = this.utilities.cloneDeep(this.parties);
        this.isContentLoaded = true;

        if (this.partyIdToOpen && this.partyIdToOpen !== "") {
          const party = this.parties.find(
            (party) => party.id === this.partyIdToOpen
          );
          this.partyIdToOpen = "";
          this.viewCustomer(party!);
        }
      },
      error: (error: any) => {
        console.log("getPartiesError :: ", error);
        if (error.status === 404) {
          this.parties = [];
          this.isContentLoaded = true;
        }
      },
    });
  }

  createNewCustomer() {
    this.router.navigate(["new"], { relativeTo: this.route });
  }

  searchInTable(event: any) {
    console.log("search input :: ", event.target.value);
    this.customerTable.filterGlobal(event.target.value, "contains");
  }

  clear(table: Table) {
    table.clear();
  }

  viewCustomer(customer: Customer) {
    if (!customer) return;

    this.sidePanelData.party = customer;
    this.sidePanelData.openStatus = true;
    this.sps.openSlidePanel(this.sidePanelData);
  }

  getPartiesOnTabChange(tabIndex: number) {
    const partyType = this.tabToTypeMap.find(
      (el) => el.index === tabIndex
    )?.type;

    this.parties = this.partiesCopy.filter((el) => el.type === partyType);
  }

  openContextMenu(event: MouseEvent, party: Party): void {
    this.contextMenuItems = [
      {
        label: "Edit Party",
        icon: "pi pi-pencil",
        command: (event) => {
          this.router.navigate(["new"], {
            relativeTo: this.route,
            state: { party },
          });
        },
      },
      {
        label: "Delete Party",
        icon: "pi pi-times-circle",
        styleClass: "danger",
        command: (event) => {
          this.deleteParty(party.id!);
        },
      },
    ];
    this.contextMenu.toggle(event);
    event.stopPropagation();
  }

  deleteParty(partyId: string) {
    this.cdm.confirm().subscribe((isConfirmed) => {
      console.log("isConfirmed :: ", isConfirmed);
      if (!isConfirmed) return;

      this.cs.deleteParty(partyId).subscribe({
        next: (response: any) => {
          const toastMessage: ToastMessage = {
            show: true,
            severity: "success",
            summary: "Success",
            detail: "Party deleted.",
          };
          this.ts.sendMessage(toastMessage);
          this.isContentLoaded = false;
          this.getParties();
        },
      });
    });
  }
}
