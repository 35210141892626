<div
  class="flex align-items-center justify-content-space-between mb-3"
  id="dashboard-title"
>
  <h4>Dashboard</h4>

  <p-dropdown
    [options]="periods"
    [(ngModel)]="selectedPeriod"
    optionLabel="label"
    (onChange)="periodChanged($event)"
  ></p-dropdown>
</div>

<div class="skeleton-layout" *ngIf="!isContentLoaded">
  <div class="grid gap-3 mb-3" style="grid-template-columns: repeat(3, 1fr)">
    <ng-container *ngFor="let number of [0, 1, 2]">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          'height': '96px',
          'border-radius': '8px',
          'margin': '0',
          'background': '#e5e5e5'
        }"
      ></ngx-skeleton-loader>
    </ng-container>
  </div>
  <div class="grid gap-3" style="grid-template-columns: repeat(2, 1fr)">
    <ng-container *ngFor="let number of [0, 1]">
      <div class="flex flex-column gap-3">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            'height': '50vh',
            'border-radius': '8px',
            'margin': '0',
            'background': '#e5e5e5'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            'height': '96px',
            'border-radius': '8px',
            'margin': '0',
            'background': '#e5e5e5'
          }"
        ></ngx-skeleton-loader>
      </div>
    </ng-container>
  </div>
</div>

<div
  id="dashboard-wrapper"
  class="flex flex-column gap-3"
  *ngIf="isContentLoaded"
>
  <div id="stats-cards-wrapper" class="grid gap-3">
    <div class="stats-card p-3" id="sales">
      <div class="flex justify-content-space-between">
        <div>
          <div class="mb-2 title">invoices</div>
          <h4>{{ dashboardData.sales.count }}</h4>
          <!-- <small>{{ calculateTrendPercentage(dashboardData.challans) }}</small> -->
        </div>
        <div class="p-1 icon">
          <app-icon icon="invoice" color="success"></app-icon>
        </div>
      </div>
    </div>
    <div class="stats-card p-3" id="purchases">
      <div class="flex justify-content-space-between">
        <div>
          <div class="mb-2 title">products / services</div>
          <h4>{{ dashboardData.inventory.count }}</h4>
        </div>
        <div class="p-1 icon">
          <app-icon icon="inventory" color="warning"></app-icon>
        </div>
      </div>
    </div>
    <div class="stats-card p-3" id="inventory">
      <div class="flex justify-content-space-between">
        <div>
          <div class="mb-2 title">parties</div>
          <h6>count_needed</h6>
        </div>
        <div class="p-1 icon">
          <app-icon icon="parties" color="info"></app-icon>
        </div>
      </div>
    </div>
  </div>
  <div id="other-stats" class="flex gap-3">
    <div id="left-column" class="flex flex-column gap-3">
      <div id="recent-transactions-card">
        <div>
          <h6 class="dashboard-card-title">Recent Transactions</h6>
        </div>
        <canvas id="myChart" style="width: 480px"></canvas>
      </div>
      <div id="business-status" class="p-3 flex flex-column gap-3">
        <div>
          <h6 class="dashboard-card-title">see how your business is doing</h6>
        </div>
        <div class="grid" id="stats-wrapper">
          <div class="b-stats-card" id="invoices-stat">
            <div class="p-2 icon-container">
              <app-icon icon="invoice" color="primary"></app-icon>
            </div>
            <div class="stat-details-wrapper flex flex-column">
              <div class="flex gap-1 align-items-center">
                <h4>{{ dashboardData.sales.outstanding_invoices_count }}</h4>
                <span>invoices</span>
              </div>
              <span class="label">Unpaid</span>
            </div>
          </div>
          <div class="b-stats-card" id="inventory-stat">
            <div class="p-2 icon-container">
              <app-icon icon="inventory" color="warning"></app-icon>
            </div>
            <div class="stat-details-wrapper flex flex-column">
              <div class="flex gap-1 align-items-center">
                <h4>{{ dashboardData.inventory.low_stock_item_count }}</h4>
                <span>items</span>
              </div>
              <span class="label">have low stock</span>
            </div>
          </div>
          <div class="b-stats-card" id="you-owe-stat">
            <div class="p-2 icon-container">
              <app-icon icon="refund" color="error"></app-icon>
            </div>
            <div class="stat-details-wrapper flex flex-column">
              <div class="flex gap-1 align-items-center">
                <h4
                  appCurrencyFormat
                  [appCurrencyFormat]="
                    math.abs(dashboardData.parties.amount_owed!).toFixed(2)
                  "
                ></h4>
              </div>
              <span class="label">you owe</span>
            </div>
          </div>
          <div class="b-stats-card" id="you-get-stat">
            <div class="p-2 icon-container">
              <app-icon icon="payment" color="success"></app-icon>
            </div>
            <div class="stat-details-wrapper flex flex-column">
              <div class="flex gap-1 align-items-center">
                <h4
                  appCurrencyFormat
                  [appCurrencyFormat]="
                    math.abs(dashboardData.parties.amount_pending!).toFixed(2)
                  "
                ></h4>
              </div>
              <span class="label">you get</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="right-column" class="flex flex-1 flex-column gap-3">
      <div id="recent-activity-card">
        <div>
          <h6 class="dashboard-card-title">Recent Activity</h6>
          <div class="flex flex-column mt-3">
            <ng-container *ngFor="let ledgerItem of ledger; let last = last">
              <div class="ledger-item">
                <div class="flex gap-1 align-items-center">
                  <div>
                    <div
                      [ngSwitch]="ledgerItem.type"
                      style="margin: auto; width: fit-content"
                    >
                      <app-icon
                        *ngSwitchCase="LEDGER_TYPES.StockIn"
                        [icon]="'inventory_stock_in'"
                        [color]="'success'"
                      ></app-icon>
                      <app-icon
                        *ngSwitchCase="LEDGER_TYPES.StockOut"
                        [icon]="'inventory_stock_out'"
                        [color]="'warning'"
                      ></app-icon>
                      <app-icon
                        *ngSwitchCase="LEDGER_TYPES.Payment"
                        [icon]="'payment'"
                        [color]="'success'"
                      ></app-icon>
                      <app-icon
                        *ngSwitchCase="LEDGER_TYPES.Invoice"
                        [icon]="'invoice'"
                        [color]="'info'"
                      ></app-icon>
                      <app-icon
                        *ngSwitchCase="LEDGER_TYPES.Challan"
                        [icon]="'slip'"
                        [color]="'primary'"
                      ></app-icon>
                    </div>
                  </div>
                  <div class="flex flex-column">
                    <div class="ledger-type">
                      {{ LEDGER_TYPES[ledgerItem.type] }}
                    </div>
                    <div class="ledger-item-title">{{ ledgerItem.title }}</div>
                  </div>
                </div>
                <div>{{ getDateFromEpoch(ledgerItem.date) }}</div>
              </div>
              <p-divider *ngIf="!last"></p-divider>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
