export enum PAYMENT_TRANSACTION_TYPE {
  Payment = 0,
  Refund = 1,
}

export const PAYMENT_METHOD_OPTIONS = [
  { label: "Cash", value: 0, background: "var(--success-green)" },
  { label: "Card", value: 1, background: "var(--brand-accent)" },
  { label: "Cheque", value: 2, background: "var(--warning-yellow)" },
  { label: "UPI", value: 3, background: "var(--success-green)" },
];

export interface PaymentTransaction {
  id?: string;

  amount: number;

  payment_method: string;

  receipt_number?: string;

  record_date: number;

  type: PAYMENT_TRANSACTION_TYPE;

  remarks?: string;
}

export function getPaymentMethodLabel(payment_method: number): string {
  const method = PAYMENT_METHOD_OPTIONS.find(
    (ele) => ele.value === payment_method
  );

  return method ? method.label : "Invalid Payment Method";
}

export function getPaymentTagBackground(payment_method: number): string {
  const method = PAYMENT_METHOD_OPTIONS.find(
    (ele) => ele.value === payment_method
  );

  return method ? method.background : "var(--neutral-900)";
}
