<div class="w-100">
  <div class="input-group">
    <label for="productName">Product</label>
    <div class="readonly">
      <span>{{ product.name }}</span>
    </div>
  </div>
</div>
<!-- -------------
{{ sidePanelContent | json }}
------------- -->
<form
  [formGroup]="stockFlowForm"
  (ngSubmit)="updateStock()"
  class="flex flex-column flex-1 justify-content-space-between"
>
  <div id="stock-flow-wrapper" class="flex flex-column gap-3 mt-3">
    <div class="flex gap-3">
      <div class="flex-1">
        <div class="input-group">
          <label for="productName">Quantity</label>
          <div class="p-inputgroup">
            <p-inputNumber inputId="quantity" formControlName="quantity">
            </p-inputNumber>
            <span class="p-inputgroup-addon">{{ product.unit }}</span>
          </div>
        </div>
        <!-- <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="newParty.controls.first_name"
        >
        </app-field-error> -->
      </div>
      <div class="flex-1">
        <div class="input-group">
          <label for="productName">{{
            stockFlowType === STOCKFLOW_TYPES.StockIn
              ? "Purchase Price"
              : "selling price"
          }}</label>
          <div class="p-inputgroup">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">&#x20B9;</span>
              <p-inputNumber
                formControlName="price"
                inputId="selling_price"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
              >
              </p-inputNumber>
            </div>
          </div>
        </div>
        <!-- <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="newParty.controls.first_name"
        >
        </app-field-error> -->
      </div>
    </div>
    <div class="flex gap-3">
      <div class="input-group flex-1">
        <label for="unit">Record Date</label>
        <p-calendar
          [showIcon]="true"
          dateFormat="dd-mm-yy"
          formControlName="transaction_date"
        ></p-calendar>
        <!-- <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="createInvoiceForm.controls.invoice_date"
        >
        </app-field-error> -->
      </div>
      <div class="flex-1"></div>
    </div>
    <div class="flex">
      <div class="input-group w-100">
        <label for="notes">notes</label>
        <textarea id="notes" rows="5" pInputTextarea></textarea>
      </div>
    </div>
  </div>
  <div id="action-buttons-wrapper" class="flex">
    <!-- <p-button
      label="Discard"
      styleClass="p-button-secondary"
      (click)="backToCustomerListing()"
    ></p-button> -->
    <p-button
      [label]="
        stockFlowType === STOCKFLOW_TYPES.StockIn ? 'Stock In' : 'Stock Out'
      "
      type="submit"
    ></p-button>
  </div>
</form>
