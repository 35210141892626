import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import User from "src/app/core/models/user.model";
import { selectUser } from "src/app/core/state_reducers/user.reducer";
import { selectCompanies } from "src/app/core/state_reducers/company.reducer";
import { AuthService } from "src/app/core/services/auth.service";
import { Company } from "src/app/core/models/company.model";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/app/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  user!: User;

  companies: Array<Company> = [];

  currentCompany!: Company;

  readonly appVersion = environment.appVersion;

  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectUser)).subscribe((userData) => {
      console.log("userData in header :: ", userData);
      this.user = userData;
    });

    this.store.pipe(select(selectCompanies)).subscribe((companiesData) => {
      this.companies = companiesData;
    });
  }

  openSettings() {
    this.router.navigate(["/settings"]);
  }

  doLogout() {
    this.authService.performLogout();
  }
}
