<div class="section-header-wrapper pb-1 mb-3" *ngIf="!isInSidePanel">
  <div class="flex justify-content-space-between align-items-center">
    <p class="heading">
      {{ !isEditMode ? "New Inventory Item" : "Edit Inventory Item" }}
    </p>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-sm p-button-secondary p-button-text"
      (click)="backToProductListing()"
    ></p-button>
  </div>
</div>
<form
  [formGroup]="newProduct"
  (ngSubmit)="createUpdateProduct($event)"
  appInvalidControlScroll
>
  <div id="new-product-wrapper" class="flex flex-column gap-4">
    <section>
      <p class="title">Basic Details</p>
      <div class="input-group">
        <label for="productType">Choose item type</label>
        <p-selectButton
          [options]="productTypeOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="type"
          (onChange)="itemTypeChanged($event)"
        ></p-selectButton>
        <app-field-error
          [isFormSubmitted]="isSubmitted"
          [fieldControl]="newProduct.controls.type"
        >
        </app-field-error>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <label for="productName">Item name</label>
            <input pInputText maxlength="250" formControlName="name" />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.name"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label for="unit">Primary Unit</label>
            <p-dropdown
              name="unit"
              [options]="units"
              optionLabel="name"
              optionValue="name"
              formControlName="unit"
              placeholder="Choose a unit for the item"
              name="unit"
            ></p-dropdown>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.unit"
          >
          </app-field-error>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <label class="input-label">selling price</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">&#x20B9;</span>
              <p-inputNumber
                formControlName="selling_price"
                inputId="selling_price"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
              >
              </p-inputNumber>
              <span class="p-inputgroup-addon" style="width: 8rem">
                <p-dropdown
                  [options]="taxation"
                  optionLabel="label"
                  styleClass="dropdown-addon"
                  (onChange)="recalculatePrice($event, 'selling_price')"
                ></p-dropdown>
              </span>
            </div>
            <app-field-error
              [isFormSubmitted]="isSubmitted"
              [fieldControl]="newProduct.controls.selling_price"
            >
            </app-field-error>
          </div>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label class="input-label">tax %</label>
            <p-dropdown
              [options]="taxPercentages"
              optionLabel="label"
              optionValue="value"
              placeholder="Choose a tax percentage"
              formControlName="tax_rate"
            ></p-dropdown>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.tax_rate"
          >
          </app-field-error>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label class="input-label">item code</label>
              <span class="optional">(optional)</span>
            </div>
            <input
              pInputText
              alphaNumeric
              maxlength="20"
              formControlName="item_code"
              oninput="this.value = this.value.toUpperCase()"
            />
          </div>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label class="input-label">weight per unit</label>
            <div class="p-inputgroup">
              <p-inputNumber
                formControlName="weight"
                inputId="weight_per_unit"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                [useGrouping]="false"
              >
              </p-inputNumber>
              <span class="p-inputgroup-addon" style="width: 8rem">
                <p-dropdown
                  [options]="item_weight_units"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="dropdown-addon"
                  formControlName="weight_unit"
                  appendTo="body"
                ></p-dropdown>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="section-divider" />
    <section>
      <p class="title">Additional Information</p>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label for="hsn_sac">HSN / SAC code</label>
              <span class="optional">(optional)</span>
            </div>
            <!-- <p-inputNumber
              formControlName="hsn_sac"
              [useGrouping]="false"
              [max]="999999999999"
              id="hsn_sac"
            ></p-inputNumber> -->
            <input
              pInputText
              alphaNumeric
              maxlength="30"
              placeholder="Enter your mobile number"
              formControlName="hsn_sac"
            />
          </div>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label for="unit">purchase price</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">&#x20B9;</span>
              <p-inputNumber
                ngDefaultControl
                formControlName="purchase_price"
                inputId="purchase_price"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                (onBlur)="calculateTaxAmount()"
              ></p-inputNumber>
              <span class="p-inputgroup-addon">
                <p-dropdown
                  [options]="taxation"
                  optionLabel="label"
                  styleClass="dropdown-addon"
                  (onChange)="recalculatePurchasePrice($event)"
                ></p-dropdown>
              </span>
            </div>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.purchase_price"
          >
          </app-field-error>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label for="barcode">barcode</label>
              <span class="optional">(optional)</span>
            </div>
            <input
              pInputText
              alphaNumeric
              maxlength="250"
              formControlName="barcode"
              id="barcode"
            />
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.barcode"
          >
          </app-field-error>
        </div>
        <div class="flex-1">
          <div class="input-group">
            <label for="unit">category</label>
            <p-autoComplete
              class="w-100"
              formControlName="category"
              [dropdown]="true"
              [suggestions]="filteredCategories"
              (completeMethod)="filterProductCategories($event)"
            ></p-autoComplete>
          </div>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newProduct.controls.category"
          >
          </app-field-error>
        </div>
      </div>
      <div class="flex gap-3">
        <div class="flex-1">
          <div class="input-group">
            <div class="flex gap-1">
              <label for="profit_percent">minimum profit %</label>
              <span class="optional">(optional)</span>
            </div>
            <p-dropdown
              [options]="profitPercentages"
              optionLabel="name"
              optionValue="code"
              placeholder="Choose a tax percentage"
              [showClear]="true"
              formControlName="min_profit_percent"
              id="profit_percent"
            ></p-dropdown>
          </div>
        </div>
        <div class="flex-1"></div>
      </div>
      <div class="flex">
        <div class="input-group w-100">
          <div class="flex gap-1">
            <label for="productDescription">product description</label>
            <span class="optional">(optional)</span>
          </div>
          <p-editor
            [style]="{ height: '120px' }"
            formControlName="description"
            (onTextChange)="onEditorTextChange($event)"
            [editorMaxChars]="EDITOR_MAX_CHARS"
          >
          </p-editor>
          <small
            ><i>{{ remainingEditorChars }} characters remaining</i></small
          >
        </div>
      </div>
    </section>
    <ng-container
      *ngIf="
        newProduct.controls.type.getRawValue() === INVENTORY_ITEM_TYPE.Product
      "
    >
      <hr class="section-divider" />
      <section>
        <p class="title">Stock details</p>
        <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <div class="flex gap-1">
                <label for="stock_quantity">opening quantity</label>
                <span
                  class="optional"
                  *ngIf="newProduct.get('type')?.getRawValue()"
                  >(optional)</span
                >
              </div>
              <div class="p-inputgroup">
                <p-inputNumber
                  mode="decimal"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  formControlName="stock_quantity"
                  id="stock_quantity"
                >
                </p-inputNumber>
                <span
                  class="p-inputgroup-addon"
                  *ngIf="newProduct.controls.unit.getRawValue() !== ''"
                  >{{ newProduct.controls.unit.getRawValue() }}</span
                >
              </div>
              <app-field-error
                [isFormSubmitted]="isSubmitted"
                [fieldControl]="newProduct.controls.stock_quantity"
              >
              </app-field-error>
            </div>
            <!-- <app-field-error [isFormSubmitted]="isSubmitted" [formControl]="newProduct.controls.stock_quantity">
            </app-field-error> -->
          </div>
          <div class="flex-1">
            <div class="input-group">
              <div class="flex gap-1">
                <label for="lowStockQuantity">low stock quantity</label>
                <span
                  class="optional"
                  *ngIf="newProduct.get('type')?.getRawValue()"
                  >(optional)</span
                >
              </div>
              <div class="p-inputgroup">
                <p-inputNumber
                  id="lowStockQuantity"
                  mode="decimal"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  formControlName="low_stock_quantity"
                >
                </p-inputNumber>
                <span
                  class="p-inputgroup-addon"
                  *ngIf="newProduct.controls.unit.getRawValue() !== ''"
                  >{{ newProduct.controls.unit.getRawValue() }}</span
                >
              </div>
            </div>
            <!-- <app-field-error [isFormSubmitted]="isSubmitted" [formControl]="newProduct.controls.stock_quantity">
            </app-field-error> -->
          </div>
        </div>
        <!-- <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <label for="unit">opening purchase price</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">&#x20B9;</span>
                <p-inputNumber
                  formControlName=""
                  inputId="selling_price"
                  mode="decimal"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                >
                </p-inputNumber>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="input-group">
              <label for="productName">opening stock valuation</label>
              <input pInputText numbersOnly maxlength="16" />
            </div>
          </div>
        </div> -->
      </section>
    </ng-container>
  </div>
  <div id="action-buttons-wrapper" class="flex gap-3 pt-3">
    <p-button
      label="Discard"
      styleClass="p-button-secondary"
      (click)="backToProductListing()"
    ></p-button>
    <p-button
      *ngIf="!isEditMode"
      label="Create Product"
      type="submit"
    ></p-button>
    <p-button
      *ngIf="isEditMode"
      label="Update Product"
      type="submit"
    ></p-button>
  </div>
</form>
