<p-sidebar
  [(visible)]="isVisible"
  position="right"
  [styleClass]="mainSideBarStyleClasses.join(' ')"
  transitionOptions="400ms cubic-bezier(0, 0, 0.2, 1)"
  (onHide)="close()"
>
  <ng-template pTemplate="header">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <div class="title flex gap-min" *ngSwitchCase="'product'">
        <h5>Product Details</h5>
      </div>
      <div
        class="title flex justify-content-space-between w-100 pr-2"
        *ngSwitchCase="'invoice'"
      >
        <div class="flex gap-min">
          <h5>Invoice</h5>
          <h5>#{{ sidePanelData.invoice.invoice_number }}</h5>
        </div>
        <p-chip
          [label]="
            utilities.getInvoiceStatusLabel(sidePanelData.invoice.status)
          "
          [styleClass]="
            utilities
              .getInvoiceStatusLabel(sidePanelData.invoice.status)
              .toLowerCase()
              .replace('/\s/g', '')
          "
        ></p-chip>
      </div>
      <div class="title flex gap-min" *ngSwitchCase="'party'">
        <h5>
          {{ sidePanelData.party?.first_name }}
          {{ sidePanelData.party?.last_name }}
        </h5>
      </div>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <div *ngSwitchCase="'product'">
        <div id="product-details" class="flex flex-column gap-3">
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">Name</label>
              <span class="field-value">{{ sidePanelData.product.name }}</span>
            </div>
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">current stock quantity</label>
              <span class="field-value">{{
                sidePanelData.product.stock_quantity
              }}</span>
            </div>
          </div>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">Selling price</label>
              <span class="field-value"
                >₹
                {{
                  parseFloat(sidePanelData.product.selling_price).toFixed(2)
                }}</span
              >
            </div>
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">purchase price</label>
              <span class="field-value"
                >₹
                {{
                  parseFloat(
                    sidePanelData.product.purchase_price_w_tax
                  ).toFixed(2)
                }}</span
              >
            </div>
          </div>
          <div class="flex gap-3">
            <div class="flex flex-column flex-1 gap-min display-field">
              <label class="field-label">Tax Rate</label>
              <span class="field-value"
                >{{ sidePanelData.product.tax_rate }}%</span
              >
            </div>
          </div>
          <div>
            <!-- <p-tabView>
              <p-tabPanel header="Inventory">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </p-tabPanel>
              <p-tabPanel header="Transactions">
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Consectetur, adipisci velit, sed quia non numquam
                  eius modi.
                </p>
              </p-tabPanel>
            </p-tabView> -->
            <ng-container *ngIf="sidePanelData.product?.stockflow?.length">
              <div id="stockflow">
                <p-accordion [activeIndex]="0">
                  <p-accordionTab header="Stock Flow">
                    <div id="transactions-wrapper">
                      <ng-container
                        *ngFor="
                          let transaction of sidePanelData.product?.stockflow
                            | sortByEpoch : 'transaction_date';
                          let isLast = last
                        "
                      >
                        <div class="flex gap-2 align-items-center">
                          <div class="transaction_type">
                            <!-- <img
                            
                            src="../../../assets/img/payment.svg"
                            alt="payment"
                          /> -->
                            <app-icon
                              icon="inventory_stock_in"
                              color="success"
                              *ngIf="
                                transaction.type === STOCKFLOW_TYPES.StockIn
                              "
                            ></app-icon>
                            <app-icon
                              icon="inventory_stock_out"
                              color="error"
                              *ngIf="
                                transaction.type === STOCKFLOW_TYPES.StockOut
                              "
                            ></app-icon>
                          </div>
                          <div
                            class="transaction-details flex flex-1 align-items-center justify-content-space-between"
                          >
                            <div class="flex flex-column gap-min w-50">
                              <b>{{
                                transaction.type === STOCKFLOW_TYPES.StockIn
                                  ? "Stock In"
                                  : "Stock Out"
                              }}</b>
                              <span>{{
                                utilities.epochToDate(
                                  transaction.transaction_date,
                                  "dd MMM yyyy | hh:mm a"
                                )
                              }}</span>
                            </div>
                            <div
                              class="w-40 flex justify-content-end align-items-end flex-column"
                            >
                              <div class="flex align-items-center gap-1">
                                <span class="stockflow-label">Qty: </span
                                ><b class="stockflow-value"
                                  >&#x20B9;
                                  {{ transaction.quantity?.toFixed(2) }}</b
                                >
                              </div>
                              <div class="flex align-items-center gap-1">
                                <span class="stockflow-label">Amount: </span
                                ><b class="stockflow-value"
                                  >&#x20B9;
                                  {{ transaction.price?.toFixed(2) }}</b
                                >
                              </div>
                            </div>
                            <!-- <p-button
                            label="Receipt"
                            styleClass="p-button-sm p-button-secondary"
                          ></p-button> -->
                          </div>
                        </div>
                        <p-divider *ngIf="!isLast"></p-divider>
                      </ng-container>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'invoice'">
        <div id="invoice-details" class="flex flex-column">
          <div
            id="customer"
            class="flex flex-column justify-content-space-between gap-3"
          >
            <section
              id="payment-banner"
              *ngIf="sidePanelData.invoice.status === 'paid'"
            >
              <div class="flex gap-1 p-2 align-items-center">
                <i
                  class="pi pi-check-circle"
                  style="color: var(--success-green)"
                ></i>
                <span>Invoice paid on ***payment-date***</span>
              </div>
            </section>
            <section class="flex gap-2 w-100">
              <div id="left-column" class="flex flex-column flex-1 gap-1">
                <div class="input-group" id="bill-to">
                  <label>bill to</label>
                  <b>
                    {{ sidePanelData.invoice.party.first_name }}
                    {{ sidePanelData.invoice.party.last_name }}
                  </b>
                  <small
                    *ngIf="sidePanelData.invoice.party.billing_address?.length"
                    >{{ sidePanelData.invoice.party.billing_address[0].line_1 }}
                    <span
                      *ngIf="
                        sidePanelData.invoice.party.billing_address[0].line_1 &&
                        sidePanelData.invoice.party.billing_address[0].line_1
                          .length
                      "
                      >,</span
                    >
                    {{ sidePanelData.invoice.party.billing_address[0].line_2 }}
                    <br />
                    {{
                      sidePanelData.invoice.party.billing_address[0].landmark
                    }}
                    <span
                      *ngIf="
                        sidePanelData.invoice.party.billing_address[0]
                          .landmark &&
                        sidePanelData.invoice.party.billing_address[0].landmark
                          .length
                      "
                      >,</span
                    >
                    {{ sidePanelData.invoice.party.billing_address[0].city }}
                  </small>
                </div>
              </div>
              <div id="right-column" class="flex-1">
                <div class="input-group" id="bill-from">
                  <label>bill from</label>
                  <!-- {{ sidePanelData.company | json }} -->
                  <b>
                    {{ sidePanelData.company.company_name }}
                  </b>
                  <small *ngIf="sidePanelData.company.billing_address?.length"
                    >{{ sidePanelData.company.billing_address[0].line_1 }},
                    {{ sidePanelData.company.billing_address[0].line_2 }}
                    <br />
                    <span
                      *ngIf="
                        sidePanelData.company.billing_address[0].landmark
                          ?.length
                      "
                      >{{
                        sidePanelData.company.billing_address[0].landmark
                      }},</span
                    >
                    {{ sidePanelData.company.billing_address[0].city }}
                  </small>
                </div>
              </div>
            </section>
            <section class="flex gap-2 w-100">
              <div class="flex flex-1">
                <div class="input-group">
                  <label>invoice date</label>
                  <span>{{
                    utilities.epochToDate(
                      sidePanelData.invoice.invoice_date,
                      "dd MMM yyyy"
                    )
                  }}</span>
                </div>
              </div>
              <div class="flex flex-1">
                <div class="input-group">
                  <label>due date</label>
                  <span>{{
                    utilities.epochToDate(
                      sidePanelData.invoice.due_date,
                      "dd MMM yyyy"
                    )
                  }}</span>
                </div>
              </div>
            </section>
          </div>
          <p-divider></p-divider>
          <div id="invoice-items" class="flex flex-column gap-2">
            <b>INVOICE ITEMS</b>
            <div>
              <p-table
                [value]="sidePanelData.invoice.invoice_items"
                [tableStyle]="{ 'min-width': '100%' }"
                styleClass="p-datatable-sm"
                class="w-100"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [resizableColumns]="false"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Item
                      </div>
                    </th>
                    <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Quantity
                      </div>
                    </th>
                    <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Unit Price
                      </div>
                    </th>
                    <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Price with tax
                      </div>
                    </th>
                    <!-- <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Discount on Total
                      </div>
                    </th> -->
                    <th>
                      <div
                        class="flex align-items-center justify-content-space-between"
                      >
                        Net Price
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoiceItem>
                  <tr>
                    <td>
                      <span class="p-column-title">Name</span>
                      {{ invoiceItem.title }}
                    </td>
                    <td>
                      <span class="p-column-title">Quantity</span>
                      {{ invoiceItem.quantity }}
                    </td>
                    <td>
                      <span class="p-column-title">Unit Price</span>
                      <div class="flex flex-column">
                        &#x20B9; {{ invoiceItem.unit_price?.toFixed(2) }}
                      </div>
                    </td>
                    <td>
                      <span class="p-column-title">Price with tax</span>
                      <div class="flex flex-column">
                        &#x20B9; {{ invoiceItem.price_w_tax?.toFixed(2) }}
                      </div>
                    </td>
                    <!-- <td>
                      <span class="p-column-title">Discount on Total</span>
                      <div class="p-inputgroup">
                        INR {{ invoiceItem.discount_amount?.toFixed(2) }}
                      </div>
                    </td> -->
                    <td>
                      <span class="p-column-title">Net Amount</span>
                      &#x20B9; {{ invoiceItem.net_amount?.toFixed(2) }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <div
                id="invoice-total-wrapper"
                class="p-3 flex flex-column gap-1"
              >
                <div
                  class="flex align-items-center justify-content-space-between"
                >
                  <span>Total Taxable Amount:</span>
                  <span
                    >&#x20B9;
                    {{ sidePanelData.invoice.sub_total?.toFixed(2) }}</span
                  >
                </div>
                <div
                  class="flex align-items-center justify-content-space-between"
                >
                  <span>Total Tax Amount:</span>
                  <span
                    >&#x20B9;
                    {{
                      sidePanelData.invoice.total_tax_amount?.toFixed(2)
                    }}</span
                  >
                </div>
                <div
                  class="flex align-items-center justify-content-space-between"
                >
                  <span>Total Discount:</span>
                  <span
                    >&#x20B9;
                    {{
                      sidePanelData.invoice.total_discount_amount?.toFixed(2)
                    }}</span
                  >
                </div>
                <div
                  class="flex align-items-center justify-content-space-between"
                >
                  <h5>Total Amount:</h5>
                  <h5>
                    &#x20B9; {{ sidePanelData.invoice.total?.toFixed(2) }}
                  </h5>
                </div>
                <div
                  *ngIf="sidePanelData.invoice.pending_amount > 0"
                  class="flex align-items-center justify-content-space-between"
                >
                  <b><span>Pending Amount:</span></b>
                  <b
                    ><span
                      >&#x20B9;
                      {{
                        sidePanelData.invoice.pending_amount?.toFixed(2)
                      }}</span
                    ></b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="sidePanelData.invoice.transactions?.length">
          <p-divider></p-divider>
          <div id="transactions">
            <p-accordion [activeIndex]="0">
              <p-accordionTab header="Transactions">
                <div id="transactions-wrapper">
                  <ng-container
                    *ngFor="
                      let transaction of sidePanelData.invoice.transactions
                        | sortByEpoch : 'record_date';
                      let isLast = last
                    "
                  >
                    <div class="flex gap-2 align-items-center">
                      <div class="transaction_type">
                        <!-- <img
                          
                          src="../../../assets/img/payment.svg"
                          alt="payment"
                        /> -->
                        <app-icon
                          icon="payment"
                          color="success"
                          *ngIf="transaction.type === INVOICE_TXN_TYPES.Payment"
                        ></app-icon>
                        <app-icon
                          icon="refund"
                          color="success"
                          *ngIf="transaction.type === INVOICE_TXN_TYPES.Refund"
                        ></app-icon>
                      </div>
                      <div
                        class="transaction-details flex flex-1 align-items-center justify-content-space-between"
                      >
                        <div class="flex flex-column gap-min w-30">
                          <b
                            ><span>{{
                              utilities.epochToDate(
                                transaction.record_date,
                                "dd MMM yyyy | hh:mm a"
                              )
                            }}</span></b
                          >
                          <small
                            *ngIf="
                              transaction.receipt_number &&
                              transaction.receipt_number !== ''
                            "
                            ><span
                              >Receipt: {{ transaction.receipt_number }}</span
                            ></small
                          >
                        </div>
                        <div class="flex justify-content-center w-20">
                          <p-tag
                            [style]="{
                              background: getPaymentTagBackground(
                                transaction.payment_method
                              )
                            }"
                            [ngClass]="transaction.payment_method"
                            [value]="
                              getPaymentMethodLabel(transaction.payment_method)
                            "
                          ></p-tag>
                        </div>
                        <div class="w-20 flex justify-content-end">
                          <b class="transaction-amount"
                            >&#x20B9; {{ transaction.amount?.toFixed(2) }}</b
                          >
                        </div>
                        <!-- <p-button
                          label="Receipt"
                          styleClass="p-button-sm p-button-secondary"
                        ></p-button> -->
                      </div>
                    </div>
                    <p-divider *ngIf="!isLast"></p-divider>
                  </ng-container>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </ng-container>
      </div>
      <app-view-party
        *ngSwitchCase="'party'"
        [party]="sidePanelData.party!"
      ></app-view-party>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="'invoice'">
        <div class="flex gap-2 w-100">
          <div class="flex">
            <p-button
              label="Download PDF"
              icon="pi pi-file-pdf"
              styleClass="p-button-outlined p-button-danger p-button-sm"
              (onClick)="downloadInvoice()"
            ></p-button>
          </div>
          <div
            class="flex"
            *ngIf="sidePanelData.invoice?.status !== invoiceStatus.Paid"
          >
            <p-button
              label="Record a payment"
              icon="pi pi-money-bill"
              styleClass="p-button-outlined p-button-primary p-button-sm"
              (onClick)="markInvoiceAsPaid(sidePanelData.invoice)"
            ></p-button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</p-sidebar>
<p-sidebar
  [(visible)]="subSidebarVisible"
  position="right"
  styleClass="w-25 subSidebar"
  transitionOptions="400ms cubic-bezier(0, 0, 0.2, 1)"
  (onHide)="closeSubPanel()"
>
  <ng-template pTemplate="header">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="'invoice'">
        <h5>
          Record Payment for {{ this.sidePanelData.invoice.invoice_number }}
        </h5>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container [ngSwitch]="sidePanelData.dataType">
      <ng-container *ngSwitchCase="'invoice'">
        <app-record-payment
          [invoice]="sidePanelData.invoice"
          (paymentRecordResult)="onPaymentRecord($event)"
        ></app-record-payment>
      </ng-container>
    </ng-container>
  </ng-template>
</p-sidebar>
