<div class="section-header-wrapper pb-1 mb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5 class="heading">New Challan</h5>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-sm p-button-secondary p-button-text"
      (onClick)="backToChallanListing()"
    ></p-button>
  </div>
</div>
<ng-container *ngIf="isContentLoaded">
  <form [formGroup]="newChallanForm" (ngSubmit)="createUpdateChallan()">
    <div id="new-challan-wrapper" class="flex flex-column gap-1">
      <section>
        <p class="title">Basic Details</p>
        <div class="input-group">
          <label for="productType">Choose item type</label>
          <p-selectButton
            [options]="challanTypeOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="type"
          ></p-selectButton>
          <app-field-error
            [isFormSubmitted]="isSubmitted"
            [fieldControl]="newChallanForm.controls.type"
          >
          </app-field-error>
        </div>
        <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <label for="productName">Select a Customer</label>
                <p-button
                  icon="pi pi-plus-circle"
                  label="Add new customer"
                  styleClass="p-button-text p-1 extra-small"
                  (onClick)="createCustomer()"
                ></p-button>
              </div>
              <p-dropdown
                [options]="customers"
                optionLabel="first_name"
                [filter]="true"
                (onFilter)="filterParties($event)"
                placeholder="Search existing customers"
                (onChange)="partyChanged($event)"
              >
                <ng-template let-party pTemplate="item">
                  <div class="flex flex-column justify-content-start gap-0">
                    <div>{{ party.first_name }}</div>
                    <small>{{
                      party.contact_details.mobile_number.value
                    }}</small>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <!-- <app-field-error
              [isFormSubmitted]="isSubmitted"
              [fieldControl]="newChallanForm.controls.selectedCustomer"
            >
            </app-field-error> -->
          </div>
          <div class="flex flex-1 gap-3">
            <div class="input-group flex-1">
              <label for="unit">challan date</label>
              <p-calendar
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                formControlName="challan_date"
                (onSelect)="issueOnChanged($event)"
              ></p-calendar>
              <app-field-error
                [isFormSubmitted]="isSubmitted"
                [fieldControl]="newChallanForm.controls.challan_date"
              >
              </app-field-error>
            </div>
            <div class="input-group flex-1">
              <label for="unit">Delivery date</label>
              <p-calendar
                [showIcon]="true"
                dateFormat="dd-mm-yy"
                appendTo="body"
                formControlName="delivery_date"
                [minDate]="newChallanForm.controls.challan_date.getRawValue()!"
              ></p-calendar>
              <app-field-error
                [isFormSubmitted]="isSubmitted"
                [fieldControl]="newChallanForm.controls.delivery_date"
              >
              </app-field-error>
            </div>
          </div>
        </div>
        <div
          class="flex-1 input-group"
          formGroupName="party"
          *ngIf="selectedParty"
        >
          <label>choose a delivery address</label>
          <div class="grid gap-3" id="shipping-address-wrapper">
            <ng-container
              *ngFor="let address of selectedParty?.shipping_address"
            >
              <label [for]="address.id" class="flex-1 card">
                <div class="flex align-items-start gap-1">
                  <div class="flex flex-column gap-2">
                    <b>{{ address.nick_name }}</b>
                    <span>{{ getAddressForDisplay(address) }}</span>
                  </div>
                  <!-- <p-checkbox
                    class="address_checkbox"
                    name="selectedShippingAddress"
                    [value]="address.id"
                    [inputId]="address.id"
                  ></p-checkbox> -->
                  <p-radioButton
                    [value]="address.id"
                    [inputId]="address.id"
                    formControlName="shipping_address_id"
                  ></p-radioButton>
                </div>
              </label>
            </ng-container>
          </div>
        </div>
      </section>
      <p-divider></p-divider>
      <section>
        <div class="flex gap-3">
          <div
            class="flex-1 flex flex-column gap-3 p-3"
            id="delivery-vehicle-wrapper"
            formGroupName="transport"
          >
            <h6>Delivery Vehicle Details</h6>
            <div class="input-group flex-1">
              <div
                class="flex align-items-center justify-content-space-between"
              >
                <label for="delivery_vehicle">Choose Vehicle</label>
                <p-button
                  icon="pi pi-plus-circle"
                  label="Add new vehicle to fleet"
                  styleClass="p-button-text p-1 extra-small"
                ></p-button>
              </div>
              <p-autoComplete
                [suggestions]="filteredVehicles"
                (completeMethod)="filterVehicles($event)"
                [dropdown]="true"
                (onSelect)="vehicleSelected($event)"
                formControlName="vehicle_number"
              >
                <ng-template let-vehicle pTemplate="item">
                  <div class="flex flex-column justify-content-start gap-0">
                    <div>{{ vehicle.vehicle_number }}</div>
                    <small>{{ vehicle.brand }} {{ vehicle.model }}</small>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="input-group flex-1">
              <div class="flex gap-1">
                <label for="transport_vehicle_company"
                  >transport company name</label
                >
                <span class="optional">(optional)</span>
              </div>
              <input
                id="transport_vehicle_company"
                type="text"
                pInputText
                formControlName="company_name"
              />
            </div>
          </div>
          <div class="flex-1 flex flex-column gap-3 p-3" id="notes-wrapper">
            <div class="flex gap-1">
              <app-icon icon="notes"></app-icon>
              <h6>Notes</h6>
            </div>
            <div class="input-group flex-1">
              <div class="flex gap-1">
                <label for="notes">Delivery related notes</label>
                <span class="optional">(optional)</span>
              </div>
              <textarea id="notes" rows="5" cols="30" pInputTextarea></textarea>
            </div>
          </div>
        </div>
      </section>
      <p-divider></p-divider>
      <section>
        <p class="title">Items for delivery</p>
        <div class="flex gap-3">
          <div class="flex-1">
            <div class="input-group">
              <label for="itemsSelector">Select an item</label>
              <p-dropdown
                [options]="products"
                optionLabel="name"
                optionDisabled="inactive"
                placeholder="Search existing products"
                [showClear]="true"
                [(ngModel)]="selectedProduct"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-product pTemplate="item">
                  <div class="flex flex-column align-items-start gap-min">
                    <div class="flex justify-content-space-between w-100">
                      <div>{{ product.name }}</div>
                      <div
                        class="meta-data flex flex-column align-items-end gap-0"
                      >
                        <span
                          >Quantity: {{ product.stock_quantity }}
                          {{ product.unit }}</span
                        >
                        <small
                          >Selling Price: &#x20B9;
                          {{ product.selling_price.toFixed(2) }}</small
                        >
                      </div>
                    </div>
                    <small
                      ><em *ngIf="product.inactive"
                        >Already added to invoice</em
                      ></small
                    >
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex">
              <div class="input-group">
                <label for="itemQuantity">Quantity</label>
                <p-inputNumber
                  [useGrouping]="false"
                  [(ngModel)]="selectedProductQuantity"
                  [ngModelOptions]="{ standalone: true }"
                  mode="decimal"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                ></p-inputNumber>
              </div>
              <div
                class="input-group flex-1 align-items-end justify-content-end"
              >
                <p-button
                  icon="pi pi-plus"
                  label="Add item to challan"
                  [disabled]="
                    !selectedProduct ||
                    selectedProduct.id === '' ||
                    selectedProductQuantity === 0 ||
                    selectedProductQuantity === null
                  "
                  (onClick)="addItemToChallan()"
                ></p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-column">
          <div formArrayName="challan_items">
            <p-table
              [value]="challanItemsList().controls"
              [tableStyle]="{ 'min-width': '100%' }"
              styleClass="p-datatable-sm"
              class="w-100"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [resizableColumns]="true"
              columnResizeMode="expand"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 15%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Item Code
                    </div>
                  </th>
                  <th style="width: 45%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Description
                    </div>
                  </th>
                  <th style="width: 15%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Quantity
                    </div>
                  </th>
                  <th style="width: 15%">
                    <div
                      class="flex align-items-center justify-content-space-between"
                    >
                      Net Weight
                    </div>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-challanItem>
                <tr>
                  <td>
                    <span class="p-column-title">Item Code</span>
                    <span class="cell-content">{{
                      challanItem.value.item_code
                        ? challanItem.value.item_code
                        : "NA"
                    }}</span>
                  </td>
                  <td>
                    <span class="p-column-title">Description</span>
                    <span class="cell-content">{{
                      challanItem.value.description
                    }}</span>
                  </td>
                  <td>
                    <span class="p-column-title">Quantity</span>
                    <span class="cell-content">{{
                      challanItem.value.quantity
                    }}</span>
                  </td>
                  <td>
                    <span class="p-column-title">Net Weight</span>
                    <span class="cell-content"
                      >{{
                        challanItem.value.unit_weight *
                          challanItem.value.quantity
                      }}
                      {{
                        ITEM_WEIGHT_UNIT[challanItem.value.weight_unit]
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="cell-content">
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-sm p-button-danger p-button-text"
                        (onClick)="removeChallanItem(challanItem.value)"
                      ></p-button>
                    </span>
                  </td>
                  <!-- <td>
                    <span class="p-column-title">Quantity</span>
                    <p-inputNumber
                      [(ngModel)]="invoiceItem.value.quantity"
                      [ngModelOptions]="{ standalone: true }"
                      [useGrouping]="false"
                      mode="decimal"
                      [minFractionDigits]="0"
                      [maxFractionDigits]="2"
                      (onInput)="calculateInvoiceTotal()"
                    >
                    </p-inputNumber>
                  </td>
                  <td>
                    <span class="p-column-title">Unit Price</span>
                    <div class="flex flex-column">
                      <p-inputNumber
                        [(ngModel)]="invoiceItem.value.unit_price"
                        [ngModelOptions]="{ standalone: true }"
                        [useGrouping]="false"
                        mode="currency"
                        currency="INR"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        (onInput)="unitPriceChanged(invoiceItem.value)"
                      ></p-inputNumber>
                      <small
                        *ngIf="
                          invoiceItem.value.discount_amount > 0 ||
                          invoiceItem.value.discount_percent > 0
                        "
                        ><em
                          >after discount
                          {{
                            getUnitPriceAfterDiscount(invoiceItem.value)
                              | roundOffCurrency
                          }}</em
                        ></small
                      >
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Tax Amount</span>
                    <div class="flex flex-column">
                      <p-inputNumber
                        [(ngModel)]="invoiceItem.value.price_w_tax"
                        [ngModelOptions]="{ standalone: true }"
                        [useGrouping]="false"
                        mode="currency"
                        currency="INR"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                      ></p-inputNumber>
                      <small *ngIf="invoiceItem.is_discount_given"
                        ><em
                          >after discount
                          {{ invoiceItem.price_w_tax_after_discount }}</em
                        ></small
                      >
                      span class="cell-content">{{
                        (
                          getInvoiceItemTax(
                            invoiceItem.value.product_service_id,
                            invoiceItem.value.unit_price,
                            invoiceItem.value.quantity
                          ) / 3
                        ).toFixed(2)
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Discount</span>
                    <div class="p-inputgroup">
                      <p-inputNumber
                        *ngIf="invoiceItem.value.discount_mode === 'amount'"
                        [useGrouping]="false"
                        mode="decimal"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [max]="
                          invoiceItem.value.price_w_tax *
                          invoiceItem.value.quantity
                        "
                        (onInput)="discountChanged(invoiceItem.value, $event)"
                        [(ngModel)]="invoiceItem.value.discount_amount"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </p-inputNumber>
                      <p-inputNumber
                        *ngIf="invoiceItem.value.discount_mode === 'percent'"
                        [useGrouping]="false"
                        mode="decimal"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                        [max]="
                          invoiceItem.value.price_w_tax *
                          invoiceItem.value.quantity
                        "
                        (onInput)="discountChanged(invoiceItem.value, $event)"
                        [(ngModel)]="invoiceItem.value.discount_percent"
                        [ngModelOptions]="{ standalone: true }"
                      >
                      </p-inputNumber>
                      <span class="p-inputgroup-addon">
                        <p-dropdown
                          [options]="discountMode"
                          appendTo="body"
                          optionLabel="name"
                          optionValue="code"
                          styleClass="dropdown-addon"
                          [(ngModel)]="invoiceItem.value.discount_mode"
                          [ngModelOptions]="{ standalone: true }"
                        ></p-dropdown>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span class="p-column-title">Net Amount</span>
                    <span class="cell-content">
                      {{
                        getInvoiceItemNetAmount(invoiceItem.value)
                          | roundOffCurrency
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="cell-content">
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-sm p-button-danger p-button-text"
                        (onClick)="removeInvoiceItem(invoiceItem.value)"
                      ></p-button>
                    </span>
                  </td> -->
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </section>
    </div>
    <div class="sticky pblk-1">
      <div id="action-buttons-wrapper" class="flex gap-3 pt-2">
        <p-button label="Discard" styleClass="p-button-secondary"></p-button>
        <p-button
          label="Create Challan"
          type="submit"
          [loading]="isSubmitted"
        ></p-button>
      </div>
    </div>
  </form>
</ng-container>
