<!-- <button
  type="button"
  class="flex align-items-center justify-content-center gap-1"
  [ngClass]="{
    'icon-right': icon_position.toUpperCase() === 'RIGHT',
    'secondary': variant.toUpperCase() === 'SECONDARY'
  }"
>
  <ng-container *ngIf="icon !== '' && isIconValid(icon)">
    <svg
      class="icon"
      *ngIf="variant.toUpperCase() === 'SECONDARY'; else primaryIcon"
    >
      <use
        [attr.href]="'assets/img/icons/'.concat(icon).concat('.svg#symbol')"
        [style.--color_fill]="getColor('brand-primary')"
      ></use>
    </svg>
  </ng-container>
  <ng-container *ngIf="label && label !== ''">
    {{ label }}
  </ng-container>
</button> -->

<p-button
  type="button"
  [label]="label"
  [styleClass]="styleClasses.join(' ')"
  [disabled]="disabled"
>
  <ng-container *ngIf="icon !== '' && isIconValid(icon)">
    <div class="icon">
      <svg
        *ngIf="variant.toUpperCase() === 'SECONDARY'; else primaryIcon"
        width="100%"
        height="100%"
      >
        <use
          [attr.href]="'assets/img/icons/'.concat(icon).concat('.svg#symbol')"
          [style.--color_fill]="getColor('brand-primary')"
        ></use>
      </svg>
    </div>
  </ng-container>
</p-button>

<ng-template #primaryIcon>
  <svg *ngIf="icon !== '' && isIconValid(icon)">
    <use
      [attr.href]="'assets/img/icons/'.concat(icon).concat('.svg#symbol')"
      [style.--color_fill]="getColor('neutral-00')"
    ></use>
  </svg>
</ng-template>
