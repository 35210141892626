import { Injectable } from "@angular/core";
import { Company } from "../models/company.model";
import User from "../models/user.model";
import format from "date-fns/format";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { INVOICE_STATUS } from "../models/invoice-item.model";
import { PhoneNumberUtil, PhoneNumberType } from "google-libphonenumber";
import { Address } from "../models/address.model";

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  readonly THOUSAND = 1;

  user: User = new User({});

  companies: Array<Company> = [];

  currentCompany: Company = {} as Company;

  constructor() {}

  cloneDeep(thing: any) {
    return JSON.parse(JSON.stringify(thing));
  }

  setUserInService(user: User) {
    this.user = user;
  }

  getUserFromService = () => this.user;

  setCompaniesInService(companies: Array<Company>) {
    this.companies = companies;
  }

  getCompaniesFromService() {
    return this.companies;
  }

  setCurrentCompany(company: Company) {
    this.currentCompany = company;
  }

  getCurrentCompany() {
    return this.currentCompany;
  }

  getPriceWithoutTax(price: number, tax_rate: number): number {
    let tax = 0;
    if (tax_rate > 1) {
      tax = tax_rate / 100;
    } else {
      tax = tax_rate;
    }

    return Number(price / (1 + tax));
  }

  getTaxAmount(price_wo_tax: number, tax_rate: number): number {
    let tax = 0;
    if (tax_rate > 1) {
      tax = tax_rate / 100;
    } else {
      tax = tax_rate;
    }

    return Number(price_wo_tax * tax);
  }

  dateToEpoch(date: any) {
    return new Date(date).valueOf() / this.THOUSAND;
  }

  epochToDate(epoch: number, formatAs?: string) {
    const date = new Date(epoch * this.THOUSAND);

    if (!formatAs || formatAs === "") {
      return date;
    }

    return format(date, formatAs);
  }

  // getFieldInvalidStatus(
  //   fg: FormGroup,
  //   controlName: string
  // ): boolean | undefined {
  //   if (!controlName || controlName === "") return false;

  //   return (
  //     fg.get(controlName)?.invalid &&
  //     (fg.get(controlName)?.dirty || fg.get(controlName)?.touched)
  //   );
  // }

  currencyValidator(control: AbstractControl): ValidationErrors | null {
    const currencyRegex = /^(?!0+(\.0{1,2})?$)([$]?\d{1,16}(\.\d{1,2})?)$/;
    if (currencyRegex.test(control.value)) {
      return null; // Valid
    } else {
      return { invalidCurrency: true }; // Invalid
    }
  }

  getInvoiceStatusLabel(status: number): string {
    const statusLabel = INVOICE_STATUS[status] || undefined;

    if (!statusLabel) {
      return "Invalid Status";
    }

    return statusLabel;
  }

  convertStringEnumToMapList(enumerable: any) {
    return Object.keys(enumerable)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key) => {
        const enumKey = key as keyof typeof enumerable;
        return { label: enumKey, value: enumerable[enumKey] };
      });
  }

  getAddressForDisplay(address: Address | undefined | null): string {
    let finalAddress = "";

    if (!address) return finalAddress;

    if (address.line_1 && address.line_1 !== null && address.line_1 !== "") {
      finalAddress = finalAddress.concat(address.line_1.trim(), ", ");
    }

    if (address.line_2 && address.line_2 !== null && address.line_2 !== "") {
      finalAddress = finalAddress.concat(address.line_2.trim(), ", \n");
    }

    if (
      address.landmark &&
      address.landmark !== null &&
      address.landmark !== ""
    ) {
      finalAddress = finalAddress.concat(address.landmark.trim(), ", \n");
    }

    if (address.city && address.city !== null && address.city !== "") {
      finalAddress = finalAddress.concat(address.city.trim(), ", ");
    }

    if (address.state && address.state !== null && address.state !== "") {
      finalAddress = finalAddress.concat(address.state.trim(), ", ");
    }

    if (
      address.postal_code &&
      address.postal_code !== null &&
      address.postal_code !== ""
    ) {
      finalAddress = finalAddress.concat(address.postal_code.trim());
    }

    return finalAddress;
  }
}
