import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  NgForm,
  FormControlName,
} from "@angular/forms";
import { ProductService } from "src/app/core/services/product.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import {
  ToastService,
  ToastMessage,
} from "src/app/core/services/toast.service";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import {
  PRODUCT_UNITS,
  TAXATION_OPTIONS,
  TAX_RATES,
  INVENTORY_ITEM_TYPE,
  INVENTORY_ITEM_OPTIONS,
  ITEM_WEIGHT_UNIT,
} from "src/app/core/constants/constants";
import { AutoCompleteCompleteEvent } from "src/app/core/models/shared.model";
import { ConfirmExitModalComponent } from "src/app/shared/confirm-exit-modal/confirm-exit-modal.component";

interface StringDropdown {
  name: string;
  code: string;
}

interface NumberDropdown {
  name: string;
  code: number;
}

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: "app-create-product",
  templateUrl: "./create-product.component.html",
  styleUrls: ["./create-product.component.scss"],
})
export class CreateProductComponent implements OnInit {
  @Input() isInSidePanel = false;

  INVENTORY_ITEM_TYPE = INVENTORY_ITEM_TYPE;

  productTypeOptions: any[] = INVENTORY_ITEM_OPTIONS;

  units = PRODUCT_UNITS;

  taxation = TAXATION_OPTIONS;

  taxPercentages = TAX_RATES;

  profitPercentages: NumberDropdown[] = [];

  uploadedFiles: any[] = [];

  editProductId: string = "";

  productToEdit!: Inventory_Item;

  item_weight_units =
    this.utilities.convertStringEnumToMapList(ITEM_WEIGHT_UNIT);

  newProduct = new FormGroup({
    type: new FormControl<INVENTORY_ITEM_TYPE | null>(
      INVENTORY_ITEM_TYPE.Product,
      Validators.required
    ),
    name: new FormControl("", Validators.required),
    category: new FormControl(""),
    description: new FormControl(""),
    min_profit_percent: new FormControl<number | undefined>(undefined),
    purchase_price: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
    selling_price: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
    stock_quantity: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    tax_rate: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
    unit: new FormControl("", Validators.required),
    barcode: new FormControl(""),
    hsn_sac: new FormControl<number | null>(null),
    low_stock_quantity: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    item_code: new FormControl<string | null>(""),
    weight: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    weight_unit: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  purchase_price_wt = 0;

  purchase_price_wot = 0;

  purchase_price_mode = "WT";

  selling_price_wt = 0;

  selling_price_wot = 0;

  selling_price_mode = "WT";

  tax_amount = 0;

  isEditMode: boolean = false;

  productCategories: string[] = [];

  isSubmitted = false;

  EDITOR_MAX_CHARS = 300;

  remainingEditorChars = this.EDITOR_MAX_CHARS;

  filteredCategories: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ps: ProductService,
    public utilities: UtilitiesService,
    private ts: ToastService,
    private cem: ConfirmExitModalComponent
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    this.productToEdit = state ? state["product"] : undefined;
    this.editProductId = this.route.snapshot.paramMap.get("id")!;

    this.ps
      .getProductCategories(this.utilities.getUserFromService().companyId)
      .subscribe({
        next: (response: any) => {
          if (response.data !== null && response.data.length) {
            response.data.forEach((category: string) => {
              this.productCategories.push(category);
            });
          }
        },
      });
  }

  ngOnInit(): void {
    for (let i = 1; i <= 20; i++) {
      const profit: NumberDropdown = {
        name: (i * 5).toString(),
        code: i * 5,
      };
      this.profitPercentages.push(profit);
    }

    if (
      this.editProductId &&
      this.editProductId !== null &&
      this.editProductId !== ""
    ) {
      this.isEditMode = true;

      this.newProduct.patchValue({
        barcode: this.productToEdit.barcode,
        category: this.productToEdit.category,
        description: this.productToEdit.description,
        min_profit_percent: this.productToEdit.min_profit_percent,
        name: this.productToEdit.name,
        item_code: this.productToEdit.item_code,
        purchase_price: this.productToEdit.purchase_price_w_tax,
        selling_price: this.productToEdit.selling_price,
        stock_quantity: this.productToEdit.stock_quantity,
        tax_rate: this.productToEdit.tax_rate,
        unit: this.productToEdit.unit,
        type: this.productToEdit.type,
        hsn_sac: this.productToEdit.hsn_sac,
        weight: this.productToEdit.weight,
        weight_unit: this.productToEdit.weight_unit,
      });
    }
  }

  backToProductListing(inventoryId?: string) {
    if (
      this.newProduct.pristine ||
      (inventoryId !== undefined && inventoryId !== "")
    ) {
      this.router.navigate(["../products"], {
        state: { inventoryId },
        relativeTo: this.route.parent,
      });
      return;
    }

    this.cem.confirm().subscribe((isConfirmed) => {
      if (!isConfirmed) return;

      this.router.navigate(["../products"], {
        state: { inventoryId },
        relativeTo: this.route.parent,
      });
    });
  }

  itemTypeChanged(event: any) {
    const openingStockControl = this.newProduct.get("stock_quantity");
    const lowStockControl = this.newProduct.get("low_stock_quantity");

    if (event.value === INVENTORY_ITEM_TYPE.Service) {
      openingStockControl?.removeValidators([Validators.required]);
      lowStockControl?.removeValidators([Validators.required]);
    } else {
      openingStockControl?.setValidators([Validators.required]);
      lowStockControl?.setValidators([Validators.required]);
    }
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  createUpdateProduct(event: any) {
    this.isSubmitted = true;
    if (!this.newProduct.valid) return;

    const companyId = this.utilities.getUserFromService().companyId;

    let product: any = this.newProduct.value;

    switch (this.purchase_price_mode) {
      case "WT":
        product.purchase_price_w_tax =
          this.newProduct.controls.purchase_price.getRawValue();
        product.purchase_price = product.purchase_price_w_tax - this.tax_amount;
        break;
      case "WOT":
        const current_pp =
          this.newProduct.controls.purchase_price.getRawValue()!;
        product.purchase_price_w_tax = current_pp + this.tax_amount;
        product.purchase_price = current_pp;
        break;
      default:
        break;
    }

    if (this.isEditMode) {
      this.ps.updateProduct(companyId, this.editProductId, product).subscribe({
        next: (response: any) => {
          console.log("update product response :: ", response);
          const toastMessage: ToastMessage = {
            show: true,
            severity: "success",
            summary: "Success",
            detail: "Your product has been updated.",
          };
          this.ts.sendMessage(toastMessage);
          this.backToProductListing();
        },
        complete: () => {
          this.isSubmitted = false;
        },
      });
    } else {
      this.ps.createProduct(companyId, product).subscribe({
        next: (response: any) => {
          console.log("create product response :: ", response);
          if (!this.isInSidePanel) {
            const toastMessage: ToastMessage = {
              show: true,
              severity: "success",
              summary: "Success",
              detail: "Your new product is ready for use.",
            };
            this.ts.sendMessage(toastMessage);
            this.backToProductListing(response.data?.id);
          } else {
            this.ps.sendItemCreation(response.data.id);
          }
        },
        complete: () => {
          this.isSubmitted = false;
        },
      });
    }
  }

  calculateTaxAmount() {
    const current_pp = this.newProduct.controls.purchase_price.getRawValue()!;
    const current_tax_rate =
      this.newProduct.controls.tax_rate.getRawValue()! / 100;
    switch (this.purchase_price_mode) {
      case "WT":
        this.tax_amount =
          (current_pp / (1 + current_tax_rate)) * current_tax_rate;
        // (current_pp * current_tax_rate) / 100;
        // Selling Price / (1 + Tax Rate) * Tax Rate
        break;
      case "WOT":
        this.tax_amount = current_pp * current_tax_rate;
        break;
      default:
        break;
    }
    console.log("tax_amount :: ", this.tax_amount);
  }

  recalculatePurchasePrice(event: any) {
    console.log("purchase price mode changed to :: ", event.value.value);
    if (this.newProduct.controls.purchase_price.getRawValue() === null) {
      return;
    }

    this.calculateTaxAmount();

    this.purchase_price_mode = event.value.value;
    const current_pp: number = parseFloat(
      this.newProduct.controls.purchase_price.getRawValue()!.toString()
    );

    switch (this.purchase_price_mode) {
      case "WT":
        this.purchase_price_wt = current_pp + this.tax_amount;
        this.newProduct.controls.purchase_price.setValue(
          this.purchase_price_wt
        );

        break;
      case "WOT":
        this.purchase_price_wot = current_pp - this.tax_amount;
        this.newProduct.controls.purchase_price.setValue(
          this.purchase_price_wot
        );
        break;
      default:
        break;
    }
  }

  recalculateSellingPrice(event: any) {
    console.log("selling price mode changed to :: ", event.value.value);
    if (this.newProduct.controls.selling_price.getRawValue() === null) {
      return;
    }

    this.newProduct.controls["selling_price"];

    this.calculateTaxAmount();

    this.selling_price_mode = event.value.value;
    const current_sp: number = parseFloat(
      this.newProduct.controls.selling_price.getRawValue()!.toString()
    );

    switch (this.selling_price_mode) {
      case "WT":
        this.selling_price_wt = current_sp + this.tax_amount;
        this.newProduct.controls.selling_price.setValue(this.selling_price_wt);

        break;
      case "WOT":
        this.selling_price_wot = current_sp - this.tax_amount;
        this.newProduct.controls.selling_price.setValue(this.selling_price_wot);
        break;
      default:
        break;
    }
  }

  recalculatePrice(event: any, controlName: string) {
    const control = this.newProduct.get(controlName) as FormControl;
    if (control.getRawValue() === null) {
      return;
    }

    this.calculateTaxAmount();

    const current_price: number = parseFloat(control.getRawValue()!.toString());

    switch (event.value.value) {
      case "WT":
        control.setValue(current_price + this.tax_amount);

        break;
      case "WOT":
        control.setValue(current_price - this.tax_amount);
        break;
      default:
        break;
    }
  }

  getInvalidStatus(field: any): boolean | undefined {
    if (!field || field === "") return false;

    return (
      this.newProduct.get(field)?.invalid &&
      (this.newProduct.get(field)?.dirty || this.newProduct.get(field)?.touched)
    );
  }

  onEditorTextChange(event: any) {
    console.log("event ::::: ", event);
    this.remainingEditorChars =
      this.EDITOR_MAX_CHARS - event.textValue?.length < 0
        ? 0
        : this.EDITOR_MAX_CHARS - event.textValue?.length;
  }

  filterProductCategories(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.productCategories as any[]).length; i++) {
      let category = (this.productCategories as any[])[i];
      if (category.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(category);
      }
    }

    this.filteredCategories = filtered;
  }
}
