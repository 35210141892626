import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { SideNavToggle } from "./side-nav-toggle.interface";
import { Router } from "@angular/router";

interface MenuItem {
  label: string;
  icon?: string;
  route: string;
  items?: MenuItem[];
  expanded?: boolean;
}

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();

  readonly mainNavItems: MenuItem[] = [
    {
      icon: "dashboard",
      label: "Dashboard",
      route: "/dashboard",
    },
    {
      icon: "sales",
      label: "Sales",
      route: "/sales",
      items: [
        {
          label: "Invoices",
          route: "/sales",
        },
        {
          label: "Credit Notes",
          route: "/creditnotes",
        },
      ],
    },
    {
      icon: "inventory",
      label: "Inventory",
      route: "/products",
    },
    {
      icon: "parties",
      label: "Parties",
      route: "/parties",
    },
    {
      icon: "slip",
      label: "Slips/Quotations",
      route: "/delivery_challan",
      items: [
        {
          label: "Delivery Challan",
          route: "/delivery_challan",
        },
      ],
    },
    {
      icon: "settings",
      label: "Settings",
      route: "/settings",
    },
  ];

  isCollapsed = false;

  screenWidth = 0;

  constructor(public router: Router) {}

  toggleSideNav() {
    this.isCollapsed = !this.isCollapsed;
    this.screenWidth = window.innerWidth;
    this.onToggleSideNav.emit({
      collapsed: this.isCollapsed,
      screenWidth: this.screenWidth,
    });
  }

  ngOnInit(): void {}

  toggleSubMenu(event: MouseEvent, menuItem: MenuItem) {
    event.preventDefault();
    event.stopPropagation();
    menuItem.expanded = !menuItem.expanded;
  }
}
