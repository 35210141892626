<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Products / Services</h5>
    <p-button
      label="New item"
      styleClass="p-button-sm"
      icon="pi pi-plus"
      iconPosition="left"
      (click)="createNewProduct()"
    ></p-button>
  </div>
</div>
<div *ngIf="!contentLoaded" class="flex flex-column gap-3">
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{ 'height': '48px', 'border-radius': '8px', 'margin': '0' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    class="flex flex-1 gap-3"
    count="4"
    appearance="circle"
    [theme]="{
      'flex': 1,
      'height': '80px',
      'border-radius': '8px',
      'margin': '0'
    }"
  >
  </ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="6"
    appearance="line"
    [theme]="{ 'height': '48px', 'border-radius': '8px' }"
  ></ngx-skeleton-loader>
</div>
<ng-container *ngIf="contentLoaded">
  <p-tabView>
    <p-tabPanel header="Items">
      <div class="flex flex-column gap-3 mb-2">
        <div class="grid gap-3" id="stock-stats">
          <div
            class="flex flex-column gap-1 p-2 stock-stat-card"
            id="low-stock"
          >
            <span>Low Stock</span>
            <h5>{{ lowStockItems }} items</h5>
          </div>
          <div
            class="flex flex-column gap-1 p-2 stock-stat-card"
            id="positive-stock"
          >
            <span>Positive Stock</span>
            <h5>{{ positiveStockItems }} items</h5>
          </div>
          <div
            class="flex flex-column gap-1 p-2 stock-stat-card"
            id="stock-sales-price"
          >
            <span>Stock Value Sales Price</span>
            <h5>&#x20B9; {{ stockValueSalesPrice }}</h5>
          </div>
          <div
            class="flex flex-column gap-1 p-2 stock-stat-card"
            id="stock-purchase-price"
          >
            <span>Stock Value Purchase Price</span>
            <h5>&#x20B9; {{ stockValuePurchasePrice }}</h5>
          </div>
        </div>
      </div>
      <ng-container *ngIf="products.length > 0; else noDataFound">
        <p-table
          [columns]="cols"
          [value]="products | sortByEpoch : 'update_date'"
          [paginator]="true"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          styleClass="p-datatable-sm"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [resizableColumns]="false"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th [pSortableColumn]="col.field" *ngFor="let col of columns">
                {{ col.header }}
                <p-sortIcon
                  *ngIf="col.showAs !== 'action'"
                  [field]="col.field"
                ></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr (click)="viewProduct(rowData)">
              <td *ngFor="let col of columns" [style.width]="col?.width">
                <div [ngSwitch]="col.showAs">
                  <div *ngSwitchCase="'chip'">
                    <p-chip
                      [label]="rowData[col.field]"
                      styleClass="yellow"
                    ></p-chip>
                  </div>
                  <div *ngSwitchCase="'currency'">
                    <span
                      >&#x20B9;
                      {{ parseFloat(rowData[col.field]).toFixed(2) }}</span
                    >
                  </div>
                  <div *ngSwitchCase="'action'" class="flex gap-1">
                    <!-- pi-ellipsis-v -->
                    <p-button
                      icon="pi pi-ellipsis-v"
                      styleClass="p-button-sm p-button-secondary p-button-text"
                      (onClick)="openContextMenu($event, rowData)"
                    ></p-button>
                    <p-contextMenu
                      #contextMenu
                      appendTo="body"
                      [model]="contextMenuItems"
                    ></p-contextMenu>
                  </div>
                  <div *ngSwitchCase="'tag'">
                    <p-tag
                      [severity]="
                        rowData[col.field] === INVENTORY_ITEM_TYPE.Product
                          ? 'success'
                          : 'info='
                      "
                      [value]="INVENTORY_ITEM_TYPE[rowData[col.field]]"
                    ></p-tag>
                  </div>
                  <div *ngSwitchDefault>
                    <ng-container *ngIf="col.shouldConcat; else default">
                      {{ rowData[col.field] }} {{ rowData[col.concatField] }}
                    </ng-container>
                    <ng-template #default>
                      {{ rowData[col.field] }}
                    </ng-template>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
    </p-tabPanel>
    <p-tabPanel header="Categories">
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non
        numquam eius modi.
      </p>
    </p-tabPanel>
  </p-tabView>
  <ng-template #noDataFound>
    <div class="flex flex-column align-items-center justify-content-center">
      <img
        class="no-data-image"
        src="../../../assets/img/empty-folder.png"
        alt="empty"
      />
      <span
        >You don't seem to have any products in your catalog. Add a new product
        to get started...</span
      >
    </div>
  </ng-template>
</ng-container>
