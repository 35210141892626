<div id="side-nav" class="sidenav" [ngClass]="{ collapsed: isCollapsed }">
  <div class="nav-top" [ngClass]="{ collapsed: isCollapsed }">
    <div id="side-nav-toggle" (click)="toggleSideNav()" class="p-1">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 8.00004H2M11.3333 8.00004L7.33333 12M11.3333 8.00004L7.33333 4.00004M14 3.33337V12.6667"
          stroke="#fff"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <!-- <div class="nav-items"> -->
  <!-- <ul class="flex gap-min p-1 pinl-2"> -->
  <!-- <ng-container *ngFor="let navItem of mainNavItems">
        <li
          class="nav-link"
          [routerLink]="navItem.route"
          [ngClass]="{ active: router.url.startsWith(navItem.route) }"
        >
          <a
            class="flex align-items-center p-1"
            [ngClass]="{ collapsed: isCollapsed }"
          >
            <app-icon
              [icon]="navItem.icon"
              [color]="router.url.startsWith(navItem.route) ? 'primary' : ''"
            ></app-icon>
            <span class="nav-link-text" [ngClass]="{ hidden: isCollapsed }">{{
              navItem.label
            }}</span>
          </a>
        </li>
      </ng-container> -->
  <!-- <p-panelMenu [model]="mainNavItems"></p-panelMenu> -->
  <ul class="mt-4">
    <li
      *ngFor="let menuItem of mainNavItems"
      [ngClass]="{
        expandable: menuItem.items,
        expanded: menuItem.expanded
      }"
    >
      <a
        [routerLink]="menuItem.route"
        routerLinkActive="active"
        class="flex align-items-center"
      >
        <app-icon
          [icon]="menuItem.icon ? menuItem.icon : ''"
          [color]="router.url.startsWith(menuItem.route) ? 'primary' : ''"
          class="mr-1"
        ></app-icon>
        <span class="flex flex-1">{{ menuItem.label }}</span>
        <i
          *ngIf="menuItem.items"
          (click)="toggleSubMenu($event, menuItem)"
          class="pi pi-angle-down"
        ></i>
      </a>
      <ul *ngIf="menuItem.items">
        <li *ngFor="let childItem of menuItem.items">
          <a [routerLink]="childItem.route" routerLinkActive="active">
            <span>{{ childItem.label }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <!-- </ul> -->
  <!-- </div> -->
</div>
