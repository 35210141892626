import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-main-content",
  templateUrl: "./main-content.component.html",
  styleUrls: ["./main-content.component.scss"],
})
export class MainContentComponent implements OnInit, OnChanges {
  @Input() isSideNavCollapsed = false;

  @Input() screenWidth = 0;

  mainContentClasses = "";

  private readonly COLLAPSED = "collapsed";

  constructor() {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let styleClass: Array<string> = [];

    for (let propName in changes) {
      if (propName === "isSideNavCollapsed") {
        if (this.isSideNavCollapsed) {
          styleClass.push(this.COLLAPSED);
        } else {
          styleClass.splice(styleClass.indexOf(this.COLLAPSED), 1);
        }
      }
    }

    this.mainContentClasses = styleClass.join(" ");
  }

  getMainContentClass(): string {
    let styleClass: Array<string> = [];
    // if (this.isSideNavCollapsed && this.screenWidth > 768) {
    //   styleClass = "content-synced";
    // } else if (
    //   this.isSideNavCollapsed &&
    //   this.screenWidth <= 768 &&
    //   this.screenWidth > 0
    // ) {
    //   styleClass = "content-static";
    // }

    console.log(
      "main content this.isSideNavCollapsed :: ",
      this.isSideNavCollapsed
    );

    if (this.isSideNavCollapsed) {
    } else {
      if (this.screenWidth > 768) {
        // styleClass = "content-synced";
      } else if (this.screenWidth <= 768 && this.screenWidth > 0) {
        // styleClass = "content-static";
      }
    }
    return styleClass.join(" ");
  }
}
