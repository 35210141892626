import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import {
  ADDRESS_TYPES,
  CREATION_SIDEPANEL_DATA_TYPE,
  STATES,
} from "src/app/core/constants/constants";
import { Address, ADDRESS_KIND } from "src/app/core/models/address.model";
import { Company } from "src/app/core/models/company.model";
import ContactDetails from "src/app/core/models/contact-details.model";
import MobileNumber from "src/app/core/models/mobile-number.model";
import User from "src/app/core/models/user.model";
import {
  CreationSlidePanelData,
  CreationSlidepanelService,
} from "src/app/core/services/creation-slidepanel.service";
import { UserService } from "src/app/core/services/user.service";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { Store } from "@ngrx/store";
import * as UserActions from "../../../core/store_actions/user.actions";
import * as CompanyActions from "../../../core/store_actions/company.actions";

export type FormControlType<T> = { [key in keyof T]: FormControl<T[key]> };

@Component({
  selector: "app-company-update",
  templateUrl: "./company-update.component.html",
  styleUrls: ["./company-update.component.scss"],
})
export class CompanyUpdateComponent implements OnInit {
  company = new FormGroup({
    id: new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    company_name: new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    brand_name: new FormControl<string | undefined>(""),
    isDefault: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    gstin: new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    contact_details: new FormGroup({
      email: new FormControl<string | undefined>(""),
      mobile_number: new FormGroup({
        dial_code: new FormControl<string>("", Validators.required),
        value: new FormControl<string>("", Validators.required),
      }),
    }),
    billing_address: new FormArray<any>([], [Validators.required]),
    shipping_address: new FormArray<any>([], [Validators.required]),
  });

  isSubmitted: boolean = false;

  addressTypeOptions = this.utilities.convertStringEnumToMapList(ADDRESS_TYPES);

  states = STATES.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  isCreatingEditing = false;

  constructor(
    public utilities: UtilitiesService,
    private csps: CreationSlidepanelService,
    private us: UserService,
    private store: Store
  ) {}

  ngOnInit(): void {
    console.log("current company :: ", this.utilities.getCurrentCompany());
    this.initCompanySettings();

    // this.invoiceItemsList().push(new FormControl<InvoiceItem>(invoiceItem));

    console.log("company form :: ", this.company);

    this.us.getCompanyAddressUpdate().subscribe((address: Address) => {
      console.log("company update component :: ", address);

      // const companyPayload: Company = this.company.getRawValue() as Company;
      const tempCompany = this.company;

      if (Object.keys(address).length && address.id && address.id !== "") {
        const billingAddresses = tempCompany.controls.billing_address;

        for (let control of billingAddresses?.controls) {
          if (control.get("id")?.getRawValue() === address.id) {
            Object.entries(address).forEach((entry) => {
              const [key, value] = entry;
              if (control.get(key)) {
                control.get(key)?.setValue(value);
              }
            });
          }
        }

        const shippingAddresses = tempCompany.controls.shipping_address;

        for (let control of shippingAddresses?.controls) {
          if (control.get("id")?.getRawValue() === address.id) {
            Object.entries(address).forEach((entry) => {
              const [key, value] = entry;
              if (control.get(key)) {
                control.get(key)?.setValue(value);
              }
            });
          }
        }
      }

      this.updateCompany(tempCompany.getRawValue() as Company);
    });
  }
  initCompanySettings() {
    const currentCompany = this.utilities.getCurrentCompany();

    this.company.patchValue({
      id: currentCompany.id,
      company_name: currentCompany.company_name,
      brand_name: currentCompany.brand_name,
      isDefault: currentCompany.isDefault,
      gstin: currentCompany.gstin,
      // billing_address: currentCompany.billing_address,
      // shipping_address: currentCompany.shipping_address,
      contact_details: currentCompany.contact_details,
    });

    currentCompany.billing_address?.forEach((address) => {
      this.company.controls.billing_address.push(
        new FormGroup({
          id: new FormControl(address.id),
          type: new FormControl(address.type),
          city: new FormControl(address.city),
          is_default: new FormControl(address.is_default),
          landmark: new FormControl(address.landmark),
          line_1: new FormControl(address.line_1),
          line_2: new FormControl(address.line_2),
          nick_name: new FormControl(address.nick_name),
          postal_code: new FormControl(address.postal_code),
          state: new FormControl(address.state),
          state_code: new FormControl(address.state_code),
        })
      );
    });

    currentCompany.shipping_address?.forEach((address) => {
      this.company.controls.shipping_address.push(
        new FormGroup({
          id: new FormControl(address.id),
          type: new FormControl(address.type),
          city: new FormControl(address.city),
          is_default: new FormControl(address.is_default),
          landmark: new FormControl(address.landmark),
          line_1: new FormControl(address.line_1),
          line_2: new FormControl(address.line_2),
          nick_name: new FormControl(address.nick_name),
          postal_code: new FormControl(address.postal_code),
          state: new FormControl(address.state),
          state_code: new FormControl(address.state_code),
        })
      );
    });
  }

  updateCompany = (company: Company) => {
    console.log("update company method");
    const companyPayload = this.company.getRawValue();

    console.log("updateCompany companyPayload :: ", companyPayload);

    this.us.updateCompany(companyPayload as Company).subscribe({
      next: (response: any) => {
        console.log("updated successfully. :: ", response);
        this.csps.closeSlidePanel();

        this.us.getCompaniesForUser().subscribe({
          next: (companiesResponse: any) => {
            const defaultCompany = companiesResponse.data.find(
              (company: any) => company?.isDefault === true
            );

            // const userData: User = {
            //   id: response.data.id,
            //   first_name: response.data.first_name,
            //   last_name: response.data.last_name,
            //   isLoggedIn: true,
            //   companyId: defaultCompany.id,
            //   companyName: defaultCompany.company_name,
            //   contact_details: {
            //     mobile_number: {
            //       dial_code:
            //         response.data.contact_details.mobile_number.dial_code,
            //       value: response.data.contact_details.mobile_number.value,
            //     },
            //   },
            //   role: "admin",
            // };

            // this.store.dispatch(UserActions.setUser(userData));
            // this.utilities.setUserInService(userData);
            this.utilities.setCompaniesInService(companiesResponse.data);
            this.utilities.setCurrentCompany(defaultCompany);
            this.store.dispatch(
              CompanyActions.setCompanies({
                companies: companiesResponse.data,
                currentCompany: defaultCompany,
              })
            );

            this.initCompanySettings();
          },
          error: (error: any) => {
            console.log("error getting companies after company update");
          },
        });

        // this.utilities.setCompaniesInService([defaultCompany]);
        // this.utilities.setCurrentCompany(defaultCompany);
        // this.store.dispatch(
        //   CompanyActions.setCompanies({
        //     companies: [defaultCompany],
        //     currentCompany: defaultCompany,
        //   })
        // );
      },
      error: (error: any) => {
        console.log("error in updateCompany :: Error: ", error);
      },
    });
  };

  addAddress = (type: number) => {
    console.log("add billing address method");
    if (type === 0) {
      this.company.controls.billing_address.push(
        new FormGroup({
          type: new FormControl(null, Validators.required),
          city: new FormControl("", Validators.required),
          is_default: new FormControl(false),
          landmark: new FormControl(""),
          line_1: new FormControl("", Validators.required),
          line_2: new FormControl(""),
          nick_name: new FormControl(""),
          postal_code: new FormControl(null, Validators.required),
          state: new FormControl(null, Validators.required),
          state_code: new FormControl(null, Validators.required),
        })
      );
    } else if (type === 1) {
      this.company.controls.shipping_address.push(
        new FormGroup({
          type: new FormControl(null, Validators.required),
          city: new FormControl("", Validators.required),
          is_default: new FormControl(false),
          landmark: new FormControl(""),
          line_1: new FormControl("", Validators.required),
          line_2: new FormControl(""),
          nick_name: new FormControl(""),
          postal_code: new FormControl(null, Validators.required),
          state: new FormControl(null, Validators.required),
          state_code: new FormControl(null, Validators.required),
        })
      );
    } else {
      console.log("invalid address type");
    }
  };

  editAddress(addressGroup: AbstractControl) {
    console.log(addressGroup);

    const creationPanelData: CreationSlidePanelData = {
      openStatus: true,
      dataType: CREATION_SIDEPANEL_DATA_TYPE.address,
      data: addressGroup.value,
    };

    this.csps.openSlidePanel(creationPanelData);
  }
}
