import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import Party from "../models/party.model";

export interface SidePanelData {
  openStatus: boolean;
  dataType: "product" | "party" | "invoice";
  product?: any;
  party?: Party;
  invoice?: any;
  company?: any;
}

@Injectable({
  providedIn: "root",
})
export class SlidepanelService {
  private subject = new BehaviorSubject<SidePanelData>({} as SidePanelData);

  openSlidePanel(data: SidePanelData) {
    this.subject.next(data);
  }

  closeSlidePanel() {
    const data = {} as SidePanelData;
    data.openStatus = false;
    this.subject.next(data);
  }

  getMessasge(): Observable<SidePanelData> {
    return this.subject.asObservable();
  }
}
