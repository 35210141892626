<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Invoices</h5>
    <p-button
      label="Create Invoice"
      styleClass="p-button-sm"
      icon="pi pi-plus-circle"
      iconPosition="left"
      (onClick)="createInvoice()"
    ></p-button>
  </div>
</div>
<ng-container *ngIf="!areInvoicesLoaded">
  <ngx-skeleton-loader
    class="flex flex-1 gap-1 mb-3"
    [count]="tabToStatusMap.length"
    appearance="circle"
    [theme]="{
      'width': '72px',
      'height': '40px',
      'border-radius': '8px',
      'margin': '0'
    }"
  >
  </ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="8"
    appearance="line"
    animation="progress"
    [theme]="{
      'height': '3rem',
      'border-radius': 4
    }"
  >
  </ngx-skeleton-loader>
</ng-container>
<p-tabView
  (onChange)="getInvoicesOnTabChange($event.index)"
  *ngIf="areInvoicesLoaded"
  [(activeIndex)]="activeIndex"
>
  <ng-container *ngFor="let tab of tabToStatusMap">
    <p-tabPanel [header]="tab.tabLabel">
      <ng-container *ngIf="!isTabChanged">
        <ng-container *ngIf="invoices && invoices.length > 0; else noDataFound">
          <p-table
            [columns]="cols"
            [value]="invoices"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            styleClass="p-datatable-sm"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [resizableColumns]="false"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr (click)="viewInvoice(rowData)">
                <td *ngFor="let col of columns" [style.width]="col?.width">
                  <div [ngSwitch]="col.showAs">
                    <div *ngSwitchCase="'currency'">
                      &#x20B9; {{ _(rowData, col.field).toFixed(2) }}
                    </div>
                    <div *ngSwitchCase="'chip'">
                      <p-chip
                        [label]="
                          utilities.getInvoiceStatusLabel(_(rowData, col.field))
                        "
                        [styleClass]="
                          utilities
                            .getInvoiceStatusLabel(_(rowData, col.field))
                            .toLowerCase()
                            .replace('/\s/g', '')
                        "
                      ></p-chip>
                    </div>
                    <div *ngSwitchCase="'date'">
                      {{
                        utilities.epochToDate(
                          _(rowData, col.field),
                          "dd-MM-yyyy"
                        )
                      }}
                    </div>
                    <div *ngSwitchCase="'action'" class="flex gap-1">
                      <!-- pi-ellipsis-v -->
                      <p-button
                        icon="pi pi-ellipsis-v"
                        styleClass="p-button-sm p-button-secondary p-button-text"
                        (onClick)="openContextMenu($event, rowData)"
                      ></p-button>
                      <p-contextMenu
                        #contextMenu
                        appendTo="body"
                        [model]="contextMenuItems"
                      ></p-contextMenu>
                    </div>
                    <div *ngSwitchDefault>
                      <ng-container *ngIf="col.shouldConcat; else default">
                        {{ _(rowData, col.field) }}
                        {{ _(rowData, col.concatField) }}
                      </ng-container>
                      <ng-template #default>
                        {{ _(rowData, col.field) }}
                      </ng-template>
                    </div>
                  </div>
                  <ng-container *ngIf="col.subField">
                    <small>{{ _(rowData, col.subField) }}</small>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
            <!-- <ng-template pTemplate="paginatorleft">
              <p-button
                type="button"
                icon="pi pi-plus"
                styleClass="p-button-text"
              ></p-button>
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <p-button
                type="button"
                icon="pi pi-cloud"
                styleClass="p-button-text"
              ></p-button>
            </ng-template> -->
          </p-table>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isTabChanged">
        <ngx-skeleton-loader
          count="8"
          appearance="line"
          animation="progress"
          [theme]="{
            'height': '3rem',
            'border-radius': 4
          }"
        >
        </ngx-skeleton-loader>
      </ng-container>
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
        est laborum.
      </p>
  
      <br /><br />
  
      <app-text-input id="first_name" label="First Name"></app-text-input> -->
    </p-tabPanel>
  </ng-container>
</p-tabView>
<ng-template #noDataFound>
  <div class="flex flex-column align-items-center justify-content-center">
    <img
      class="no-data-image"
      src="../../../assets/img/empty-folder.png"
      alt="empty"
    />
    <span>{{ noDataMessageForCurrentTab }}</span>
  </div>
</ng-template>
