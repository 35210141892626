import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { EnvironmentService } from "./environment.service";
import { endpoints } from "./endpoints.ts.service";
import { Company } from "../models/company.model";
import { BehaviorSubject, Observable } from "rxjs";
import { Address } from "../models/address.model";
import { UtilitiesService } from "./utilities.service";
import { DASHBOARD_PERIODS } from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userUrl = "";

  private addressUpdateSubject = new BehaviorSubject<Address>({} as Address);

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private utilities: UtilitiesService
  ) {
    this.environmentService.environmentObservable.subscribe(() => {
      this.userUrl = environment.apiUrl + endpoints.USERS;
    });
    this.userUrl = environment.apiUrl + endpoints.USERS;
  }

  getUser() {
    return this.httpClient.get(`${this.userUrl}`);
  }

  createUser(payload: any) {
    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.USERS}`,
      payload
    );
  }

  updateUser(payload: any) {
    return this.httpClient.patch(`${this.userUrl}`, payload);
  }

  createCompanyForUser(payload: Omit<Company, "id">) {
    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.COMPANIES}`,
      payload
    );
  }

  updateCompany(payload: Company) {
    const headers = new HttpHeaders().set(
      "company_id",
      this.utilities.getCurrentCompany().id
    );

    return this.httpClient.patch(
      `${environment.apiUrl}${endpoints.COMPANIES}`,
      payload,
      {
        headers,
      }
    );
  }

  getCompaniesForUser() {
    return this.httpClient.get(
      `${environment.apiUrl + endpoints.USERS}${endpoints.COMPANIES}`
    );
  }

  getPartiesOfUser() {
    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.USERS}${endpoints.PARTIES}`
    );
  }

  sendCompanyAddressUpdate(address: Address) {
    this.addressUpdateSubject.next(address);
  }

  getCompanyAddressUpdate(): Observable<any> {
    return this.addressUpdateSubject.asObservable();
  }

  getDashboardInfo(period?: DASHBOARD_PERIODS) {
    const headers = new HttpHeaders().set(
      "company_id",
      this.utilities.getCurrentCompany().id
    );

    let params = new HttpParams();

    if (period !== undefined) {
      params = params.append("period", period);
    }

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.COMPANIES}${endpoints.DASHBOARD}`,
      {
        headers,
        params,
      }
    );
  }
}
