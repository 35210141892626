import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { Phonenumberutility } from "../utils/phone-number.util";
import { UtilitiesService } from "../services/utilities.service";

@Directive({
  selector: "[appCurrencyFormat]",
})
export class CurrencyFormatDirective {
  @Input("appCurrencyFormat") amount!: string;

  constructor(
    private el: ElementRef,
    private pnu: Phonenumberutility,
    private utilities: UtilitiesService
  ) {}

  ngOnInit() {
    if (this.amount !== null) {
      const formattedAmount = this.formatCurrency(this.amount);
      this.el.nativeElement.textContent = formattedAmount;
    }
  }

  private formatCurrency(amount: string): string {
    const amountValue = parseFloat(amount);

    const dial_code =
      this.utilities.getUserFromService().contact_details?.mobile_number
        ?.dial_code;

    const country = this.pnu.getCountryDetailsFromDialCode(dial_code);

    return new Intl.NumberFormat(`en-${country.iso}`, {
      style: "currency",
      currency: country.currency,
    }).format(amountValue);
  }
}
