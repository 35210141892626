import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { EnvironmentService } from "./environment.service";
import { endpoints } from "./endpoints.ts.service";
import StockFlow from "../models/stock-flow.model";
import { UtilitiesService } from "./utilities.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  ITEM_WEIGHT_UNIT,
  WEIGHT_CONVERSION_RATES,
} from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private stockFlowSubject = new BehaviorSubject<string>("");

  private createItemSubject = new BehaviorSubject<string>("");

  constructor(
    private httpClient: HttpClient,
    private utilities: UtilitiesService
  ) {}

  sendStockFlowUpdate(customerId: string) {
    this.stockFlowSubject.next(customerId);
  }

  getStockFlowUpdate(): Observable<string> {
    return this.stockFlowSubject.asObservable();
  }

  clearStockFlowUpdate() {
    this.stockFlowSubject.next("");
  }

  sendItemCreation(itemId: string) {
    this.createItemSubject.next(itemId);
  }

  getItemCreation() {
    return this.createItemSubject.asObservable();
  }

  getProductsByCompany(companyId: string) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.COMPANIES}${endpoints.INVENTORY}`,
      {
        headers,
      }
    );
  }

  createProduct(companyId: string, payload: any) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.INVENTORY}`,
      payload,
      {
        headers,
      }
    );
  }

  updateProduct(companyId: string, productId: string, payload: any) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.patch(
      `${environment.apiUrl}${endpoints.INVENTORY}/${productId}`,
      payload,
      {
        headers,
      }
    );
  }

  deleteProduct(id: string, companyId: string) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.delete(
      `${environment.apiUrl}${endpoints.INVENTORY}/${id}`,
      {
        headers,
      }
    );
  }

  getProductCategories(companyId: string) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.INVENTORY}${endpoints.CATEGORIES}`,
      {
        headers,
      }
    );
  }

  updateStockFlow(productId: String, payload: StockFlow) {
    const headers = new HttpHeaders().set(
      "company_id",
      this.utilities.getCurrentCompany().id
    );

    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.INVENTORY}/${productId}${endpoints.STOCK_FLOW}`,
      payload,
      {
        headers,
      }
    );
  }

  convertWeight(
    value: number,
    fromUnit: ITEM_WEIGHT_UNIT,
    toUnit: ITEM_WEIGHT_UNIT
  ): number {
    if (fromUnit === toUnit) {
      return value;
    }
    return value * WEIGHT_CONVERSION_RATES[fromUnit][toUnit];
  }
}
