import { Injectable } from "@angular/core";
import { PDFDocument, PDFFont, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { UtilitiesService } from "./utilities.service";
import { Company } from "../models/company.model";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  readonly inchInMM = 25.4;

  readonly seventyTwo = 72;

  readonly a4PageWidth = (210 / this.inchInMM) * this.seventyTwo;

  readonly a4PageHeight = (297 / this.inchInMM) * this.seventyTwo;

  topMargin =
    this.a4PageHeight - (this.inchInMM / this.inchInMM) * this.seventyTwo;

  bottomMargin = (12.77 / this.inchInMM) * this.seventyTwo;

  horizontalMargin = (19 / this.inchInMM) * this.seventyTwo;

  currentVerticalPosition = this.topMargin;

  lastSavedVerticalPosition = this.currentVerticalPosition;

  headingFontSize = 18;

  subHeadingFontSize = 16;

  bodyFontSize = 11;

  text: string = "";

  textWidth: number = 0;

  // Invoice items columns
  column1Position = this.horizontalMargin;
  column2Position =
    (this.a4PageWidth - 2 * this.horizontalMargin) * 0.4 + this.column1Position;
  column3Position =
    (this.a4PageWidth - 2 * this.horizontalMargin) * 0.15 +
    this.column2Position;
  column4Position =
    (this.a4PageWidth - 2 * this.horizontalMargin) * 0.15 +
    this.column3Position;
  column5Position =
    (this.a4PageWidth - 2 * this.horizontalMargin) * 0.15 +
    this.column4Position;

  // Invoice total layout
  totalColumn1 = this.horizontalMargin;
  totalColumn2 =
    (this.a4PageWidth - 2 * this.horizontalMargin) * 0.6 + this.totalColumn1;

  // Terms & conditions
  termsAndConditions = [
    "Goods once sold will not be taken back.",
    "All disputes are subject to <city> jurisdiction only.",
  ];

  constructor(private utilities: UtilitiesService) {}

  async createInvoicePdf(invoice: any, company: Company) {
    console.log("invoice in pdfService :: ", invoice);
    // const company: Company = {...invoicingCompany, }
    const regularFontURL = "../../../assets/fonts/Inter-Regular.ttf";
    const boldFontURL = "../../../assets/fonts/Inter-Bold.ttf";
    const regularFontBytes = await fetch(regularFontURL).then((res) =>
      res.arrayBuffer()
    );
    const boldFontBytes = await fetch(boldFontURL).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const boldFont = await pdfDoc.embedFont(boldFontBytes);
    const regularFont = await pdfDoc.embedFont(regularFontBytes);

    // debugger;
    // const customFont = await pdfDoc.embedFont(fontBytes);

    const page = pdfDoc.addPage([this.a4PageWidth, this.a4PageHeight]);
    this.text = company.company_name;
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.topMargin,
      size: this.headingFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    console.log(
      "height of header text :: ",
      boldFont.heightAtSize(this.headingFontSize)
    );

    console.log(
      "this.currentVerticalPosition :: ",
      this.currentVerticalPosition
    );

    this.text = "Tax Invoice";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.subHeadingFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.topMargin,
      size: this.subHeadingFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition = this.currentVerticalPosition - 24;

    console.log(
      "this.currentVerticalPosition :: ",
      this.currentVerticalPosition
    );

    this.text = "Address Line 1";
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });
    this.currentVerticalPosition = this.currentVerticalPosition - 15;
    this.text = "Address Line 2";
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });
    this.currentVerticalPosition = this.currentVerticalPosition - 15;
    this.text = "Address Line 3";
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });
    this.currentVerticalPosition = this.currentVerticalPosition - 30;

    this.text = "Bill To";
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Invoice #";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth / 2 - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = [invoice.party.first_name, invoice.party.last_name].join(" ");
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    console.log("invoice_number ::: ", invoice.invoice_number);
    const invId = this.replaceHyphens(invoice.invoice_number);

    this.text = invId;
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth / 2 - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = invoice.party.contact_details.email;
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });
    this.currentVerticalPosition -= 15;

    this.text = invoice.party.contact_details.mobile_number.value;
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Invoice Date";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = "Full Address";
    page.drawText(this.text, {
      x: this.horizontalMargin,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = this.utilities
      .epochToDate(invoice.invoice_date, "dd MMM yyyy")
      .toString();
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });
    this.currentVerticalPosition -= 15;

    page.drawLine({
      start: { x: this.horizontalMargin, y: this.currentVerticalPosition },
      end: {
        x: this.a4PageWidth - this.horizontalMargin,
        y: this.currentVerticalPosition,
      },
      thickness: 1,
      color: rgb(0, 0, 0),
      opacity: 0.25,
    });

    this.currentVerticalPosition -= 30;

    this.text = "Item";
    page.drawText(this.text, {
      x: this.column1Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Qty";
    page.drawText(this.text, {
      x: this.column2Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Rate";
    page.drawText(this.text, {
      x: this.column3Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Tax";
    page.drawText(this.text, {
      x: this.column4Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Amount";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 30;

    // Contructing invoice items table
    invoice.invoice_items.forEach((item: any) => {
      // Item name
      this.text = item.title;
      this.textWidth = regularFont.widthOfTextAtSize(
        this.text,
        this.bodyFontSize
      );
      const textHeight = regularFont.heightAtSize(this.bodyFontSize);
      const heightAfterWrapped =
        (this.textWidth / (this.column2Position - this.column1Position)) *
        textHeight;
      console.log("width of text :: ", this.textWidth);
      console.log(
        "column width :: ",
        this.column2Position - this.column1Position
      );
      console.log("height of text :: ", heightAfterWrapped);
      page.drawText(this.text, {
        x: this.column1Position,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
        maxWidth: this.column2Position - this.column1Position - 8,
        lineHeight: this.bodyFontSize * 1.2,
      });

      // Item quantity
      this.text = item.quantity.toString();
      page.drawText(this.text, {
        x: this.column2Position,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      // Item unit price
      this.text = item.unit_price.toFixed(2);
      page.drawText(this.text, {
        x: this.column3Position,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      // Item tax amount
      this.text = item.total_tax.toFixed(2);
      page.drawText(this.text, {
        x: this.column4Position,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      // Item total
      this.text = item.total.toFixed(2);
      this.textWidth = regularFont.widthOfTextAtSize(
        this.text,
        this.bodyFontSize
      );
      page.drawText(this.text, {
        x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      this.currentVerticalPosition -= heightAfterWrapped + 20;
    });

    page.drawLine({
      start: { x: this.horizontalMargin, y: this.currentVerticalPosition },
      end: {
        x: this.a4PageWidth - this.horizontalMargin,
        y: this.currentVerticalPosition,
      },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.5,
    });

    this.currentVerticalPosition -= 22;

    this.text = "Subtotal";
    page.drawText(this.text, {
      x: this.column1Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 127.5";
    page.drawText(this.text, {
      x: this.column4Position,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 12001.95";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    page.drawLine({
      start: { x: this.horizontalMargin, y: this.currentVerticalPosition },
      end: {
        x: this.a4PageWidth - this.horizontalMargin,
        y: this.currentVerticalPosition,
      },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.5,
    });

    this.currentVerticalPosition -= 30;

    this.text = "Terms & Conditions";
    page.drawText(this.text, {
      x: this.totalColumn1,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "Taxable Amount";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ ".concat(invoice.total_tax_amount?.toFixed(2));
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 20;
    this.lastSavedVerticalPosition = this.currentVerticalPosition;

    this.termsAndConditions.forEach((condition, index) => {
      this.text = (index + 1).toString().concat(". ", condition);
      page.drawText(this.text, {
        x: this.totalColumn1,
        y: this.currentVerticalPosition,
        size: this.bodyFontSize,
        font: regularFont,
        color: rgb(0, 0, 0),
      });
      this.currentVerticalPosition -= 15;
    });

    this.currentVerticalPosition = this.lastSavedVerticalPosition;

    this.text = "CGST @ 9%";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 200.40";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = "SGST @ 9%";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 200.40";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    page.drawLine({
      start: { x: this.totalColumn2, y: this.currentVerticalPosition },
      end: {
        x: this.a4PageWidth - this.horizontalMargin,
        y: this.currentVerticalPosition,
      },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.5,
    });

    this.currentVerticalPosition -= 22;

    this.text = "Total Amount";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 12001.95";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    page.drawLine({
      start: { x: this.totalColumn2, y: this.currentVerticalPosition },
      end: {
        x: this.a4PageWidth - this.horizontalMargin,
        y: this.currentVerticalPosition,
      },
      thickness: 2,
      color: rgb(0, 0, 0),
      opacity: 0.5,
    });

    this.currentVerticalPosition -= 20;

    this.text = "Received Amount";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 2000";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = "Balance Amount";
    page.drawText(this.text, {
      x: this.totalColumn2,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.text = "₹ 10000.95";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 30;

    this.text = "Total Amount (in words)";
    this.textWidth = boldFont.widthOfTextAtSize(this.text, this.bodyFontSize);
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    this.currentVerticalPosition -= 15;

    this.text = this.price_in_words(12471.96); // "Twelve Thousand and one rupees and ninety five paise only";
    this.textWidth = regularFont.widthOfTextAtSize(
      this.text,
      this.bodyFontSize
    );
    page.drawText(this.text, {
      x: this.a4PageWidth - this.horizontalMargin - this.textWidth,
      y: this.currentVerticalPosition,
      size: this.bodyFontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    const a = document.createElement("a");
    a.download = "FileName.pdf";
    a.href = pdfBytes;
    a.click();

    // window.open(pdfBytes);
  }

  price_in_words(amount: number): string {
    let price = amount.toString();
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt: number, prevDgt: number) {
        return 0 == dgt ? "" : 1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt];
      },
      handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
        return (
          (0 != dgt && 1 != nxtDgt ? sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? denom : "")
        );
      };

    var str = [],
      digitIdx = 0,
      digit: number = 0,
      nxtDigit = 0,
      words: Array<string> = [];
    var re = /(0|([1-9]\d*))(\.\d+)?/g;
    if (re.test(price)) {
      var arr = price.split(".");
      price = arr[0];
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
        switch (
          ((digit = parseInt(price[digitIdx], 10) - 0),
          (nxtDigit = digitIdx > 0 ? parseInt(price[digitIdx - 1], 10) - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;
          case 2:
            words.push(
              0 != digit
                ? sglDigit[digit] +
                    " Hundred" +
                    (0 != parseInt(price[digitIdx + 1], 10) &&
                    0 != parseInt(price[digitIdx + 2], 10)
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 9:
            words.push(handle_utlc(digit, nxtDigit, "Arab"));
            break;
          case 10:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 11:
            words.push(handle_utlc(digit, nxtDigit, "Kharab"));
            break;
          case 12:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 13:
            words.push(handle_utlc(digit, nxtDigit, "Nil"));
            break;
          case 14:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 15:
            words.push(handle_utlc(digit, nxtDigit, "Padma"));
            break;
          case 16:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 17:
            words.push(handle_utlc(digit, nxtDigit, "Shankh"));
            break;
          case 18:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;

          case 19:
            words.push(
              0 != digit
                ? sglDigit[digit] +
                    " Hundred" +
                    (0 != parseInt(price[digitIdx + 1], 10) &&
                    0 != parseInt(price[digitIdx + 2], 10)
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 20:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 21:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;
          case 22:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 23:
            words.push(handle_tens(digit, parseInt(price[digitIdx + 1], 10)));
            break;
        }
      }
      str = words.filter((word) => word !== "");
      if (arr.length > 1) {
        str.unshift("Rupees and");
        price = arr[1];
        words = [];
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
          switch (
            ((digit = parseInt(price[digitIdx], 10) - 0),
            (nxtDigit =
              digitIdx > 0 ? parseInt(price[digitIdx - 1], 10) - 0 : 0),
            price.length - digitIdx - 1)
          ) {
            case 0:
              words.push(handle_utlc(digit, nxtDigit, ""));
              break;
            case 1:
              words.push(handle_tens(digit, parseInt(price[digitIdx], 10)));
              break;
            case 2:
              words.push(
                0 != digit
                  ? sglDigit[digit] +
                      "Hundred" +
                      (0 != parseInt(price[digitIdx + 1], 10) &&
                      0 != parseInt(price[digitIdx + 2], 10)
                        ? "and"
                        : "")
                  : ""
              );
              break;
          }
        }
        words.unshift("Paise Only");
        str = words.concat(str);
      } else {
        words.unshift("Rupees Only");
        str = words;
      }
    } else return "";

    console.log("final str :: ", str);

    str = str.filter((e) => e !== "");
    return str.reverse().join(" ");
  }

  replaceHyphens(text: string): string {
    return text.replace(/-/g, "\u2011"); // \u2011 is the Unicode character for non-breaking hyphen
  }
}
