import { Component } from "@angular/core";
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from "primeng/api";
import { Observable } from "rxjs";

@Component({
  selector: "app-confirm-delete-modal",
  templateUrl: "./confirm-delete-modal.component.html",
  styleUrls: ["./confirm-delete-modal.component.scss"],
})
export class ConfirmDeleteModalComponent {
  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  public confirm(): Observable<boolean> {
    return new Observable((observer) => {
      this.confirmationService.confirm({
        // message:
        //   "<div class='flex flex-column gap-2'><p>Do you want to delete this record?</p><p>NOTE: This action cannot be undone.</p></div>",
        message: "NOTE: This action cannot be undone.",
        header: "Are you sure you want to delete this record?",
        icon: "pi pi-info-circle",
        accept: () => {
          // this.messageService.add({
          //   severity: "info",
          //   summary: "Confirmed",
          //   detail: "You have accepted",
          // });
          observer.next(true);
          observer.complete(); //to avoid memory leaks
        },
        reject: (type: ConfirmEventType) => {
          // switch (type) {
          //   case ConfirmEventType.REJECT:
          //     this.messageService.add({
          //       severity: "error",
          //       summary: "Rejected",
          //       detail: "You have rejected",
          //     });
          //     break;
          //   case ConfirmEventType.CANCEL:
          //     this.messageService.add({
          //       severity: "warn",
          //       summary: "Cancelled",
          //       detail: "You have cancelled",
          //     });
          //     break;
          // }
          observer.next(false);
          observer.complete(); //to avoid memory leaks
        },
        key: "confirm-delete",
      });
    });
  }
}
