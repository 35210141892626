import { ITEM_WEIGHT_UNIT } from "../constants/constants";
import { Address } from "./address.model";
import ContactDetails from "./contact-details.model";
import CreditInfo from "./credit-info.model";

export class Challan {
  id?: string = ""; // Unique identifier (UUID) for the challan
  challan_number?: string = ""; // Auto generated Challan number
  challan_date: number = 0; // Date of the challan (milliseconds since epoch)
  delivery_date: number = 0;
  type: number = 0; // Type of challan: 0 for inward, 1 for outward
  status: number = 0; // Status of the challan: 0 for open, 1 for closed
  approval_status: number = 0; // Approval status of the challan: 0 for pending, 1 for rejected, 2 for approved
  linked_to: {
    challan_id?: string; // List of Parent challan IDs if applicable where challan type is not equal to type of child challan
    sales_invoice_id: string; // List of Child challan IDs if applicable where challan type is not equal to type of child challan
    purchase_invoice_id?: string; // Based on type these could be sales(OUTWARD) or purchase(INWARD)
    purchase_order_id: string; // IDs of the linked purchase orders (UUID)
  } = {
    challan_id: "", // List of Parent challan IDs if applicable where challan type is not equal to type of child challan
    sales_invoice_id: "", // List of Child challan IDs if applicable where challan type is not equal to type of child challan
    purchase_order_id: "", // Based on type these could be sales(OUTWARD) or purchase(INWARD)
    purchase_invoice_id: "",
  };
  rework: boolean = false; // In case the challan holds rejected items from a previous challan
  party: {
    id: string;
    billing_address_id?: string; // Selected Address from UI
    shipping_address_id?: string; // Selected Address from UI
    first_name?: string;
    last_name?: string;
    contact_details?: ContactDetails;
    billing_address?: Array<Address>;
    shipping_address?: Array<Address>;
    credit_info?: CreditInfo;
    type?: number;
    create_date?: number;
    update_date?: number;
  } = {
    id: "",
    billing_address_id: "",
    shipping_address_id: "",
  };
  transport: {
    //optional if transport_id is available
    company_name: string;
    vehicle_number: string;
  } = {
    company_name: "",
    vehicle_number: "",
  };
  challan_items: Array<ChallanItem> = []; // Items included in the challan
  total_weight: number = 0; // Total weight of the items included
  terms_and_conditions: Array<string> = [];
  create_date?: number = 0;
  update_date?: number = 0;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class ChallanItem {
  item_code?: string = ""; //Item code for job work

  description: string = ""; // optional Description of the item

  hsn_sac_code?: string = ""; // HSN/SAC code of the item

  product_service_id: string = ""; // Product or service ID

  quantity: number = 0; // Quantity of the item

  unit_weight?: number = 0; // Total weight of the item

  net_weight?: number = 0;

  weight_unit!: ITEM_WEIGHT_UNIT;

  batch_number: string = ""; // Batch number of the item

  returned_quantity: number = 0; //To be updated if any child challans are being linked

  constructor(data: any) {
    Object.assign(this, data);
  }
}

/**
 * Challan Types
 * @enum
 */
export enum CHALLAN_TYPES {
  Inward = 0,
  Outward = 1,
}

/**
 * Status of challan
 */
export enum CHALLAN_STATUS {
  Open = 0,
  Closed = 1,
}

/**
 * Approval status of challan
 * @enum
 */
export enum CHALLAN_APPROVAL_STATUS {
  Pending = 0,
  Rejected = 1,
  Approved = 2,
}
