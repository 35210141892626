import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CHALLAN_TYPE } from "src/app/core/constants/constants";
import { Challan } from "src/app/core/models/challan.model";
import { ChallanService } from "src/app/core/services/challan.service";
import { get } from "lodash";
import { UtilitiesService } from "src/app/core/services/utilities.service";

interface Column {
  field: string;
  header: string;
  width?: string;
  showAs?: string;
  shouldConcat?: boolean;
  concatField?: string;
}

@Component({
  selector: "app-challans",
  templateUrl: "./challans.component.html",
  styleUrls: ["./challans.component.scss"],
})
export class ChallansComponent implements OnInit {
  _ = get;

  parseFloat = parseFloat;

  CHALLAN_TYPE = CHALLAN_TYPE;

  contentLoaded: boolean = false;

  columns: Array<Column> = [];

  challans: Array<Challan> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: ChallanService,
    public utilities: UtilitiesService
  ) {}

  ngOnInit(): void {
    // define columns for the challans table view
    this.columns = [
      { field: "challan_number", header: "Challan #", showAs: "" },
      {
        field: "type",
        header: "Challan Type",
        showAs: "tag",
      },
      {
        field: "transport.vehicle_number",
        header: "Transport Vehicle",
        showAs: "",
      },
      {
        field: "delivery_date",
        header: "Delivery Date",
        showAs: "date",
      },
      {
        field: "total_weight",
        header: "Consignment Weight (in kg)",
        showAs: "",
      },
    ];

    this.getChallansByCompany();
  }

  createChallan() {
    this.router.navigate(["new"], { relativeTo: this.route });
  }

  getChallansByCompany() {
    this.cs.getChallansByCompany().subscribe({
      next: (response: any) => {
        console.log("getChallansByCompany response :: ", response);
        this.challans = response.data;
        this.contentLoaded = true;
      },
      error: (error) => {
        console.log("getChallansByCompany error :: ", error);
        this.contentLoaded = true;
      },
    });
  }

  viewChallan(challan: Challan) {
    console.log("View challan");
  }
}
