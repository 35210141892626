import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { SalesComponent } from "./pages/invoice/sales/sales.component";
import { CreateInvoiceComponent } from "./pages/invoice/create-invoice/create-invoice.component";
import { ProductsComponent } from "./pages/product/products/products.component";
import { CreateProductComponent } from "./pages/product/create-product/create-product.component";
import { PartiesComponent } from "./pages/party/parties/parties.component";
import { CreatePartyComponent } from "./pages/party/create-party/create-party.component";
import { InventoryComponent } from "./pages/inventory/inventory/inventory.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { UserUpdateComponent } from "./pages/user-update/user-update.component";
import { CompanyUpdateComponent } from "./pages/company/company-update/company-update.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AuthGuard } from "./core/guards/auth-guard.guard";
import { ChallansComponent } from "./pages/challan/delivery-challan/challans/challans.component";
import { CreateUpdateChallanComponent } from "./pages/challan/delivery-challan/create-update-challan/create-update-challan.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
      {
        path: "login",
        component: LoginComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "signup",
        component: SignupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "sales",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: SalesComponent,
          },
          {
            path: "new",
            component: CreateInvoiceComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: "purchases",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: SalesComponent,
          },
          {
            path: "new",
            component: CreateInvoiceComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: "products",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ProductsComponent,
          },
          {
            path: "new",
            component: CreateProductComponent,
          },
          {
            path: ":id",
            component: CreateProductComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: "inventory",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: InventoryComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: "parties",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: PartiesComponent,
          },
          {
            path: "new",
            component: CreatePartyComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: "delivery_challan",
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ChallansComponent,
          },
          {
            path: "new",
            component: CreateUpdateChallanComponent,
          },
        ],
      },
      {
        path: "settings",
        component: SettingsComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "user",
          },
          {
            path: "user",
            component: UserUpdateComponent,
          },
          {
            path: "company",
            component: CompanyUpdateComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
