import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/app/environments/environment";
import { EnvironmentService } from "./environment.service";
import { endpoints } from "./endpoints.ts.service";
import { PaymentTransaction } from "../models/payment-transaction";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  private paymentRecordSubject = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  getInvoicesByCompany(companyId: string) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.COMPANIES}${endpoints.INVOICES}`,
      {
        headers,
      }
    );
  }

  getInvoiceById(companyId: string, invoiceId: string) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.get(
      `${environment.apiUrl}${endpoints.INVOICES}/${invoiceId}`,
      {
        headers,
      }
    );
  }

  createInvoice(companyId: string, payload: any) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.INVOICES}`,
      payload,
      {
        headers,
      }
    );
  }

  updateInvoice(companyId: string, payload: any) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.patch(
      `${environment.apiUrl}${endpoints.INVOICES}/${payload.id}`,
      payload,
      {
        headers,
      }
    );
  }

  recordPayment(
    paymentPayload: PaymentTransaction,
    invoiceId: string,
    companyId: string
  ) {
    const headers = new HttpHeaders().set("company_id", companyId);

    return this.httpClient.post(
      `${environment.apiUrl}${endpoints.INVOICES}/${invoiceId}${endpoints.TRANSACTIONS}`,
      paymentPayload,
      {
        headers,
      }
    );
  }

  sendPaymentRecordMessage(isRecorded: boolean) {
    this.paymentRecordSubject.next(isRecorded);
  }

  getPaymentRecordedMessasge(): Observable<any> {
    return this.paymentRecordSubject.asObservable();
  }
}
