import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import {
  Challan,
  ChallanItem,
  CHALLAN_TYPES,
  CHALLAN_STATUS,
  CHALLAN_APPROVAL_STATUS,
} from "src/app/core/models/challan.model";
import { MethodNotImplementedError } from "pdf-lib";
import Party from "src/app/core/models/party.model";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { UtilitiesService } from "src/app/core/services/utilities.service";
import { UserService } from "src/app/core/services/user.service";
import { ProductService } from "src/app/core/services/product.service";
import Inventory_Item from "src/app/core/models/inventory-item.model";
import { isAfter } from "date-fns";
import { Address } from "src/app/core/models/address.model";
import { AutoCompleteCompleteEvent } from "src/app/core/models/shared.model";
import { ITEM_WEIGHT_UNIT } from "src/app/core/constants/constants";
import { ChallanService } from "src/app/core/services/challan.service";
import { InvoiceService } from "src/app/core/services/invoice.service";
import { CheckboxChangeEvent } from "src/app/core/interfaces/primeng-intrefaces";

@Component({
  selector: "app-create-update-challan",
  templateUrl: "./create-update-challan.component.html",
  styleUrls: ["./create-update-challan.component.scss"],
})
export class CreateUpdateChallanComponent implements OnInit {
  ITEM_WEIGHT_UNIT = ITEM_WEIGHT_UNIT;

  parseFloat = parseFloat;

  math = Math;

  newChallanForm = new FormGroup({
    id: new FormControl<string>(""),
    challan_number: new FormControl<string>(""),
    challan_date: new FormControl<Date | undefined>(new Date(), [
      Validators.required,
    ]),
    delivery_date: new FormControl<Date | undefined>(new Date(), [
      Validators.required,
    ]),
    type: new FormControl<CHALLAN_TYPES>(CHALLAN_TYPES.Inward, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    status: new FormControl<CHALLAN_STATUS | null>(0, [Validators.required]),
    approval_status: new FormControl<CHALLAN_APPROVAL_STATUS | null>(2, [
      Validators.required,
    ]),
    linked_to: new FormGroup({
      // parent_challans: new FormControl<Array<string>>([], {
      //   nonNullable: true,
      // }),
      // child_challans: new FormControl<Array<string>>([], { nonNullable: true }),
      // invoices: new FormControl<Array<string>>([], { nonNullable: true }),
      // purchase_orders: new FormControl<Array<string>>([], {
      //   nonNullable: true,
      // }),
      challan_id: new FormControl<string>("", { nonNullable: true }),
      sales_invoice_id: new FormControl<string>("", { nonNullable: true }),
      purchase_invoice_id: new FormControl<string>("", { nonNullable: true }),
      purchase_order_id: new FormControl<string>("", { nonNullable: true }),
    }),
    rework: new FormControl<boolean>(false),
    party: new FormGroup({
      id: new FormControl<string>("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      billing_address_id: new FormControl<string>("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      shipping_address_id: new FormControl<string>("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    }),
    transport: new FormGroup({
      id: new FormControl<string>(""),
      company_name: new FormControl<string>("", { nonNullable: true }),
      vehicle_number: new FormControl<string>("", { nonNullable: true }),
    }),
    challan_items: new FormArray<FormControl<ChallanItem>>(
      [],
      [Validators.required]
    ),
  });

  isContentLoaded = true;

  customers: Array<Party> = [];

  customersCopy: Array<Party> = [];

  products: Array<Inventory_Item> = [];

  productsCopy: Array<any> = [];

  invoices: Array<any> = [];

  invoicesCopy: Array<any> = [];

  challans: Array<Challan> = [];

  challansCopy: Array<Challan> = [];

  companyId!: string;

  isSubmitted: boolean = false;

  selectedParty!: Party;

  selectedProduct: Inventory_Item | undefined;

  selectedProductQuantity: number = 0;

  challanTypeOptions = this.utilities.convertStringEnumToMapList(CHALLAN_TYPES);

  readonly vehicles = [
    {
      vehicle_number: "MH12CB0012",
      brand: "Tata",
      model: "Ace",
      company_name: "Deliver Now LLC",
    },
    {
      vehicle_number: "MH06XC0563",
      brand: "Tata",
      model: "Ace",
      company_name: "Deliver Now LLC",
    },
    {
      vehicle_number: "MH12CR2600",
      brand: "Mahindra",
      model: "Jeeto",
      company_name: "Deliver Now LLC",
    },
    {
      vehicle_number: "MH12TH3668",
      brand: "Mahindra",
      model: "Jeeto",
      company_name: "Deliver Now LLC",
    },
  ];

  filteredVehicles: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private us: UserService,
    private ps: ProductService,
    private utilities: UtilitiesService,
    private cs: ChallanService,
    private is: InvoiceService
  ) {
    // get current company id
    this.companyId = this.utilities.getUserFromService().companyId;

    // fetch customers and products
    forkJoin({
      parties: this.us.getPartiesOfUser().pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
      inventory: this.ps.getProductsByCompany(this.companyId).pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
      invoices: this.is.getInvoicesByCompany(this.companyId).pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
      challans: this.cs.getChallansByCompany().pipe(
        catchError((err: any) => {
          return of(undefined);
        })
      ),
    }).subscribe((result: any) => {
      console.log("final result :: ", result);
      const { parties, inventory, invoices, challans } = result;

      this.customers = parties ? parties.data : [];
      this.customersCopy = this.customers;

      this.products = inventory ? inventory.data : [];
      this.productsCopy = this.utilities.cloneDeep(this.products);

      this.invoices = invoices ? invoices.data.invoices : [];
      this.invoicesCopy = this.invoices;

      this.challans = challans ? challans.data : [];
      this.challansCopy = this.challans;

      console.log("typeof challan :: ", typeof this.challans[0]);

      this.isContentLoaded = true;
    });
  }

  ngOnInit(): void {}

  backToChallanListing() {
    this.router.navigate(["delivery_challan"]);
  }

  createCustomer() {
    throw new MethodNotImplementedError(
      "create-update-challan",
      "createCustomer"
    );
  }

  filterParties(event: any) {
    event.filter = event.filter.toLowerCase();

    this.customers = this.customersCopy.filter((customer) =>
      customer.contact_details.mobile_number.value.includes(event.filter)
    );
    console.log("filtered customers :: ", this.customers);
  }

  issueOnChanged(date: Date) {
    if (!date) return;

    if (
      isAfter(date, this.newChallanForm.controls.delivery_date.getRawValue()!)
    ) {
      this.newChallanForm.controls.delivery_date.setValue(date);
    }
  }

  partyChanged(event: any) {
    if (!event.value || !Object.keys(event?.value).length) return;

    this.selectedParty = event.value;
    this.newChallanForm.controls.party.controls.id.setValue(
      this.selectedParty.id!
    );

    const defaultBillingAddress = this.selectedParty.billing_address.find(
      (address: Address) => address.is_default
    );
    this.newChallanForm.controls.party.controls.billing_address_id.setValue(
      defaultBillingAddress?.id || this.selectedParty?.billing_address[0].id
    );

    console.log("this.invoicesCopy :: ", this.invoicesCopy);

    this.invoices = this.invoicesCopy.filter(
      (invoice) => invoice.party_id === this.selectedParty.id
    );

    this.products = this.productsCopy;
    this.newChallanForm.get("linked_to")!.setValue({
      challan_id: "",
      sales_invoice_id: "",
      purchase_invoice_id: "",
      purchase_order_id: "",
    });
  }

  getAddressForDisplay = (address: Address) =>
    this.utilities.getAddressForDisplay(address);

  challanItemsList(): FormArray {
    return this.newChallanForm.get("challan_items") as FormArray;
  }

  addItemToChallan() {
    if (!this.selectedProduct || !this.selectedProductQuantity) return;

    const challanItem = new ChallanItem({});

    challanItem.item_code = this.selectedProduct.item_code!;
    challanItem.description = this.selectedProduct.name;
    challanItem.product_service_id = this.selectedProduct.id!;
    challanItem.batch_number = "1111";
    challanItem.hsn_sac_code = this.selectedProduct.hsn_sac?.toString();
    challanItem.unit_weight = this.selectedProduct.weight!;
    challanItem.weight_unit = this.selectedProduct.weight_unit;
    challanItem.quantity = this.selectedProductQuantity;

    this.challanItemsList().push(new FormControl<ChallanItem>(challanItem));

    const usedProduct = this.products.find(
      (element) => element.id === this.selectedProduct?.id
    );

    // if (usedProduct) {
    //   usedProduct.inactive = true;
    // }

    this.selectedProduct = undefined;
    this.selectedProductQuantity = 0;
  }

  removeChallanItem(itemToRemove: ChallanItem) {
    const index = this.challanItemsList().controls.findIndex(
      (item) =>
        item.value.product_service_id === itemToRemove.product_service_id
    );

    const usedProduct = this.productsCopy.find(
      (element) => element.id === itemToRemove.product_service_id
    );

    usedProduct.inactive = false;

    this.challanItemsList().controls.splice(index, 1);
  }

  // calculateTotalWeight(
  //   items: ChallanItem[],
  //   outputUnit: ITEM_WEIGHT_UNIT
  // ): number {
  //   return items.reduce((total, item) => {
  //     const convertedWeight = this.ps.convertWeight(
  //       item.unit_weight,
  //       item.weight_unit,
  //       outputUnit
  //     );
  //     console.log("current total :: ", total);
  //     console.log("convertedWeight of current item :: ", convertedWeight);
  //     console.log("item.quantity :: ", item.quantity);
  //     console.log(
  //       "adding to current total :: ",
  //       convertedWeight * item.quantity
  //     );
  //     return total + convertedWeight * item.quantity;
  //   }, 0);
  // }

  filterVehicles(event: AutoCompleteCompleteEvent) {
    console.log("autocomplete filter event :: ", event);

    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.vehicles.length; i++) {
      let vehicle = this.vehicles[i];
      if (
        vehicle.vehicle_number.toUpperCase().indexOf(query.toUpperCase()) !== -1
      ) {
        filtered.push(vehicle);
      }
    }

    this.filteredVehicles = filtered;
  }

  vehicleSelected(event: any) {
    console.log("vehicleSelected :: ", event);
    this.newChallanForm.controls.transport
      .get("id")
      ?.setValue(event.vehicle_number);
    this.newChallanForm.controls.transport
      .get("vehicle_number")
      ?.setValue(event.vehicle_number);
    this.newChallanForm.controls.transport
      .get("company_name")
      ?.setValue(event.company_name);
  }

  vehicleOnBlur(event: any) {
    console.log("vehicleOnBlur :: ", event.target.value);
    const inputValue: string = event.target.value
      .toString()
      .replaceAll(" ", "")
      .toUpperCase();
    if (inputValue.length) {
      this.vehicles.forEach((vehicle) => {
        if (vehicle.vehicle_number === inputValue) {
          this.newChallanForm.get("transport")?.patchValue({
            id: vehicle.vehicle_number,
            vehicle_number: vehicle.vehicle_number,
            company_name: vehicle.company_name,
          });
        } else {
          this.newChallanForm.get("transport")?.patchValue({
            id: "",
            vehicle_number: inputValue,
            company_name: "",
          });
        }
      });
    }
  }

  // TODO Remove the folloging commented functions
  // onInvoiceLinkChange(event: CheckboxChangeEvent) {
  //   console.log("onInvoiceLinkChange event :: ", event);
  // }

  // get selectedValues() {
  //   return this.newChallanForm.get("linked_to.invoices") as FormArray;
  // }

  // onCheckboxChange(option: any, event: any) {
  //   console.log("option :: ", option);
  //   console.log("event :: ", event);
  //   const selectedValues = this.newChallanForm.get("linked_to.invoices")!
  //     .value as string[];

  //   if (event.checked.length) {
  //     selectedValues.push(option);
  //   } else {
  //     const index = selectedValues.indexOf(option);
  //     if (index > -1) {
  //       selectedValues.splice(index, 1);
  //     }
  //   }
  //   this.newChallanForm.get("linked_to.invoices")!.setValue(selectedValues);
  //   console.log(selectedValues);

  //   this.filterInventoryByLinkedInvoices();
  // }

  // filterInventoryByLinkedInvoices() {
  //   this.products = [];
  //   const linkedInvoices = this.newChallanForm.get("linked_to.invoices")!
  //     .value as string[];

  //   if (!linkedInvoices.length) {
  //     this.products = this.productsCopy;
  //     return;
  //   }

  //   this.invoices.forEach((invoice) => {
  //     if (linkedInvoices.includes(invoice.id)) {
  //       invoice.invoice_items.forEach((invoiceItem: any) => {
  //         const itemFromLinkedInvoice: Inventory_Item = this.productsCopy.find(
  //           (prd) => prd.id === invoiceItem.product_service_id
  //         );
  //         if (
  //           itemFromLinkedInvoice &&
  //           this.products.find((prd) => prd.id === itemFromLinkedInvoice.id) ===
  //             undefined
  //         ) {
  //           this.products.push(itemFromLinkedInvoice);
  //         } else {
  //           this.products = this.products;
  //         }
  //       });
  //     }
  //   });
  // }

  // getSelectedOptions() {
  //   return this.selectedValues.value
  //     .map((checked: boolean, i: number) => (checked ? this.invoices[i] : null))
  //     .filter((v: string | null) => v !== null);
  // }

  createUpdateChallan() {
    this.isSubmitted = true;
    if (!this.newChallanForm.valid) {
      this.isSubmitted = false;
      return;
    }
    const challan = this.newChallanForm.getRawValue();

    const payload: Omit<Challan, "total_weight"> = {
      challan_date: this.utilities.dateToEpoch(challan.challan_date),
      delivery_date: this.utilities.dateToEpoch(challan.delivery_date),
      type: challan.type,
      status: 1,
      approval_status: 0,
      rework: false,
      party: challan.party,
      transport: {
        company_name: challan.transport.company_name,
        vehicle_number: challan.transport.vehicle_number,
      },
      linked_to: challan.linked_to,
      challan_items: challan.challan_items!,
      terms_and_conditions: [
        "Subject to Ahmedabad Jurisdiction.",
        "Our responsibility ceases as soon as the goods leave our premises.",
        "Goods once sold will not be taken back.",
        "Delivery ex-premises.",
        "GST Payable on Reverse Charge - N.A.",
      ],
    };

    this.cs.createUpdateChallan(payload).subscribe({
      next: (response: any) => {
        console.log("createUpdateChallan response :: ", response);
        this.isSubmitted = false;
        this.backToChallanListing();
      },
      error: (error) => {
        console.log("createUpdateChallan error :: ", error);
        this.isSubmitted = false;
      },
    });
  }
}
